/* Footer */
.Footer {
    @include font-size(22);
    color: $white;
    font-weight: 300;
    position: relative;
    background-color: $footer-bg-color;

    // Top
    &-top {
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit);

        .Grid {
            margin-top: u($spacing-unit * -1);

            .Grid-cell {
                margin-top: u($spacing-unit);
            }
        }

        .Grid-cell--footer {

            @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                display: flex;
                align-items: center;
                flex-direction: column;

                margin-left: auto;
                margin-right: auto;
            }

            @include mq($until: $viewport--lg - 1) {
                max-width: u(400);
            }
        }
    }

    // Bottom
    &-bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        flex-direction: column;
        padding-top: u($spacing-unit--sm);
        padding-bottom: u($spacing-unit--sm);

        @include mq($from: $viewport--sm) {
            align-items: center;
            justify-content: center;
        }

        @include mq($from: $viewport--lg) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    a {
        color: $white;
        text-decoration: none;
        transition: border-color .3s;
        border-bottom: 1px solid $cc-red;

        &:hover {
            border-color: $white;
        }
    }

    .Hunt {
        position: absolute;
        right: 10px;
        bottom: 14px;
        border: none;

        &:hover {
            border: none;
        }
    }

    form.Newsletter {
        max-width: u(300);
        overflow: hidden;

        @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            justify-content: center;
            margin-left: 44px;
        }

        label {
            display: none;
        }

        .Form-item {

            &:not(.Form-item--action) {
                width: 100%;
            }

            &--action {
                width: 44px;
                line-height: 34px;
                transform: translateX(-44px);

                div {
                    height: 100%;
                }
            }
        }

        .Form-field--input {
            max-width: none;
            border: 1px solid $white;
            background-color: transparent;
            padding-right: u($spacing-unit);

            &,
            &::placeholder {
                color: $white;
            }
        }

        .Button--primary {
            width: 44px;
            border: none;
            height: 100%;
            color: $cc-red;
            border: 1px solid $white;
            background: linear-gradient(to right, $black 48%, $white 50%);
            background-size: 210% 100%;
            background-position: right bottom;

            &:hover {
                color: $cc-red;
                background-position: left bottom;
            }
        }
    }
}
