.productFilter {
    position: relative;
    margin: u($spacing-unit * 1.5, 0);
    background-color: $cc-grey--lighter;

    @include mq($from: $viewport--sm) {
        margin: u($spacing-unit--xl, 0);
    }
    > .Container {
        padding-top: u($spacing-unit * 1.5);
        padding-bottom: u($spacing-unit * 1.5);
        background-color: $cc-grey--lighter;

        @include mq($from: $viewport--sm) {
            padding-top: u($spacing-unit--xl);
            padding-bottom: u($spacing-unit--xl);
        }
    }

    .hidden {
        visibility: hidden;
        height: 0;
    }

    &-filterBtn {
        padding-bottom: 1rem;
        @include mq($from: $viewport--md) {
            display: none;
        }

        .Button--primary:hover {
            background-position: right bottom;
        }
    }

    &-catContainer {
    }

    &-title {
        margin: 0 auto;
        text-align: center;
        position: relative;
        top: -2.5rem;
        color: $cc-green;

        @include mq($from: $viewport--sm) {
            top: -6.5rem;
        }
    }

    &-productList {
        list-style: none;
        padding-left: 0;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        gap: 0.25rem 1rem;
        flex-wrap: wrap;
        font-size: 24px;

        @include mq($from: $viewport--sm) {
            flex-direction: column;
            gap: 0;
            font-size: 30px;
        }
    }

    &-productListItem {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-products {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.333333rem;
        overflow: hidden;

        @include mq($from: $viewport--sm) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @include mq($from: $viewport--lg) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @include mq($from: $viewport--xl) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    &-arrow {
        transition: all 0.4s;
        transform: rotate(180deg);
        width: 18px;
        margin-left: 10px;

        &--expanded {
            transform: rotate(0);
        }
    }

    &-groupTitle {
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    &-img {
        aspect-ratio: 320/300;
        object-fit: cover;
        transition: all 0.4s;
    }

    &-picture {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-show {
        // display: flex !important;
    }

    &-hidden {
        display: none !important;
    }

    &-item {
        display: block;
        flex-direction: column;
        background: $white;
        height: 100%;
        margin: 0 auto;
        width: 100%;
        min-width: 0px;

        &:hover {
            .productFilter-img {
                transform: scale(1.05);
            }
        }

        &-info {
            @include font-size(22);

            flex-grow: 1;
            position: relative;
            background-color: $white;
            padding: u($spacing-unit--sm);

            @include mq($from: $viewport--md) {
                padding: u($spacing-unit, $spacing-unit, $spacing-unit * 1.5);
            }
        }

        &-title {
            @include fluid-type(
                'font-size',
                $viewport--sm,
                $viewport--lg,
                24,
                30
            );
            @include fluid-type(
                'line-height',
                $viewport--sm,
                $viewport--lg,
                24,
                30
            );

            a {
                color: $cc-green;
                text-decoration: none;

                &:hover {
                    color: $black;
                }
            }
        }
    }
}
