.Block--exploded {
    margin: u($spacing-unit--lg, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .Exploded {
        display: flex;
        align-items: center;
        flex-direction: column;

        &-row {
            display: flex;
            justify-content: center;

            width: 100%;
            position: relative;

            & + .Exploded-row {
                margin-top: u($spacing-unit--xs);
            }

            &:hover {

                .Exploded-label {
                    opacity: 1;
                    visibility: visible;
                }

                .Exploded-imageWrapper::before {
                    background-color: rgba($white, 0);
                }
            }
        }

        &-imageWrapper {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                z-index: 2;
                transition: background-color .3s;

                @include mq($from: $viewport--md) {
                    background-color: rgba($white, .5);
                }
            }
        }

        &-label {
            @include position(absolute, $top: 50%, $left: 100%, $z: 3);
            @include font-size(22);
            display: flex;
            align-items: center;
            width: calc((100vw - 600px) / 2 - 40px);

            opacity: 0;
            visibility: hidden;
            transform: translateX(-100%) translateX(#{u(-40)}) translateY(-50%);
            transition: opacity .3s, visibility .3s;

            @include mq($from: $viewport--lg) {
                transform: translateX(#{u(-60)}) translateY(-50%);
            }

            &.is-wide {
                min-width: 100px;
                padding-left: u($spacing-unit);
            }

            span {
                z-index: 3;
                position: relative;
                background-color: $white;
                padding: u(5, 5, 5, $spacing-unit--sm);
            }

            &::before {
                @include dimensions(40, 1);
                @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 1"><line x2="10" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/></svg>');
                content: '';
                flex-shrink: 0;
                background-repeat: repeat-x;
            }
        }
    }
}