.#{$modal-namespace}Modal {
    @include transition(#{opacity, visibility});
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10;

    &,
    &:before {
        @include position(fixed, 0, 0, 0, 0);
    }

    &::before {
        background-color: $cc-grey--lighter;
        content: '';
        z-index: -1;
    }

    &:not(.is-active) {
        opacity: 0;
        visibility: hidden;
    }

    // Inner
    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;

        &Body {
            border-radius: if($modal-border-radius > 0, px($modal-border-radius), 0);
            border: px($modal-border-width) solid $modal-border-color;
            border-color: $modal-border-color;
            margin: u($modal-margin--t, $modal-margin--r, $modal-margin--b, $modal-margin--b);
            width: auto;
            position: relative;

            @include mq($from: $viewport--sm) {
                margin: u($spacing-unit--lg, auto);
                max-width: u($modal-width);
                width: 100%;
            }


        }
    }

    // Header
    &-header {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: u($modal-padding--t, $modal-padding--r, $modal-padding--b * 3, $modal-padding--l);
    }

    // Title
    &-title {
        @include font-size(30, false);
        line-height: u(40);
        margin-bottom: 0;
        font-weight: 300;
        padding: u(0, 40);
        color: $black;
        text-align: center;

        &--landing {
            text-transform: none;
        }
    }

    // Close
    &-close {
        @include font-size($font-size * 1.5);
        @include transition(#{opacity, visibility});
        cursor: pointer;
        font-weight: 700;
        line-height: 1;
        position: absolute;
        top: -40px;
        right: 0px;
        padding: u($modal-padding--t, $modal-padding--r, $modal-padding--b, $modal-padding--l);
        margin: u($modal-padding--t * -1, $modal-padding--r * -1, $modal-padding--b * -1, auto);

        html:not(.is-tabbing) &:focus {
            outline: 0;
        }

        &:not(:hover) {
            opacity: .5;
        }
    }

    // Body
    &-body {
        padding: em($modal-padding--t, $modal-padding--r, $modal-padding--b, $modal-padding--l);

        .Block {

            .Tile {

                &-imageWrapper {
                    height: u(253);

                    @include mq($until: $viewport--sm) {
                        height: u(150);
                    }
                }

                &-title {
                    font-size: u(34);
                    line-height: u(34);
                    position: static;
                    padding: 0;
                    height: auto;
                    width: auto;
                    left: auto;
                    right: auto;
                    top: auto;
                    bottom: auto;
                    text-align: left;
                    display: block;
                }

                &-link {
                    padding: u(5, 15);
                    font-size: u( 22);
                }

                &-bottom {
                    background-color: $white;
                    padding-top: u(30);
                    padding-left: u(40);
                    padding-right: u(40);
                    padding-bottom: u(100) !important;
                }

                &.is-red {
                    .Tile-title {
                        color: $cc-red;
                    }
                }

                &.is-green {
                    .Tile-title {
                        color: $cc-green;
                    }
                }

                &.is-black {
                    .Tile-title {
                        color: $black;
                    }
                }
            }
        }
    }

    // Footer
    &-footer {
        display: flex;
        justify-content: flex-end;
        padding: em($modal-padding--t, $modal-padding--r, $modal-padding--b, $modal-padding--l);
        border-top: 1px solid $grey--300;
    }
}

.Modal-datasheet {

    &::before {
        background-color: rgba($white, .7);
    }

    .Modal {

        &-inner {
            margin: 0 auto;
            max-width: 640px;
        }

        &-innerBody {
            background-color: $white;
            padding: u($spacing-unit);
        }

        &-title {
            @include font-size(40);
            font-weight: bold
        }
    }
}