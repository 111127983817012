/**
 * Size
 */

@if $grid-legacy {

     .u-size1of12 {
         flex-basis: auto !important;
         width: calc(100% * 1 / 12) !important;
     }

     .u-size1of10 {
         flex-basis: auto !important;
         width: 10% !important;
     }

     .u-size1of8 {
         flex-basis: auto !important;
         width: 12.5% !important;
     }

     .u-size1of6,
     .u-size2of12 {
         flex-basis: auto !important;
         width: calc(100% * 1 / 6) !important;
     }

     .u-size1of5,
     .u-size2of10 {
         flex-basis: auto !important;
         width: 20% !important;
     }

     .u-size1of4,
     .u-size2of8,
     .u-size3of12 {
         flex-basis: auto !important;
         width: 25% !important;
     }

     .u-size3of10 {
         flex-basis: auto !important;
         width: 30% !important;
     }

     .u-size1of3,
     .u-size2of6,
     .u-size4of12 {
         flex-basis: auto !important;
         width: calc(100% * 1 / 3) !important;
     }

     .u-size3of8 {
         flex-basis: auto !important;
         width: 37.5% !important;
     }

     .u-size2of5,
     .u-size4of10 {
         flex-basis: auto !important;
         width: 40% !important;
     }

     .u-size5of12 {
         flex-basis: auto !important;
         width: calc(100% * 5 / 12) !important;
     }

     .u-size1of2,
     .u-size2of4,
     .u-size3of6,
     .u-size4of8,
     .u-size5of10,
     .u-size6of12 {
         flex-basis: auto !important;
         width: 50% !important;
     }

     .u-size7of12 {
         flex-basis: auto !important;
         width: calc(100% * 7 / 12) !important;
     }

     .u-size3of5,
     .u-size6of10 {
         flex-basis: auto !important;
         width: 60% !important;
     }

     .u-size5of8 {
         flex-basis: auto !important;
         width: 62.5% !important;
     }

     .u-size2of3,
     .u-size4of6,
     .u-size8of12 {
         flex-basis: auto !important;
         width: calc(100% * 2 / 3) !important;
     }

     .u-size7of10 {
         flex-basis: auto !important;
         width: 70% !important;
     }

     .u-size3of4,
     .u-size6of8,
     .u-size9of12 {
         flex-basis: auto !important;
         width: 75% !important;
     }

     .u-size4of5,
     .u-size8of10 {
         flex-basis: auto !important;
         width: 80% !important;
     }

     .u-size5of6,
     .u-size10of12 {
         flex-basis: auto !important;
         width: calc(100% * 5 / 6) !important;
     }

     .u-size7of8 {
         flex-basis: auto !important;
         width: 87.5% !important;
     }

     .u-size9of10 {
         flex-basis: auto !important;
         width: 90% !important;
     }

     .u-size11of12 {
         flex-basis: auto !important;
         width: calc(100% * 11 / 12) !important;
     }


     // Properties

     .u-sizeFit {
         flex-basis: auto !important;
     }

     .u-sizeFill {
         flex: 1 1 0% !important;
         flex-basis: 0% !important;
     }

     .u-sizeFillAlt {
         flex: 1 1 auto !important;
         flex-basis: auto !important;
     }

     .u-sizeFull {
         width: 100% !important;
     }
}