/**
 * Size: Medium
 */

@if $grid-legacy {

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {

        .u-md-size1of12 {
            flex-basis: auto !important;
            width: calc(100% * 1 / 12) !important;
        }

        .u-md-size1of10 {
            flex-basis: auto !important;
            width: 10% !important;
        }

        .u-md-size1of8 {
            flex-basis: auto !important;
            width: 12.5% !important;
        }

        .u-md-size1of6,
        .u-md-size2of12 {
            flex-basis: auto !important;
            width: calc(100% * 1 / 6) !important;
        }

        .u-md-size1of5,
        .u-md-size2of10 {
            flex-basis: auto !important;
            width: 20% !important;
        }

        .u-md-size1of4,
        .u-md-size2of8,
        .u-md-size3of12 {
            flex-basis: auto !important;
            width: 25% !important;
        }

        .u-md-size3of10 {
            flex-basis: auto !important;
            width: 30% !important;
        }

        .u-md-size1of3,
        .u-md-size2of6,
        .u-md-size4of12 {
            flex-basis: auto !important;
            width: calc(100% * 1 / 3) !important;
        }

        .u-md-size3of8 {
            flex-basis: auto !important;
            width: 37.5% !important;
        }

        .u-md-size2of5,
        .u-md-size4of10 {
            flex-basis: auto !important;
            width: 40% !important;
        }

        .u-md-size5of12 {
            flex-basis: auto !important;
            width: calc(100% * 5 / 12) !important;
        }

        .u-md-size1of2,
        .u-md-size2of4,
        .u-md-size3of6,
        .u-md-size4of8,
        .u-md-size5of10,
        .u-md-size6of12 {
            flex-basis: auto !important;
            width: 50% !important;
        }

        .u-md-size7of12 {
            flex-basis: auto !important;
            width: calc(100% * 7 / 12) !important;
        }

        .u-md-size3of5,
        .u-md-size6of10 {
            flex-basis: auto !important;
            width: 60% !important;
        }

        .u-md-size5of8 {
            flex-basis: auto !important;
            width: 62.5% !important;
        }

        .u-md-size2of3,
        .u-md-size4of6,
        .u-md-size8of12 {
            flex-basis: auto !important;
            width: calc(100% * 2 / 3) !important;
        }

        .u-md-size7of10 {
            flex-basis: auto !important;
            width: 70% !important;
        }

        .u-md-size3of4,
        .u-md-size6of8,
        .u-md-size9of12 {
            flex-basis: auto !important;
            width: 75% !important;
        }

        .u-md-size4of5,
        .u-md-size8of10 {
            flex-basis: auto !important;
            width: 80% !important;
        }

        .u-md-size5of6,
        .u-md-size10of12 {
            flex-basis: auto !important;
            width: calc(100% * 5 / 6) !important;
        }

        .u-md-size7of8 {
            flex-basis: auto !important;
            width: 87.5% !important;
        }

        .u-md-size9of10 {
            flex-basis: auto !important;
            width: 90% !important;
        }

        .u-md-size11of12 {
            flex-basis: auto !important;
            width: calc(100% * 11 / 12) !important;
        }


        // Properties

        .u-md-sizeFit {
            flex-basis: auto !important;
        }

        .u-md-sizeFill {
            flex: 1 1 0% !important;
            flex-basis: 0% !important;
        }

        .u-md-sizeFillAlt {
            flex: 1 1 auto !important;
            flex-basis: auto !important;
        }

        .u-md-sizeFull {
            width: 100% !important;
        }

    }
}