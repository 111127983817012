/* Slider: navigation */
.Slider-button {
    @include dimensions(20, 40);
    @include position(absolute, $top: 50%, $z: 10);
    margin-top: u(-20px);
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 40px;
    transition: background-image .3s;

    &.is-disabled {
        cursor: auto;
        opacity: 0.35;
    }

    &.is-locked {
        display: none;
    }
}

.Slider-button--prev,
.Slider-buttonSlider--prev,
.Slider--rtl .Slider-button--next {
    background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#{$cc-grey}'/></svg>");
    right: auto;
    left: 20px;

    &:hover {
        background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#{$black}'/></svg>");
    }
}

.Slider-button--next,
.Slider-buttonSlider--next,
.Slider--rtl .Slider-button--prev {
    background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#{$cc-grey}'/></svg>");
    right: 20px;
    left: auto;

    &:hover {
        background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#{$black}'/></svg>");
    }
}
