.Site-rubidor {
    .Footer,
    .DigitalPulse-object,
    .CookieConsent-inner,
    .Nav--main .Nav-link.is-active,
    .Button--primary,
    .Block--tiles .Tile-link.Button--primary,
    .Block--catalogue .CatalogueProduct-label,
    .Block--overview .Overview-tag a.is-active,
    .Nav--main .Nav-item.Nav-button .Nav-link:hover,
    .Block--form .Form-item--action .Button--primary,
    .Block--textMedia a.TextMedia-link.Button--primary,
    .Nav--main .Nav-item.Nav-button .Nav-link.is-active,
    .Block--slider .Slider-button {
        background-color: $cc-green;
    }

    .Button--primary:hover,
    .Block--tiles a.Tile-link.Button--primary:hover,
    .Block--textMedia a.TextMedia-link.Button--primary:hover,
    .Block--slider .Slider-button:hover {
        background-color: $cc-green--dark;
    }

    .Footer a,
    a.Button--primary,
    .Block--tiles .Tile-link.Button--primary,
    .Nav--main .Nav-item.Nav-button .Nav-link,
    .Block--overview .Overview-tag a.is-active,
    .Block--form .Form-item--action .Button--primary,
    .Block--textMedia a.TextMedia-link.Button--primary {
        border-color: $cc-green;
    }

    a.DigitalPulse,
    a.Button--secondary,
    .Footer a:hover,
    .Block--grid a.Button,
    a.Button--primary,
    a.Button--primary:active,
    a.Button--primary:focus,
    a.Button--primary:hover,
    .Block--overview a.OverviewItem-link,
    .Block--circles a.Circle-imageWrapper,
    .Block--overview .Overview-tag a.Button,
    .Block--tiles .Tile-link.Button--primary,
    .Block--textMedia a.TextMedia-link.Button--primary,
    .Block--catalogue .CatalogueProduct-datasheet:hover {
        color: $white;
    }

    a,
    .Block--app .App-title,
    .Block--usps .Usp-title,
    .Block--usps .Usps-title,
    .Block--form .Form-title,
    .Block--grid .Grid-title,
    .Block--image .Image-title,
    .Block--quotes .Quote-text,
    .Block--quotes .Quote-quote,
    .Block--grid .Grid-videoTitle,
    .Block--circles .Circles-title,
    .Block--timeline .Timeline-title,
    .Block--overview .Overview-title,
    .Block--bannerHigh .Banner-title,
    .Block--bannerLow .Banner-title,
    .Block--textMedia .TextMedia-title,
    .Block--catalogue .Catalogue-title,
    .Block--titleText .TitleText-title,
    .Block--circles a.Circle-text:hover,
    .Block--timeline .Timeline-itemYear,
    .Block--timeline .Timeline-itemTitle,
    .Block--overview .OverviewItem-title,
    .Block--overview .Overview-form button,
    .Block--overview .OverviewItem-title a,
    .Block--banner .Banner-newsTitle:hover,
    .Block--banner .Banner-newsLink a:hover,
    .Footer form.Newsletter .Button--primary,
    .Nav--main .Nav-item.Nav-button .Nav-link,
    .Block--timeline .Timeline-line.is-active,
    .Footer form.Newsletter .Button--primary:hover,
    .Bravo,
    h2,
    h3,
    h4,
    .Charlie {
        color: $cc-green;
    }

    a:hover,
    a:focus,
    a:active {
        color: $black;
    }

    // Custom buttons
    .Nav--main .Nav-item.Nav-button .Nav-link {
        background: linear-gradient(to right, $cc-green 48%, transparent 50%);
        background-size: 210% 100%;
        background-position: right bottom;

        &:hover {
            background-position: left bottom;
        }
    }

    .Block--banner .Banner-link {
        background: linear-gradient(to left, $black 48%, $cc-green 50%);
        background-size: 210% 100%;
        background-position: left bottom;

        &:hover {
            background-position: right bottom;
        }
    }

    .Button--primary {
        background: linear-gradient(to right, $black 48%, $cc-green 50%);
        background-size: 210% 100%;
        background-position: right bottom;

        &:hover {
            background-position: left bottom;
        }
    }

    .Block--overview .Overview-tag button.Button {
        background: linear-gradient(to right, $cc-green 48%, $cc-grey 50%);
        background-size: 210% 100%;
        background-position: right bottom;

        &:hover {
            background-position: left bottom;
        }
    }

    .Block--overview .Overview-tag button.Button.is-active {
        background: linear-gradient(to left, $cc-grey 48%, $cc-green 50%);
        background-size: 210% 100%;
        background-position: left bottom;

        &:hover {
            background-position: right bottom;
        }
    }

    .Block--timeline .Timeline-line.is-active::before {
        @include background-svg(
            '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="90.5" viewBox="0 0 20 90.5"><g id="Group_163" data-name="Group 163" transform="translate(-380 -4857)"><circle id="Ellipse_14" data-name="Ellipse 14" cx="10" cy="10" r="10" transform="translate(380 4857)" fill="#{$cc-green}"/><line id="Line_193" data-name="Line 193" y2="81" transform="translate(390.5 4866.5)" fill="none" stroke="#{$cc-green}" stroke-width="1"/></g></svg>'
        );
    }
}
