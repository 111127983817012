.Block--overview {
    padding: u($spacing-unit * 1.5, 0);
    background-color: $cc-grey--lighter;
    margin: u($spacing-unit * 1.5, 0) !important;

    @include mq($from: $viewport--sm) {
        padding: u(0, 0, $spacing-unit--xl, 0);
    }

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0) !important;
    }

    .Overview {
        &-title {
            max-width: u(860);
            position: relative;
            margin: u($spacing-unit, auto);

            @include mq($until: $viewport--sm - 1) {
                margin-top: 0;
            }

            @include mq($from: $viewport--sm) {
                @include fluid-type(
                    'top',
                    $viewport--sm,
                    $viewport--lg,
                    -17,
                    -33
                );

                margin-top: 0;
                text-align: center;
            }
        }

        &-intro {
            @include fluid-type(
                'font-size',
                $viewport--sm,
                $viewport--lg,
                20,
                30
            );
            @include fluid-type(
                'line-height',
                $viewport--sm,
                $viewport--lg,
                26,
                40
            );

            margin: 0 auto;
            max-width: u(680);

            @include mq($from: $viewport--sm) {
                text-align: center;
            }
        }

        &-tagsWrapper {
            max-width: u(860);
            margin: u(0, auto);
        }

        &-form {
            margin: u($spacing-unit * 1.4, 0);

            .Form-item {
                @include mq($from: $viewport--sm) {
                    margin: 0 auto;
                    max-width: u(500);
                }
            }

            .Form-controls {
                display: flex;

                @include mq($from: $viewport--sm) {
                    justify-content: center;
                }
            }

            .Form-field {
                @include font-size(32);
                padding: u(15, 30);
                background-color: $white;
            }

            .Form-clear {
                text-align: right;

                a {
                    @include font-size(16);
                    font-style: italic;
                    text-decoration: none;
                    color: $cc-grey--hover;

                    &:hover {
                        color: darken($cc-grey--hover, 10%);
                    }
                }
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;

                z-index: 4;
                color: $cc-red;
                padding: u(0, 20);
                text-decoration: none;
            }
        }

        &-tags {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            @include mq($from: $viewport--sm) {
                justify-content: center;
            }

            margin: u($spacing-unit--sm, auto, 0, $spacing-unit--xs * -1);
        }

        &-tag {
            display: flex;
            align-items: center;
            margin-top: u($spacing-unit--xs);
            margin-left: u($spacing-unit--xs);

            button {
                background: linear-gradient(
                    to right,
                    $cc-red 48%,
                    $cc-grey 50%
                );
                background-size: 210% 100%;
                background-position: right bottom;

                &:hover {
                    background-position: left bottom;
                }
            }

            button.is-active {
                background: linear-gradient(to left, $cc-grey 48%, $cc-red 50%);
                background-size: 210% 100%;
                background-position: left bottom;

                &:hover {
                    background-position: right bottom;
                }
            }

            svg {
                width: u(15);
                height: u(15);
                margin-left: u(5);
            }
        }

        &-noResults {
            @include font-size(20);
        }

        &-grid {
            margin-top: u($spacing-unit);
            margin-left: u($spacing-unit * -1.2);

            .Grid-cell {
                padding-top: u($spacing-unit * 1.2);
                padding-left: u($spacing-unit * 1.2);
            }
        }
    }

    .OverviewItem {
        display: flex;
        flex-direction: column;

        height: 100%;
        margin: 0 auto;
        max-width: u(400);

        &-imageWrapper {
            display: block;
            overflow: hidden;

            &:hover {
                .OverviewItem-object {
                    transform: scale(1.05);
                }
            }
        }

        &-object {
            transition: transform 0.3s;
        }

        &-info {
            @include font-size(22);

            flex-grow: 1;
            position: relative;
            background-color: $white;
            padding: u($spacing-unit, $spacing-unit, $spacing-unit * 1.5);
        }

        &-title {
            font-size: 1.1rem;
            line-height: 1.1;

            a {
                color: $cc-red;
                text-decoration: none;

                &:hover {
                    color: $black;
                }
            }
        }

        &-intro {
            margin-top: u($spacing-unit--sm * 1.5);
        }

        &-link {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        &-footer {
            margin-top: u($spacing-unit * 1.5);

            svg {
                width: 100%;
                height: 100%;
                color: $cc-grey;
                max-width: 35px;
                max-height: 28px;
                transition: color 0.3s;
            }

            a {
                &:hover svg {
                    color: $cc-grey--hover;
                }

                & + a {
                    margin-left: u($spacing-unit--xs);
                }
            }
        }
    }

    &.Block--overviewTipsTricks {
        .OverviewItem-info {
            padding-bottom: u($spacing-unit--sm);
        }
    }
}

.Video-modal {
    .Modal-inner-body {
        border: none;
        position: relative;
    }

    .Modal-body {
        padding: 0;
    }
}
