/* Header */
.Header {
    background-color: $header-bg-color;
    display: flex;
    flex-direction: column;

    > .Container {
        display: flex;
        height: 100%;
        justify-content: center;
    }

    &--sticky {
        will-change: transform;

        &.is-pinned {
            transform: translateY(0%);
        }

        &.is-unpinned {
            transform: translateY(-100%);
        }

        &.is-notTop {
            box-shadow: 0 0 10px 0 rgba($black, 0.25);
        }
    }
}
