// Children
.Nav--children {
    @include mq($until: $viewport--lg - 1) {
        &.Nav--children-1 {
            margin-top: u($spacing-unit--xs * 1.7);
            margin-left: u($spacing-unit);

            .Nav-item-dropdown + .Nav-item-dropdown {
                margin-top: 0;
            }
        }

        &.Nav--children-2 {
            margin-left: u(55) !important;

            .Nav-item-dropdown + .Nav-item-dropdown {
                margin-top: 0;
            }
        }
    }

    @include mq($from: $viewport--lg) {
        &.Nav--children-1 {
            @include transition(#{opacity, visibility});
            @include position(
                absolute,
                $top: 100%,
                $left: u($spacing-unit--sm - 10)
            );

            @include mq($from: $viewport--lg) {
                @include position(
                    absolute,
                    $top: 100%,
                    $left: u($spacing-unit - 20)
                );
            }

            @include mq($from: $viewport--xl) {
                @include position(
                    absolute,
                    $top: 100%,
                    $left: u($spacing-unit - 10)
                );
            }

            .Nav-item:last-child .Nav--children {
                margin-bottom: 0;
            }

            .Nav-link.is-open ~ .Nav--children-2 {
                margin: u($spacing-unit--xs, 0);
            }
        }

        min-width: u(215);

        .Nav-item:not(:hover) > &.Nav--children-1 {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;

            & * {
                pointer-events: none;
            }
        }

        // List
        .Nav-list {
            margin: 0;
            padding: 0;
            text-align: left;
        }

        // Item
        .Nav-item {
            margin: 0;
            padding: 0;
            width: 100%;
        }

        // Link
        .Nav-link {
            padding: 0;

            &.is-active {
                font-weight: 700;
            }

            &:hover {
                color: $black;
            }
        }

        &Background {
            position: relative;
            background-color: $white;
            border: 1px solid $cc-grey;
            padding: u($spacing-unit--sm);
        }

        &.Nav--children-2 {
            .Nav-link {
                padding-left: 40px;
            }
        }
    }

    .Nav-item {
        display: flex;
        flex-direction: column;
    }

    &.Nav--children-1 {
        z-index: 2;

        .Nav-link.is-active {
            color: $black !important;
            background-color: transparent !important;
        }

        .Nav-link.is-closed ~ .Nav--children-2 {
            height: 0;
            margin: 0;
        }

        .Nav-link.is-open ~ .Nav--children-2 {
            opacity: 1;
            height: auto;
            visibility: visible;
        }
    }

    &.Nav--children-2 {
        opacity: 0;
        visibility: hidden;
        transition: height 0.3s, margin 0.3s;
    }
}
