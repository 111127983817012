// Breadcrumbs
.Nav--breadcrumbs {
    margin-left: u($spacing-unit--sm * -1);
    padding-bottom: u($spacing-unit--sm);

    .Nav-item {
        margin-left: u($spacing-unit--sm);

        & + .Nav-item {
            position: relative;

            &::before {
                @include font-size(22);

                content: '\2013';
                position: absolute;
                top: -3px;
                left: -15px;

                color: $cc-grey;
            }
        }
    }

    .Nav-link {
        @include font-size(20);
        color: $cc-grey;
        text-transform: uppercase;

        &:hover {
            color: $black;
        }
    }
}