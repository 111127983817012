.Block--catalogue {
    padding: u(50, 0);
    margin: u($spacing-unit * 1.5, 0);
    background-color: $cc-grey--lighter;

    @include mq($from: $viewport--md) {
        padding: u(120, 0);
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .Catalogue {

        &-group {

            &:not(:first-child) {
                margin-top: u(100);
            }
        }

        &-list {
            display: flex;
            flex-wrap: wrap;

            margin-left: u(-50);

            > .Catalogue-product {
                margin-top: u(30);
                margin-left: u(50);

                @include mq($from: $viewport--sm) {
                    margin-top: u(50);
                }
            }
        }

        &-product {
            flex-basis: auto;
            width: calc(100% - 50px);

            @include mq($from: $viewport--sm) {
                width: calc((100% * 1 / 2) - 50px);
            }

            @include mq($from: $viewport--lg) {
                width: calc((100% * 1 / 3) - 50px);
            }
        }

        &-subgroup {
            flex-basis: auto;
            margin-top: u(30);
            margin-left: u(50);
            width: calc(100% - 50px);

            @include mq($from: $viewport--sm) {
                margin-top: u(50);
            }

            @include mq($from: $viewport--md) {
                margin-top: u(100);

                &:first-child {
                    margin-top: u(70);
                }
            }
        }

        &-subtitle {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 30, 40);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 20, 40);

            color: $cc-grey;
            text-transform: uppercase;
        }
    }

    .CatalogueProduct {

        &-imageWrapper {
            position: relative;
        }

        &-object {

            img {
                @include dimensions(100%);
                object-fit: cover;

                &::before {
                    background-color: white;
                }
            }
        }


        &-meta {
            display: flex;
            position: absolute;
            right: 0;
            bottom: 0;
        }

        &-video,
        &-datasheet {
            background: linear-gradient(to right, $cc-grey--hover 48%, $cc-grey 50%);
            background-size: 210% 100%;
            background-position: right bottom;
            color: $white;
            padding: u(5, 15);
            transition: all .3s;
            margin-left: u($spacing-unit--xs / 2);

            &:hover {
                background-position: left bottom;
            }
        }

        &-video {
            display: flex;
            align-items: center;
        }

        &-icon {
            display: block;
            fill: currentColor;
            width: 24px;
            height: 24px;
        }

        &-datasheet {
            @include font-size(22);
            text-decoration: none;
            text-transform: uppercase;
        }

        &-label {
            @include font-size(22);
            position: absolute;
            top: 0;
            right: 0;

            color: $white;
            padding: u(5, 15);
            position: absolute;
            text-transform: uppercase;
            background-color: $cc-red;
        }

        &-title {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 30, 40);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 30, 40);

            color: $black;
            font-weight: 600;
            margin: u($spacing-unit--sm, 0, $spacing-unit--xs, 0);
        }

        &-text {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 20, 22);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 26, 28);
        }
    }
}