// Pagination
.Nav--pagination {
    display: flex;
    justify-content: center;

    width: 100%;
    margin-top: u($spacing-unit);

    .Nav-list {
        display: flex;
        justify-content: center;

        margin: 0;
        padding: 0;
        border-radius: 5px;
        list-style-type: none;
        border: 1px solid $cc-grey--light;
    }

    .Nav-item {
        @include font-size(20);

        color: $black;

        &::before {
            display: none;
        }

        & + .Nav-item {

            .Nav-link {
                border-left: 1px solid $cc-grey--light;
            }
        }
    }

    .Nav-link {
        @include font-size(20);

        color: $black;
        padding: u($spacing-unit--xs * .75, $spacing-unit--sm * .75);

        &.is-active {
            font-weight: bold;
        }

        &:hover {
            background-color: darken($cc-grey--lighter, 3%);
        }
    }

    .Nav-ellipsis {
        height: 100%;
        display: block;
        border-left: 1px solid $cc-grey--light;
        padding: u($spacing-unit--xs * .75, $spacing-unit--sm * .75);
    }
}