/* Colors: Custom */

// Grey
$cc-grey--lighter:                  #eeeeee !default;
$cc-grey--light:                    #c9c9c9 !default;
$cc-grey:                           #c4c4c4 !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);
$cc-grey--border:                   #6d6d6d !default;

$cc-red:                            #D6001C !default;
$cc-red--dark:                      scale-color($cc-red, $lightness: -30%);

$cc-green:                          #006341 !default;
$cc-green--dark:                    scale-color($cc-green, $lightness: -30%);

$cc-lime:                          #CAD208 !default;
$cc-lime--dark:                    scale-color($cc-lime, $lightness: -30%);