.Block--row {
    p {
        text-align: justify;
    }

    margin: u($spacing-unit--sm * 1.5, 0) !important;

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit * 1.5, 0) !important;
    }

    .Row {
        &-title {
            margin-bottom: u($spacing-unit--sm * 1.4);
        }

        &-content {
            @include mq($until: $viewport--lg - 1) {
                margin-top: u($spacing-unit);
            }
        }

        &-text {
            @include font-size(22);

            font-weight: 300;
            line-height: 30px;
        }

        &-media {
            .Media--video {
                width: 100%;
            }
        }

        &-object {
        }

        &-link {
            margin-top: u($spacing-unit--sm);
        }
    }
}
