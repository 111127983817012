.Block--usps {
    position: relative;
    margin: u($spacing-unit * 1.5, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .Usps {

        &-top {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            text-align: center;
            margin-bottom: u($spacing-unit--lg * 1.5);
        }

        &-title {

            & + .Usps-intro {
                margin-top: u(35);

                @include mq($from: $viewport--lg) {
                    margin-top: u(75);
                }
            }
        }

        &-intro {
            @include fluid-type('font-size', $viewport--sm, $viewport--xl, 20, 30);
            @include fluid-type('line-height', $viewport--sm, $viewport--xl, 26, 40);

            margin: 0 auto;
            max-width: u(680);
        }
    }

    .Usp {

        &-iconWrapper {
            display: flex;
            height: 100px;

            @include mq($from: $viewport--lg) {
                align-items: flex-end;
            }

            &.is-rectangle .Usp-image {
                max-width: 100px;
            }
        }

        &-title {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 30, 40);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 30, 40);

            color: $cc-red;
            text-transform: uppercase;
            margin-top: u($spacing-unit--sm * 1.6);

            @include mq($until: $viewport--lg - 1) {
                text-align: center;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100px;
        }

        &-intro {
            @include font-size(22);
        }

        &-bottom {
            margin-top: u($spacing-unit--sm);
        }

        &-grid {
            margin-top: u(-70);

            .Grid-cell {
                margin-top: u(70);
            }
        }

        &-top {

            @include mq($until: $viewport--lg - 1) {
                display: flex;
                align-items: center;
                flex-direction: column;

                max-width: 390px;
                margin-left: auto;
                margin-right: auto;
            }

            @include mq($until: $viewport--md) {
                flex-direction: column-reverse;
                justify-content: flex-end
            }
        }
    }

    &.has-intro {

        .Usp-title {
            @include fluid-type('font-size', $viewport--md, $viewport--lg, 30, 40);
            @include fluid-type('line-height', $viewport--md, $viewport--lg, 30, 40);
        }

        @include mq($until: $viewport--lg - 1) {

            .Usp-cell {
                display: flex;

                & + .Usp-cell {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: -35px;
                        right: 0;
                        left: u($spacing-unit--lg);

                        height: 1px;
                        width: calc(100% - #{$spacing-unit--lg}px);
                        background-color: $cc-grey--lighter;
                    }
                }
            }

            .Usp-title {
                margin-top: 0;
            }

            .Usp-top {
                width: 100%;
                max-width: 100px;
                flex-shrink: 0;

                @include mq($from: $viewport--xs) {
                    width: 150px;
                }

                @include mq($from: $viewport--sm) {
                    width: 200px;
                }
            }

            .Usp-bottom {
                margin-top: 0;
                margin-left: u($spacing-unit);
            }
        }
    }
}
