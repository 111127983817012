.Block--map {
    height: 550px;
    position: relative;

    .Map {

        &-address {
            @include font-size(22);

            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;

            z-index: 4;
            width: auto;
            height: auto;
            padding: u($spacing-unit);
            background-color: $white;

            @include mq($from: 400) {
                right: 0;
                bottom: 50px;
                left: auto;

                min-width: u(330);
            }

            @include mq($from: $viewport--md) {
                top: 50%;
                right: auto;
                bottom: auto;
                left: calc(50% + 50px);
                transform: translateY(-50%);
            }

            a {
                color: $black;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-object {
            height: 100%;
        }
    }
}