.Block--quotes {
    position: relative;
    margin: u($spacing-unit * 1.5, 0);
    padding: u($spacing-unit * 1.5, 0);
    background-color: $cc-grey--lighter;

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
        padding: u($spacing-unit--lg * 1.2, 0);
    }

    .Quote {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        overflow: hidden;

        &-quote {
            @include fluid-type('font-size', $viewport--sm, $viewport--xl, 40, 75);
            @include fluid-type('line-height', $viewport--sm, $viewport--xl, 35, 70);
            color: $cc-red;
            margin: u(0, 40);
            font-weight: bold;
            text-align: center;
            // text-transform: uppercase;

            @include mq($from: $viewport--md) {
                margin: u(0, 100);
            }

            @include mq($from: $viewport--xl) {
                margin: 0 auto;
                max-width: u(960);
            }

            & + .Quote-text {
                margin-top: u(25);

                @include mq($from: $viewport--md) {
                    margin-top: u(50);
                }
            }
        }

        &-text {
            @include fluid-type('font-size', $viewport--sm, $viewport--xl, 20, 30);
            @include fluid-type('line-height', $viewport--sm, $viewport--xl, 26, 40);

            color: $cc-red;
            text-align: center;
        }
    }

    .Slider-buttonQuotes--prev {
        background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#{$cc-grey}'/></svg>");

        left: 20px;

        @include mq($from: $viewport--sm) {
            left: 35px;
        }

        @include mq($from: $viewport--md) {
            left: 70px;
        }

        &:hover {
            background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#{$black}'/></svg>");
        }
    }

    .Slider-buttonQuotes--next {
        background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#{$cc-grey}'/></svg>");

        right: 20px;

        @include mq($from: $viewport--sm) {
            right: 35px;
        }

        @include mq($from: $viewport--md) {
            right: 70px;
        }

        &:hover {
            background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#{$black}'/></svg>");
        }
    }
}