@charset "UTF-8";
/*!
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import '~@fancyapps/ui/dist/fancybox.css';
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #D6001C;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 214, 0, 28;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Config Condensed";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 300;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #D6001C;
  --bs-link-hover-color: #ab0016;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #f7ccd2;
  --bs-table-border-color: #deb8bd;
  --bs-table-striped-bg: #ebc2c8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #deb8bd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4bdc2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #eb808e;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(214, 0, 28, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #eb808e;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(214, 0, 28, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #eb808e;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(214, 0, 28, 0.25);
}
.form-check-input:checked {
  background-color: #D6001C;
  border-color: #D6001C;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #D6001C;
  border-color: #D6001C;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23eb808e'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(214, 0, 28, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(214, 0, 28, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #D6001C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #f3b3bb;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #D6001C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #f3b3bb;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #D6001C;
  --bs-btn-border-color: #D6001C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #b60018;
  --bs-btn-hover-border-color: #ab0016;
  --bs-btn-focus-shadow-rgb: 220, 38, 62;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ab0016;
  --bs-btn-active-border-color: #a10015;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #D6001C;
  --bs-btn-disabled-border-color: #D6001C;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #D6001C;
  --bs-btn-border-color: #D6001C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #D6001C;
  --bs-btn-hover-border-color: #D6001C;
  --bs-btn-focus-shadow-rgb: 214, 0, 28;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #D6001C;
  --bs-btn-active-border-color: #D6001C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #D6001C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D6001C;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 220, 38, 62;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #D6001C;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #D6001C;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #D6001C;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c10019'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #eb808e;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(214, 0, 28, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #c10019;
  --bs-accordion-active-bg: #fbe6e8;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(214, 0, 28, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #D6001C;
  --bs-pagination-active-border-color: #D6001C;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #800011;
  --bs-alert-bg: #f7ccd2;
  --bs-alert-border-color: #f3b3bb;
}
.alert-primary .alert-link {
  color: #66000e;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #D6001C;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #D6001C;
  --bs-list-group-active-border-color: #D6001C;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #800011;
  background-color: #f7ccd2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #800011;
  background-color: #deb8bd;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #800011;
  border-color: #800011;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(214, 0, 28, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(214, 0, 28, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #D6001C !important;
}
.link-primary:hover, .link-primary:focus {
  color: #ab0016 !important;
}

.link-secondary {
  color: #6c757d !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}
.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* Settings: Base */ /* Settings: Colors */ /* Colors: Palette */ /* Colors: Custom */ /* Colors: Theme */ /* Settings: Fonts */ /* Elements: Heading */ /* Elements: HR */ /* Elements: Image */ /* Elements: Link */ /* Elements: List */ /* Elements: Paragraph */ /* Elements: Selection */ /* Objects: Alert */ /* Components: Button */ /* Object: Close */ /* Objects: Alert */ /* Objects: FieldGroup */ /* Objects: FlexEmbed */ /* Objects: Form */ /* Objects: Grid */ /* Objects: Nav */ /* Objects: Structure */ /* Objects: Table */ /* Objects: Toggle */ /* Components: Fields */ /* Components: Cookie */ /* Components: Modal */ /* Components: DigitalPulse */ /* Functions: Throw */ /* Functions: Checks */ /* Functions: Truncate list */ /* Functions: Maps */ /* Functions: Maps */ /* Functions: Order index */ /* Functions: Units */ /* Functions: String */ /* Functions: SVG */ /* Mixin: Arrow */ /* Mixin: Background SVG */ /* Mixin: Caret */ /* Mixin: Context */ /* Mixin: Dimensions */ /* Mixin: Family */ /* Mixin: Font-responsive */ /* Mixin: Font-size */ /* Mixin: Font-smoothing */ /* Mixin: Grid */ /* Mixin: Hover */ /* Mixin: Image rendering */ /* Mixin: Last-children */ /* Mixin: Line-height crop */ /* Mixin: Maps */ /* Mixin: ParentNthStatus */ /* Mixin: ParentState */ /* Mixin: ParentStatus */ /* Mixin: Position */ /* Mixin: Maps */ /* Mixin: Transition */ /* Mixin: Truncate */ /* Reset */
*, *::before, *::after {
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1.875em;
  line-height: 1.3333333333;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}
@media screen and (min-width: 48em) {
  html {
    hyphens: none;
  }
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

blockquote,
dl,
dd,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
hr,
figure,
p,
pre {
  margin: 0;
}

iframe {
  border: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  display: block;
  width: 100%;
}

option {
  font: inherit;
}

textarea {
  resize: vertical;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

abbr[title],
dfn[title] {
  cursor: help;
}

u,
ins {
  text-decoration: underline;
}

img {
  font-style: italic;
}

::selection {
  text-shadow: none;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

/* HR */
hr {
  margin-top: 1.3333333333rem;
  margin-bottom: 1.3rem;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid;
}

/* Image */
img {
  max-width: 100%;
  height: auto;
}

figure,
figure > img {
  display: block;
}
figure img {
  min-height: 100%;
}

picture {
  display: block;
  position: relative;
  overflow: hidden;
}
picture img::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #eeeeee;
  display: block;
  content: "";
}
picture img::after {
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url("/assets/img/icons/unlink.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 0.8888888889rem auto;
  content: "Image could not be loaded:\a" attr(alt);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.3333333333rem;
  transform: translate(-50%, -50%);
  font-size: 75%;
  line-height: 1.75;
  text-align: center;
  white-space: pre;
}
html[lang=nl] picture img::after {
  content: "Afbeelding kon niet worden geladen:\a" attr(alt);
}
html[lang=fr] picture img::after {
  content: "L'image n'a pas pu être chargée:\a" attr(alt);
}
picture:empty::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/assets/img/icons/image.svg");
  background-size: 2.6666666667rem;
  background-repeat: no-repeat;
  background-position: 50%;
  content: "";
  display: block;
}
picture[data-ratio] > img {
  position: absolute;
  min-height: 100%;
  width: 100%;
}
picture[data-ratio="1:1"] {
  padding-bottom: 100%;
}
picture[data-ratio="2:1"] {
  padding-bottom: 50%;
}
picture[data-ratio="3:2"] {
  padding-bottom: 66.6666666667%;
}
picture[data-ratio="4:3"] {
  padding-bottom: 75%;
}
picture[data-ratio="8:5"] {
  padding-bottom: 62.5%;
}
picture[data-ratio="16:9"] {
  padding-bottom: 56.25%;
}
picture[data-ratio="394:300"] {
  padding-bottom: 76.1421319797%;
}

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #D6001C;
  text-decoration: underline;
}
a:hover, a:focus, a:active {
  color: #000000;
  text-decoration: none;
}

/* List */
ol,
ul {
  margin-bottom: 1.3333333333rem;
  padding-left: 1.3333333333rem;
}
ol li,
ul li {
  margin-bottom: 0.0666666667rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-top: 0.1333333333rem;
  margin-bottom: 0.1333333333rem;
}

ul {
  list-style-type: disc;
}

ul ul {
  list-style-type: circle;
}

ul ul ul {
  list-style-type: square;
}

ol {
  list-style-type: decimal;
}

ol ol {
  list-style-type: upper-alpha;
}

ol ol ol {
  list-style-type: lower-roman;
}

ol ol ol ol {
  list-style-type: lower-alpha;
}

dt {
  font-weight: 700;
}

/* Selection */
::selection {
  color: #000000;
  background-color: #f1f075;
}

/* Heading */
h1, .h1, .Alfa,
h2,
.h2, .Bravo,
h3,
.h3, .Charlie,
h4,
.h4, .Delta,
h5,
.h5, .Echo,
h6,
.h6, .Foxtrot {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.fonts-loaded h1, .fonts-loaded .h1, .fonts-loaded .Alfa,
.fonts-loaded h2,
.fonts-loaded .h2, .fonts-loaded .Bravo,
.fonts-loaded h3,
.fonts-loaded .h3, .fonts-loaded .Charlie,
.fonts-loaded h4,
.fonts-loaded .h4, .fonts-loaded .Delta,
.fonts-loaded h5,
.fonts-loaded .h5, .fonts-loaded .Echo,
.fonts-loaded h6,
.fonts-loaded .h6, .fonts-loaded .Foxtrot {
  font-family: "Config Condensed", sans-serif;
}
h1 small, .h1 small, h1 .small, .h1 .small, .Alfa small, .Alfa .small,
h2 small,
.h2 small,
h2 .small,
.h2 .small, .Bravo small, .Bravo .small,
h3 small,
.h3 small,
h3 .small,
.h3 .small, .Charlie small, .Charlie .small,
h4 small,
.h4 small,
h4 .small,
.h4 .small, .Delta small, .Delta .small,
h5 small,
.h5 small,
h5 .small,
.h5 .small, .Echo small, .Echo .small,
h6 small,
.h6 small,
h6 .small,
.h6 .small, .Foxtrot small, .Foxtrot .small {
  font-weight: normal;
}

h1, .h1, .Alfa {
  color: #D6001C;
  text-transform: uppercase;
}
@media screen and (max-width: 37.4375em) {
  h1, .h1, .Alfa {
    font-size: 1.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  h1, .h1, .Alfa {
    font-size: calc(1.6666666667rem + 70 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  h1, .h1, .Alfa {
    font-size: 4rem;
  }
}
@media screen and (max-width: 37.4375em) {
  h1, .h1, .Alfa {
    line-height: 1.5rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  h1, .h1, .Alfa {
    line-height: calc(1.5rem + 55 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  h1, .h1, .Alfa {
    line-height: 3.3333333333rem;
  }
}

h2, .h2, .Bravo {
  color: #D6001C;
  text-transform: uppercase;
}
@media screen and (max-width: 37.4375em) {
  h2, .h2, .Bravo {
    font-size: 1.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  h2, .h2, .Bravo {
    font-size: calc(1.3333333333rem + 35 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  h2, .h2, .Bravo {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 37.4375em) {
  h2, .h2, .Bravo {
    line-height: 1.1666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  h2, .h2, .Bravo {
    line-height: calc(1.1666666667rem + 35 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  h2, .h2, .Bravo {
    line-height: 2.3333333333rem;
  }
}

h3, .h3, .Charlie {
  color: #D6001C;
  text-transform: uppercase;
}
@media screen and (max-width: 37.4375em) {
  h3, .h3, .Charlie {
    font-size: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  h3, .h3, .Charlie {
    font-size: calc(1rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  h3, .h3, .Charlie {
    font-size: 1.3333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  h3, .h3, .Charlie {
    line-height: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  h3, .h3, .Charlie {
    line-height: calc(1rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  h3, .h3, .Charlie {
    line-height: 1.3333333333rem;
  }
}

h4, .h4, .Delta {
  margin-bottom: 1.3333333333rem;
  font-size: 0.8rem;
}

h5, .h5, .Echo {
  margin-bottom: 1.3333333333rem;
  font-size: 0.6666666667rem;
}

h6, .h6, .Foxtrot {
  margin-bottom: 1.3333333333rem;
  font-size: 0.6rem;
}

.Giga {
  margin-bottom: 1.3333333333rem;
  font-size: 3.2rem;
  line-height: 1.1;
}

.Mega {
  margin-bottom: 1.3333333333rem;
  font-size: 2.4rem;
  line-height: 1.2;
}

.Kilo {
  margin-bottom: 1.3333333333rem;
  font-size: 1.6rem;
  line-height: 1.2;
}

.Milli {
  margin-bottom: 1.3333333333rem;
  font-size: 0.4rem;
}

.Micro {
  margin-bottom: 1.3333333333rem;
  font-size: 0.3333333333rem;
}

/* Paragraph */
p {
  margin-bottom: 1.3333333333rem;
}

.Lead {
  font-size: 1.125rem;
}

/* Main */
html {
  background-color: #ffffff;
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  height: 100%;
}
html.fonts-loaded {
  font-weight: 300;
  font-family: "Config Condensed", sans-serif;
}
html.hide-scroll {
  overflow: hidden;
}
@media screen and (max-width: 63.9375em) {
  html.show-nav body {
    overflow: hidden;
  }
}
html strong {
  font-weight: 500;
}

body {
  height: 100%;
}

/* Alert */
.Alert {
  border: 1px solid;
  border-radius: 11.25px;
  margin-bottom: 1.3333333333rem;
  padding: 0.6666666667rem 0.8888888889rem 0.6666666667rem 0.8888888889rem;
  position: relative;
}
.Alert.is-dismissible {
  padding-right: 3.2777777778rem;
}
.Alert.is-dismissible .Close {
  position: absolute;
  top: 0;
  right: 0;
  color: inherit;
  padding: 0.6666666667rem 0.8888888889rem 0.6666666667rem 0.8888888889rem;
}
.Alert p,
.Alert ul {
  margin-bottom: 0;
}
.Alert p + p,
.Alert p + ul,
.Alert ul + p,
.Alert ul + ul {
  margin-top: 1.3333333333rem;
}

/* Object: Button */
.Button {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  -webkit-appearance: none;
  border-radius: 0;
  border: 0px solid transparent;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8333333333rem;
  font-weight: inherit;
  line-height: inherit;
  padding: 0.0666666667em 0.3333333333em 0.0666666667em 0.3333333333em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  background: linear-gradient(to right, #000000 48%, #D6001C 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.Button:hover {
  color: inherit;
  text-decoration: none;
  background-position: left bottom;
}
.Button:focus, .Button.is-focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}
.Button:disabled, .Button.is-disabled {
  box-shadow: none;
  cursor: default;
  opacity: 0.6;
  pointer-events: none;
}

.ButtonGroup {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.ButtonGroup > .Button {
  position: relative;
  flex: 1 1 auto;
}
.ButtonGroup > .Button:hover, .ButtonGroup > .Button:focus, .ButtonGroup > .Button:active, .ButtonGroup > .Button.active {
  z-index: 1;
}
.ButtonGroup:not(.ButtonGroup--vertical) > .Button:not(:first-child),
.ButtonGroup:not(.ButtonGroup--vertical) > .ButtonGroup:not(:first-child) {
  margin-left: -1px;
}
.ButtonGroup:not(.ButtonGroup--vertical) > .Button:not(:last-child):not(.dropdown-toggle),
.ButtonGroup:not(.ButtonGroup--vertical) > .ButtonGroup:not(:last-child) > .Button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ButtonGroup:not(.ButtonGroup--vertical) > .Button:not(:first-child),
.ButtonGroup:not(.ButtonGroup--vertical) > .ButtonGroup:not(:first-child) > .Button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ButtonGroup--vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.ButtonGroup--vertical > .Button,
.ButtonGroup--vertical > .ButtonGroup {
  width: 100%;
}
.ButtonGroup--vertical > .Button:not(:first-child),
.ButtonGroup--vertical > .ButtonGroup:not(:first-child) {
  margin-top: -1px;
}
.ButtonGroup--vertical > .Button:not(:last-child):not(.dropdown-toggle),
.ButtonGroup--vertical > .ButtonGroup:not(:last-child) > .Button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ButtonGroup--vertical > .Button:not(:first-child),
.ButtonGroup--vertical > .ButtonGroup:not(:first-child) > .Button {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

/* Object: Containers */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 45.2rem;
  padding-right: 1.2666666667rem;
  padding-left: 1.2666666667rem;
  width: 100%;
}
@media screen and (min-width: 48em) {
  .Container {
    max-width: 45.3333333333rem;
    padding-right: 1.3333333333rem;
    padding-left: 1.3333333333rem;
  }
}
.Container--xs {
  max-width: 22.5333333333rem;
}
@media screen and (min-width: 48em) {
  .Container--xs {
    max-width: 22.6666666667rem;
  }
}
.Container--sm {
  max-width: 34.5333333333rem;
}
@media screen and (min-width: 48em) {
  .Container--sm {
    max-width: 34.6666666667rem;
  }
}
.Container--md {
  max-width: 40.5333333333rem;
}
@media screen and (min-width: 48em) {
  .Container--md {
    max-width: 40.6666666667rem;
  }
}
.Container--lg {
  max-width: 45.2rem;
}
@media screen and (min-width: 48em) {
  .Container--lg {
    max-width: 45.3333333333rem;
  }
}
.Container--xl {
  max-width: 72.5333333333rem;
}
@media screen and (min-width: 48em) {
  .Container--xl {
    max-width: 72.6666666667rem;
  }
}

/* Object: Close */
.Close {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.Close:hover {
  color: #000000;
  text-decoration: none;
}
.Close:hover, .Close:focus {
  opacity: 0.75;
}
.Close:disabled, .Close.is-disabled {
  pointer-events: none;
}

button.Close {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
}

/* Objects: FieldGroup */
.FieldGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.FieldGroup > .Form-field {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.FieldGroup > .Form-field:focus {
  z-index: 3;
}
.FieldGroup > .Form-field + .Form-field {
  margin-left: 0px;
}
.FieldGroup > .Form-field:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.FieldGroup > .Form-field:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.FieldGroup-prepend, .FieldGroup-append {
  display: flex;
}
.FieldGroup-prepend .Button, .FieldGroup-append .Button {
  position: relative;
  z-index: 2;
}
.FieldGroup-prepend .Button:focus, .FieldGroup-append .Button:focus {
  z-index: 3;
}
.FieldGroup-prepend .Button + .Button,
.FieldGroup-prepend .Button + .FieldGroup-text,
.FieldGroup-prepend .FieldGroup-text + .FieldGroup-text,
.FieldGroup-prepend .FieldGroup-text + .Button, .FieldGroup-append .Button + .Button,
.FieldGroup-append .Button + .FieldGroup-text,
.FieldGroup-append .FieldGroup-text + .FieldGroup-text,
.FieldGroup-append .FieldGroup-text + .Button {
  margin-left: 0px;
}
.FieldGroup-prepend {
  margin-right: 0px;
}
.FieldGroup-append {
  margin-left: 0px;
}
.FieldGroup > .FieldGroup-prepend > .Button, .FieldGroup > .FieldGroup-prepend > .FieldGroup-text, .FieldGroup > .FieldGroup-append:not(:last-child) > .Button, .FieldGroup > .FieldGroup-append:not(:last-child) > .FieldGroup-text, .FieldGroup > .FieldGroup-append:last-child > .Button:not(:last-child):not(.dropdown-toggle), .FieldGroup > .FieldGroup-append:last-child > .FieldGroup-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.FieldGroup > .FieldGroup-append > .Button, .FieldGroup > .FieldGroup-append > .FieldGroup-text, .FieldGroup > .FieldGroup-prepend:not(:first-child) > .Button, .FieldGroup > .FieldGroup-prepend:not(:first-child) > .FieldGroup-text, .FieldGroup > .FieldGroup-prepend:first-child > .Button:not(:first-child), .FieldGroup > .FieldGroup-prepend:first-child > .FieldGroup-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.FieldGroup-text {
  font-size: 1rem;
  align-items: center;
  background-color: #eeeeee;
  border: 0px solid #bdbdbd;
  border-radius: 0rem;
  color: inherit;
  display: flex;
  font-weight: 400;
  line-height: 1.3333333333rem;
  margin-bottom: 0;
  padding: 0.4333333333em 0.7333333333em 0.4333333333em 0.7333333333em;
  text-align: center;
  white-space: nowrap;
}

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative;
}
.FlexEmbed::before {
  content: "";
  display: block;
  padding-bottom: 100%;
  width: 100%;
}

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

img.FlexEmbed-content {
  object-fit: cover;
}

.FlexEmbed--1by1:before {
  padding-bottom: 100%;
}

.FlexEmbed--2by1:before {
  padding-bottom: 50%;
}

.FlexEmbed--3by2:before {
  padding-bottom: 66.6666666667%;
}

.FlexEmbed--4by3:before {
  padding-bottom: 75%;
}

.FlexEmbed--8by5:before {
  padding-bottom: 62.5%;
}

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%;
}

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 0.4666666667rem;
}

.Form-label {
  font-size: 0.7333333333rem;
  color: #c4c4c4;
  display: inline-block;
}
.Form-item--input .Form-label {
  position: absolute;
  transform: translateX(16px) translateY(8px) scale(1);
  transform-origin: top left;
  transition: transform 0.2s, color 0.2s;
  z-index: 2;
}
.Form-item--input.is-active .Form-label {
  color: #898989;
  transform: translateX(17px) translateY(-10px) scale(0.75);
}
.Form-item:not(.is-required) .Form-label::after {
  font-size: 0.6rem;
  content: " - Optional";
  margin-left: 0.3333333333rem;
}
html[lang^=nl] .Form-item:not(.is-required) .Form-label::after {
  content: " - Optioneel";
}
html[lang^=fr] .Form-item:not(.is-required) .Form-label::after {
  content: " - Facultatif";
}
.Form-item.is-error .Form-label {
  color: #f44336;
}
.Form-item.is-success .Form-label {
  color: #4caf50;
}

.Form-help {
  margin-top: 0.3333333333rem;
  color: #bdbdbd;
  font-size: 0.75rem;
}
.Form-help:first-child {
  margin-top: 0;
  margin-bottom: 0.6666666667rem;
}
.Form-help :last-child {
  margin-bottom: 0;
}
.Form-help + .Form-help {
  margin-top: 0.6666666667rem;
  padding-top: 0.6666666667rem;
  border-top: 1px solid #e0e0e0;
}

.Form-item.is-error .Form-help--validation {
  color: #f44336;
}
.Form-item.is-success .Form-help--validation {
  color: #4caf50;
}

.Form-controls {
  position: relative;
}

.Form-item--action {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 37.5em) {
  .Form-item--action {
    justify-content: center;
  }
}

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }
  .Form--horizontal .Form-label {
    margin-top: 0.3333333333rem;
    margin-bottom: 0;
    padding-right: 1.3333333333rem;
    width: 33.3333333333%;
  }
  .Form--horizontal .Form-controls {
    width: 66.6666666667%;
  }
  .Form--horizontal .Form-item--action {
    padding-left: 33.3333333333%;
  }
}
.FormField-label-check {
  font-size: 18px;
}

.Form-item.Form-item--dropdown .Form-label {
  display: none;
}
.Form-item.Form-item--dropdown select {
  line-height: 32.9999px;
}

.Form-item--recaptcha .Form-controls {
  display: flex;
  flex-direction: column;
}

/* Grid */ /* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex; /* 1 */
  flex-flow: row wrap;
  margin: 0; /* 1 */
  padding: 0; /* 1 */
}

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%; /* 1 */
  min-width: 0; /* 2 */
}

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center;
}

.Grid--alignRight {
  justify-content: flex-end;
}

.Grid--alignMiddle {
  align-items: center;
}

.Grid--alignBottom {
  align-items: flex-end;
}

.Grid--fill > .Grid-cell {
  flex: 1 1 0%; /* 1 */
}

.Grid--fit > .Grid-cell {
  flex-basis: auto;
}

.Grid--equalHeight > .Grid-cell {
  display: flex;
}

.Grid--withExtraSmallGutter {
  margin-left: -0.3333333333rem;
}
.Grid--withExtraSmallGutter > .Grid-cell {
  padding-left: 0.3333333333rem;
}

.Grid--withSmallGutter {
  margin-left: -0.6666666667rem;
}
.Grid--withSmallGutter > .Grid-cell {
  padding-left: 0.6666666667rem;
}

.Grid--withGutter {
  margin-left: -1.3333333333rem;
}
.Grid--withGutter > .Grid-cell {
  padding-left: 1.3333333333rem;
}

.Grid--withLargeGutter {
  margin-left: -2.6666666667rem;
}
.Grid--withLargeGutter > .Grid-cell {
  padding-left: 2.6666666667rem;
}

.Grid--withExtraLargeGutter {
  margin-left: -5.3333333333rem;
}
.Grid--withExtraLargeGutter > .Grid-cell {
  padding-left: 5.3333333333rem;
}

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0;
}
.Icon svg {
  fill: currentColor;
  height: 1em;
  width: 1em;
}
.Icon.Icon--baseline svg {
  top: 0.125em;
  position: relative;
}

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
}
.Nav-item {
  margin: 0;
}
.Nav-link {
  user-select: none;
  display: block;
}
.Nav-link, .Nav-link:active, .Nav-link:focus, .Nav-link:hover {
  text-decoration: none;
}
.Nav--stacked .Nav-list {
  flex-direction: column;
  flex-wrap: nowrap;
}
.Nav--fit:not(.Nav--stacked) .Nav-item {
  flex: 1 1 auto;
}
.Nav--alignCenter {
  text-align: center;
}
.Nav--alignCenter:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
  justify-content: center;
}
.Nav--alignRight {
  text-align: right;
}
.Nav--alignRight:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
  justify-content: flex-end;
}

/* Structure */
.Wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer";
  min-height: 100%;
}

.Header {
  position: relative;
  z-index: 3;
  grid-area: header;
  height: 5.8333333333rem;
  transition: height 0.3s;
}
@media screen and (min-width: 64em) {
  .Header {
    height: auto;
  }
}

.Main {
  position: relative;
  z-index: 2;
  grid-area: main;
  overflow-x: hidden;
  margin-top: 0rem;
}
@media screen and (min-width: 48em) {
  .Main {
    position: relative;
  }
  .Main::after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2223%22%20viewBox%3D%220%200%2040%2021%22%3E%3Cline%20id%3D%22Line_25%22%20data-name%3D%22Line%2025%22%20x2%3D%2240%22%20transform%3D%22translate(40%2020.5)%20rotate(180)%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%20stroke-width%3D%221%22%2F%3E%3Cline%20id%3D%22Line_21%22%20data-name%3D%22Line%2021%22%20x2%3D%2220%22%20transform%3D%22translate(40%2015.5)%20rotate(180)%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%20stroke-width%3D%221%22%2F%3E%3Cline%20id%3D%22Line_22%22%20data-name%3D%22Line%2022%22%20x2%3D%2220%22%20transform%3D%22translate(40%2010.5)%20rotate(180)%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%20stroke-width%3D%221%22%2F%3E%3Cline%20id%3D%22Line_23%22%20data-name%3D%22Line%2023%22%20x2%3D%2220%22%20transform%3D%22translate(40%205.5)%20rotate(180)%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%20stroke-width%3D%221%22%2F%3E%3Cline%20id%3D%22Line_24%22%20data-name%3D%22Line%2024%22%20x2%3D%2220%22%20transform%3D%22translate(40%201.5)%20rotate(180)%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E");
    background-position: top;
    background-repeat: repeat-y;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 40px;
    height: 100%;
  }
}

.Footer {
  position: relative;
  z-index: 1;
  grid-area: footer;
}

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid var(--bs-border-color);
  table-layout: fixed;
  text-align: left;
  width: 100%;
}
.Table th,
.Table td {
  vertical-align: middle;
  padding: 0.2222222222rem 0.4444444444rem 0.2222222222rem 0.4444444444rem;
}
.Table thead {
  background-color: #f5f5f5;
  border-bottom: 2px solid var(--bs-border-color);
}
.Table tbody tr + tr {
  border-top: 1px solid var(--bs-border-color);
}
.Table tfoot {
  background-color: #f5f5f5;
  border-top: 2px solid var(--bs-border-color);
}
@media screen and (max-width: 47.9375em) {
  .Table.is-responsive {
    border-width: 0;
  }
  .Table.is-responsive,
  .Table.is-responsive tbody,
  .Table.is-responsive tfoot,
  .Table.is-responsive tr {
    display: block;
  }
  .Table.is-responsive thead {
    display: none;
  }
  .Table.is-responsive tbody tr {
    border: 1px solid var(--bs-border-color);
    border-bottom-width: 0;
  }
  .Table.is-responsive tfoot {
    border: 1px solid var(--bs-border-color);
    border-top-width: 2px;
  }
  .Table.is-responsive .Table-cell {
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }
  .Table.is-responsive .Table-cell[data-table-label]::before {
    content: attr(data-table-label);
    display: block;
    margin: -0.6666666667rem -0.6666666667rem 0.6666666667rem;
    padding: 0.6666666667rem;
    background-color: #fafafa;
    border-top: 1px solid var(--bs-border-color);
    border-bottom: 1px solid var(--bs-border-color);
  }
}

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa;
}
@media screen and (max-width: 47.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent;
  }
}

.Toggle {
  height: 0.6666666667rem;
  width: 1.3333333333rem;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg);
}
.Toggle-item {
  height: 0.0333333333rem;
  width: 100%;
  position: absolute;
  transition-property: top, transform, opacity;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  background-color: currentColor;
  border-radius: 0px;
  display: block;
  transform: rotate(0deg);
}
.Toggle-item:nth-child(1) {
  top: 0;
}
.show-nav .Toggle-item:nth-child(1) {
  top: 0.3166666667rem;
  transform: rotate(135deg);
}
.Toggle-item:nth-child(2) {
  top: 0.3166666667rem;
}
.show-nav .Toggle-item:nth-child(2) {
  opacity: 0;
}
.Toggle-item:nth-child(3) {
  top: 0.6333333333rem;
}
.show-nav .Toggle-item:nth-child(3) {
  top: 0.3166666667rem;
  transform: rotate(-135deg);
}

/* Alert */
.Alert--primary {
  color: #6f000f;
  background-color: #f7ccd2;
  border-color: #f4b8bf;
}
.Alert--primary hr {
  border-bottom-color: #f1a2ab;
}
.Alert--primary .Alert-link {
  color: #3c0008;
}
.Alert--secondary {
  color: #666666;
  background-color: #f3f3f3;
  border-color: #eeeeee;
}
.Alert--secondary hr {
  border-bottom-color: #e1e1e1;
}
.Alert--secondary .Alert-link {
  color: #4d4d4d;
}
.Alert--success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce;
}
.Alert--success hr {
  border-bottom-color: #bbe1bd;
}
.Alert--success .Alert-link {
  color: #18381a;
}
.Alert--info {
  color: #114e7e;
  background-color: #d3eafd;
  border-color: #c1e2fc;
}
.Alert--info hr {
  border-bottom-color: #a9d7fb;
}
.Alert--info .Alert-link {
  color: #0b3251;
}
.Alert--warning {
  color: #854f00;
  background-color: #ffeacc;
  border-color: #ffe2b8;
}
.Alert--warning hr {
  border-bottom-color: #ffd89f;
}
.Alert--warning .Alert-link {
  color: #523100;
}
.Alert--danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7;
}
.Alert--danger hr {
  border-bottom-color: #fbb3af;
}
.Alert--danger .Alert-link {
  color: #551713;
}
.Alert--light {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.Alert--light hr {
  border-bottom-color: #efefef;
}
.Alert--light .Alert-link {
  color: #666666;
}
.Alert--dark {
  color: #222222;
  background-color: #d9d9d9;
  border-color: #cacaca;
}
.Alert--dark hr {
  border-bottom-color: #bdbdbd;
}
.Alert--dark .Alert-link {
  color: #090909;
}
.Alert-title {
  color: inherit;
}
.Alert-link {
  font-weight: 700;
}

.ff-form-errors,
.ff-errors {
  border: 1px solid;
  border-radius: 11.25px;
  margin-bottom: 1.3333333333rem;
  padding: 0.6666666667rem 0.8888888889rem;
  position: relative;
  border-color: #D6001C;
  color: #D6001C;
  background-color: rgba(214, 0, 28, 0.2);
  margin-bottom: 1.3333333333rem !important;
}
.ff-form-errors > p,
.ff-errors > p {
  margin-bottom: 0;
}

.ff-form-success {
  border: 1px solid;
  border-radius: 11.25px;
  margin-bottom: 1.3333333333rem;
  padding: 0.6666666667rem 0.8888888889rem;
  position: relative;
  background-color: #dbefdc;
  border-color: #cde9ce;
  color: #285b2a;
  margin-bottom: 1.3333333333rem !important;
}
.ff-form-success > p {
  margin-bottom: 0;
}

.ff-errors {
  margin-top: 1.3333333333rem !important;
}
.ff-errors li:before {
  content: none !important;
}

/* Button */
.Button--primary {
  background-color: #D6001C;
  border-color: #D6001C;
  color: #ffffff;
}
.Button--primary:active, .Button--primary.is-active {
  background-color: #a30015;
  border-color: #960014;
  color: #ffffff;
}
.Button--primary:focus, .Button--primary.is-focus {
  background-color: #D6001C;
  border-color: #D6001C;
  box-shadow: 0 0 0 0.2rem rgba(220, 38, 62, 0.5);
  color: #ffffff;
}
.Button--primary:hover, .Button--primary.is-hover {
  background-color: #b00017;
  border-color: #a30015;
  color: #ffffff;
}
.Button--primary.Button--outline {
  background-color: transparent;
  color: #D6001C;
}
.Button--primary.Button--outline:active, .Button--primary.Button--outline:hover, .Button--primary.Button--outline.is-active, .Button--primary.Button--outline.is-hover {
  background-color: #D6001C;
  border-color: #D6001C;
  color: #ffffff;
}
.Button--primary:disabled, .Button--primary.is-disabled {
  background-color: #D6001C;
  border-color: #D6001C;
  color: #ffffff;
}
.Button--secondary {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
  color: rgba(0, 0, 0, 0.7);
}
.Button--secondary:active, .Button--secondary.is-active {
  background-color: #ababab;
  border-color: #a4a4a4;
  color: #ffffff;
}
.Button--secondary:focus, .Button--secondary.is-focus {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
  box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5);
  color: rgba(0, 0, 0, 0.7);
}
.Button--secondary:hover, .Button--secondary.is-hover {
  background-color: #b1b1b1;
  border-color: #ababab;
  color: #ffffff;
}
.Button--secondary.Button--outline {
  background-color: transparent;
  color: #c4c4c4;
}
.Button--secondary.Button--outline:active, .Button--secondary.Button--outline:hover, .Button--secondary.Button--outline.is-active, .Button--secondary.Button--outline.is-hover {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
  color: rgba(0, 0, 0, 0.7);
}
.Button--secondary:disabled, .Button--secondary.is-disabled {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
  color: rgba(0, 0, 0, 0.7);
}
.Button--success {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff;
}
.Button--success:active, .Button--success.is-active {
  background-color: #3d8b40;
  border-color: #39833c;
  color: #ffffff;
}
.Button--success:focus, .Button--success.is-focus {
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(103, 187, 106, 0.5);
  color: #ffffff;
}
.Button--success:hover, .Button--success.is-hover {
  background-color: #409444;
  border-color: #3d8b40;
  color: #ffffff;
}
.Button--success.Button--outline {
  background-color: transparent;
  color: #4caf50;
}
.Button--success.Button--outline:active, .Button--success.Button--outline:hover, .Button--success.Button--outline.is-active, .Button--success.Button--outline.is-hover {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff;
}
.Button--success:disabled, .Button--success.is-disabled {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff;
}
.Button--info {
  background-color: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}
.Button--info:active, .Button--info.is-active {
  background-color: #0c7cd5;
  border-color: #0b75c9;
  color: #ffffff;
}
.Button--info:focus, .Button--info.is-focus {
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: 0 0 0 0.2rem rgba(66, 166, 245, 0.5);
  color: #ffffff;
}
.Button--info:hover, .Button--info.is-hover {
  background-color: #0c83e2;
  border-color: #0c7cd5;
  color: #ffffff;
}
.Button--info.Button--outline {
  background-color: transparent;
  color: #2196f3;
}
.Button--info.Button--outline:active, .Button--info.Button--outline:hover, .Button--info.Button--outline.is-active, .Button--info.Button--outline.is-hover {
  background-color: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}
.Button--info:disabled, .Button--info.is-disabled {
  background-color: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}
.Button--warning {
  background-color: #ff9800;
  border-color: #ff9800;
  color: #ffffff;
}
.Button--warning:active, .Button--warning.is-active {
  background-color: #cc7a00;
  border-color: #bf7200;
  color: #ffffff;
}
.Button--warning:focus, .Button--warning.is-focus {
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 0 0 0.2rem rgba(255, 167, 38, 0.5);
  color: #ffffff;
}
.Button--warning:hover, .Button--warning.is-hover {
  background-color: #d98100;
  border-color: #cc7a00;
  color: #ffffff;
}
.Button--warning.Button--outline {
  background-color: transparent;
  color: #ff9800;
}
.Button--warning.Button--outline:active, .Button--warning.Button--outline:hover, .Button--warning.Button--outline.is-active, .Button--warning.Button--outline.is-hover {
  background-color: #ff9800;
  border-color: #ff9800;
  color: #ffffff;
}
.Button--warning:disabled, .Button--warning.is-disabled {
  background-color: #ff9800;
  border-color: #ff9800;
  color: #ffffff;
}
.Button--danger {
  background-color: #f44336;
  border-color: #f44336;
  color: #ffffff;
}
.Button--danger:active, .Button--danger.is-active {
  background-color: #ea1c0d;
  border-color: #de1b0c;
  color: #ffffff;
}
.Button--danger:focus, .Button--danger.is-focus {
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(246, 95, 84, 0.5);
  color: #ffffff;
}
.Button--danger:hover, .Button--danger.is-hover {
  background-color: #f22112;
  border-color: #ea1c0d;
  color: #ffffff;
}
.Button--danger.Button--outline {
  background-color: transparent;
  color: #f44336;
}
.Button--danger.Button--outline:active, .Button--danger.Button--outline:hover, .Button--danger.Button--outline.is-active, .Button--danger.Button--outline.is-hover {
  background-color: #f44336;
  border-color: #f44336;
  color: #ffffff;
}
.Button--danger:disabled, .Button--danger.is-disabled {
  background-color: #f44336;
  border-color: #f44336;
  color: #ffffff;
}
.Button--light {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.7);
}
.Button--light:active, .Button--light.is-active {
  background-color: gainsboro;
  border-color: #d5d5d5;
  color: rgba(0, 0, 0, 0.7);
}
.Button--light:focus, .Button--light.is-focus {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5);
  color: rgba(0, 0, 0, 0.7);
}
.Button--light:hover, .Button--light.is-hover {
  background-color: #e2e2e2;
  border-color: gainsboro;
  color: rgba(0, 0, 0, 0.7);
}
.Button--light.Button--outline {
  background-color: transparent;
  color: #f5f5f5;
}
.Button--light.Button--outline:active, .Button--light.Button--outline:hover, .Button--light.Button--outline.is-active, .Button--light.Button--outline.is-hover {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.7);
}
.Button--light:disabled, .Button--light.is-disabled {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.7);
}
.Button--dark {
  background-color: #424242;
  border-color: #424242;
  color: #ffffff;
}
.Button--dark:active, .Button--dark.is-active {
  background-color: #292929;
  border-color: #222222;
  color: #ffffff;
}
.Button--dark:focus, .Button--dark.is-focus {
  background-color: #424242;
  border-color: #424242;
  box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5);
  color: #ffffff;
}
.Button--dark:hover, .Button--dark.is-hover {
  background-color: #2f2f2f;
  border-color: #292929;
  color: #ffffff;
}
.Button--dark.Button--outline {
  background-color: transparent;
  color: #424242;
}
.Button--dark.Button--outline:active, .Button--dark.Button--outline:hover, .Button--dark.Button--outline.is-active, .Button--dark.Button--outline.is-hover {
  background-color: #424242;
  border-color: #424242;
  color: #ffffff;
}
.Button--dark:disabled, .Button--dark.is-disabled {
  background-color: #424242;
  border-color: #424242;
  color: #ffffff;
}
.Button--link {
  color: #D6001C;
  font-weight: inherit;
  text-decoration: underline;
}
.Button--link:hover, .Button--link.is-focus {
  color: #000000;
  text-decoration: none;
}
.Button--link:focus, .Button--link.is-focus {
  box-shadow: none;
  text-decoration: none;
}
.Button--large {
  font-size: 1.25rem;
  padding: 0.0888888889em 0.4444444444em 0.0888888889em 0.4444444444em;
}
.Button--small {
  font-size: 0.875rem;
  padding: 0.05em 0.25em 0.05em 0.25em;
}
.Button--full {
  display: block;
  width: 100%;
}
.Button--full + .Button--full {
  margin-top: 0.6666666667rem;
}

.Modal {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
}
.Modal, .Modal:before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.Modal::before {
  background-color: #eeeeee;
  content: "";
  z-index: -1;
}
.Modal:not(.is-active) {
  opacity: 0;
  visibility: hidden;
}
.Modal-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}
.Modal-innerBody {
  border-radius: 0;
  border: 1px solid #dee2e6;
  border-color: #dee2e6;
  margin: 1.2666666667rem 1.2666666667rem 1.2666666667rem 1.2666666667rem;
  width: auto;
  position: relative;
}
@media screen and (min-width: 37.5em) {
  .Modal-innerBody {
    margin: 2.6666666667rem auto;
    max-width: 42.7333333333rem;
    width: 100%;
  }
}
.Modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0.6666666667rem 0.6666666667rem 2rem 0.6666666667rem;
}
.Modal-title {
  font-size: 1rem;
  line-height: 1.3333333333rem;
  margin-bottom: 0;
  font-weight: 300;
  padding: 0rem 1.3333333333rem;
  color: #000000;
  text-align: center;
}
.Modal-title--landing {
  text-transform: none;
}
.Modal-close {
  font-size: 1.5rem;
  line-height: 1.2;
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  cursor: pointer;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  top: -40px;
  right: 0px;
  padding: 0.6666666667rem 0.6666666667rem 0.6666666667rem 0.6666666667rem;
  margin: -0.6666666667rem -0.6666666667rem -0.6666666667rem auto;
}
html:not(.is-tabbing) .Modal-close:focus {
  outline: 0;
}
.Modal-close:not(:hover) {
  opacity: 0.5;
}
.Modal-body {
  padding: 0.6666666667em 0.6666666667em 0.6666666667em 0.6666666667em;
}
.Modal-body .Block .Tile-imageWrapper {
  height: 8.4333333333rem;
}
@media screen and (max-width: 37.5em) {
  .Modal-body .Block .Tile-imageWrapper {
    height: 5rem;
  }
}
.Modal-body .Block .Tile-title {
  font-size: 1.1333333333rem;
  line-height: 1.1333333333rem;
  position: static;
  padding: 0;
  height: auto;
  width: auto;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-align: left;
  display: block;
}
.Modal-body .Block .Tile-link {
  padding: 0.1666666667rem 0.5rem;
  font-size: 0.7333333333rem;
}
.Modal-body .Block .Tile-bottom {
  background-color: #ffffff;
  padding-top: 1rem;
  padding-left: 1.3333333333rem;
  padding-right: 1.3333333333rem;
  padding-bottom: 3.3333333333rem !important;
}
.Modal-body .Block .Tile.is-red .Tile-title {
  color: #D6001C;
}
.Modal-body .Block .Tile.is-green .Tile-title {
  color: #006341;
}
.Modal-body .Block .Tile.is-black .Tile-title {
  color: #000000;
}
.Modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.6666666667em 0.6666666667em 0.6666666667em 0.6666666667em;
  border-top: 1px solid #e0e0e0;
}

.Modal-datasheet::before {
  background-color: rgba(255, 255, 255, 0.7);
}
.Modal-datasheet .Modal-inner {
  margin: 0 auto;
  max-width: 640px;
}
.Modal-datasheet .Modal-innerBody {
  background-color: #ffffff;
  padding: 1.3333333333rem;
}
.Modal-datasheet .Modal-title {
  font-size: 1.3333333333rem;
  line-height: 1.2;
  font-weight: bold;
}

/* Cookie */
.CookieConsent {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.CookieConsent:not(.is-active) {
  opacity: 0;
  visibility: hidden;
}
.CookieConsent-inner {
  background-color: #D6001C;
  color: #ffffff;
  padding: 2.6666666667rem;
  max-width: 23.3333333333rem;
  max-height: 90%;
  width: 90%;
  overflow-y: auto;
}
.CookieConsent a {
  font-weight: 700;
}
.CookieConsent a, .CookieConsent a:active, .CookieConsent a:focus, .CookieConsent a:hover {
  color: inherit;
}
.CookieConsent-close {
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  font-size: 150%;
  margin-left: 1.3333333333rem;
  line-height: 1;
  transition-property: opacity;
  transition-duration: 200ms;
}
.CookieConsent-close:not(:hover) {
  opacity: 0.75;
}

.cookieConsent__Right .btn-primary {
  margin-right: 20px;
}

.Site-rubidor .cookieConsent__Content .h2 {
  color: #006341;
}
.Site-rubidor .cookieConsent__Right .btn-primary {
  background: #006341;
  border-color: #006341;
}
.Site-rubidor .cookieConsent__Right .btn-primary:focus {
  box-shadow: none;
}
.Site-rubidor .cookieConsentOperations .btn-primary {
  background: #006341;
  border-color: #006341;
}
.Site-rubidor .cookieConsentOperations .btn-primary:focus {
  box-shadow: none;
}

/* Navigation */
.Navigation {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 64em) {
  .Navigation {
    margin-left: 1.3333333333rem;
  }
}
.Navigation-toggle {
  display: block;
  color: #c4c4c4;
}
.Navigation-top {
  display: flex;
  justify-content: space-between;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  align-items: center;
  flex-direction: column;
}
@media screen and (min-width: 64em) {
  .Navigation-top {
    flex-direction: row;
  }
}
.Navigation-top-flex {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.Navigation-top-legal {
  font-size: 16px;
  color: #707070;
  text-align: center;
}
.Navigation-top-legal a {
  color: #707070 !important;
}
.Navigation-top-border {
  border-bottom: 1px solid #c2c2c2;
}
.Navigation-bottom {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media screen and (min-width: 64em) {
  .Navigation-bottom {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.Navigation-parent {
  flex-direction: column;
}
.Navigation-body {
  display: flex;
  margin-left: -1.3333333333rem;
}
@media screen and (min-width: 64em) {
  .Navigation-body > .Nav {
    padding-left: 1.3333333333rem;
  }
}
@media screen and (max-width: 63.9375em) {
  .Navigation-body {
    position: fixed;
    top: 5.8333333333rem;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    background-color: #ffffff;
    flex-direction: row;
    padding: 1.3333333333rem;
    pointer-events: auto;
  }
  html:not(.show-nav) .Navigation-body {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  html.show-nav .Navigation-body {
    margin: 0;
    background: #eeeeee;
  }
}
/* Types */
/* Main */
@media screen and (max-width: 63.9375em) {
  .Nav--main {
    flex-grow: 1;
    overflow-y: auto;
  }
}
@media screen and (max-width: 63.9375em) {
  .Nav--main .Nav-list {
    display: block;
    margin-left: -10px;
  }
}
@media screen and (min-width: 64em) {
  .Nav--main .Nav-list {
    margin-left: -1.3333333333rem;
  }
}
.Nav--main .Nav-item {
  display: flex;
  padding-left: 0;
  transition: padding 0.3s;
}
@media screen and (min-width: 64em) {
  .Nav--main .Nav-item {
    padding-left: 0.6666666667rem;
  }
}
@media screen and (min-width: 80em) {
  .Nav--main .Nav-item {
    padding-left: 1rem;
  }
}
.Nav--main .Nav-item.has-children {
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (min-width: 64em) {
  .Nav--main .Nav-item.has-children {
    align-items: center;
    flex-direction: row;
  }
}
.Nav--main .Nav-item.Nav-button .Nav-link {
  color: #D6001C;
  border: 1px solid #D6001C;
  background: linear-gradient(to right, #D6001C 48%, transparent 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  transition: all 0.3s;
}
.Nav--main .Nav-item.Nav-button .Nav-link:hover, .Nav--main .Nav-item.Nav-button .Nav-link.is-active {
  color: #ffffff !important;
  background-position: left bottom;
}
.Nav--main .Nav-item .Caret {
  cursor: pointer;
  transform: rotate(-90deg);
  transition: transform 0.3s;
  margin-right: 0.25rem;
}
.Nav--main .Nav-item .Nav-link.has-children {
  display: flex;
  align-items: center;
}
.Nav--main .Nav-item .Nav-link.is-open .Caret {
  transform: rotate(0deg);
}
@media screen and (max-width: 63.9375em) {
  .Nav--main .Nav-item + .Nav-item {
    margin-top: 0.3333333333rem;
  }
  .show-nav .Nav--main .Nav-item {
    transition-property: opacity, transform;
    transition-duration: 300ms, 1s;
    transition-timing-function: linear, cubic-bezier(0.19, 1, 0.22, 1);
  }
  .show-nav .Nav--main .Nav-item:nth-child(1) {
    transition-delay: 0.02s;
  }
  .show-nav .Nav--main .Nav-item:nth-child(2) {
    transition-delay: 0.04s;
  }
  .show-nav .Nav--main .Nav-item:nth-child(3) {
    transition-delay: 0.06s;
  }
  .show-nav .Nav--main .Nav-item:nth-child(4) {
    transition-delay: 0.08s;
  }
  .show-nav .Nav--main .Nav-item:nth-child(5) {
    transition-delay: 0.1s;
  }
  .show-nav .Nav--main .Nav-item:nth-child(6) {
    transition-delay: 0.12s;
  }
  .show-nav .Nav--main .Nav-item:nth-child(7) {
    transition-delay: 0.14s;
  }
  .show-nav .Nav--main .Nav-item:nth-child(8) {
    transition-delay: 0.16s;
  }
  .show-nav .Nav--main .Nav-item:nth-child(9) {
    transition-delay: 0.18s;
  }
  .show-nav .Nav--main .Nav-item:nth-child(10) {
    transition-delay: 0.2s;
  }
  html:not(.show-nav) .Nav--main .Nav-item {
    opacity: 0;
    transform: translateY(1.3333333333rem);
  }
}
.Nav--main .Nav-link {
  font-size: 0.6666666667rem;
  color: #707070;
  padding: 0.0666666667rem 0.3333333333rem;
  text-transform: uppercase;
}
@media screen and (min-width: 64em) {
  .Nav--main .Nav-link {
    white-space: nowrap;
  }
}
.Nav--main .Nav-link:active, .Nav--main .Nav-link:focus, .Nav--main .Nav-link:hover {
  color: #000000;
}
.Nav--main .Nav-link.is-active {
  color: #ffffff;
  background-color: #D6001C;
}

/* Lang */
.Nav--lang {
  font-size: 0.6666666667rem;
  color: #707070;
  position: relative;
  z-index: 1;
}
.Nav--lang:not(:hover) .Nav-list {
  opacity: 0;
  visibility: hidden;
}
.Nav--lang .Nav-toggle {
  cursor: default;
  align-items: center;
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
  column-gap: 4px;
}
.Nav--lang .Nav-toggle > svg {
  transform: translateY(2px);
}
.Nav--lang .Nav-toggle .Caret {
  height: 0.1333333333rem;
  width: 0.2666666667rem;
  position: relative;
  margin-left: 0.3333333333rem;
}
.Nav--lang .Nav-toggle .Caret:before, .Nav--lang .Nav-toggle .Caret:after {
  content: "";
  position: absolute;
}
.Nav--lang .Nav-toggle .Caret:before {
  top: 0;
  left: 0;
  border-top: 0.1333333333rem solid currentColor;
  border-left: 0.1333333333rem solid transparent;
  border-right: 0.1333333333rem solid transparent;
}
.Nav--lang .Nav-toggle .Caret:after {
  left: 0.0333333333rem;
  top: 0;
  border-top: 0.1rem solid #ffffff;
  border-left: 0.1rem solid transparent;
  border-right: 0.1rem solid transparent;
}
.Nav--lang .Nav-list {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  position: absolute;
  top: 100%;
  right: 0;
  color: #000000;
  flex-direction: column;
  background-color: #eeeeee;
}
.Nav--lang .Nav-link {
  color: currentColor;
  text-transform: uppercase;
  padding: 0.3333333333rem 0.6666666667rem;
}
.Nav--lang .Nav-link.is-active {
  background-color: #bdbdbd;
}
.Nav--lang .Nav-link:not(.is-active):hover {
  background-color: #e0e0e0;
}

.Nav--footer .Nav-list {
  display: block;
  margin-top: -0.0333333333rem;
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Nav--footer .Nav-list {
    column-count: 2;
    column-fill: auto;
  }
}
@media screen and (min-width: 80em) {
  .Nav--footer .Nav-list {
    column-count: 2;
    column-fill: auto;
  }
}
.Nav--footer .Nav-link {
  font-size: 0.7333333333rem;
  display: inline-block;
  color: #ffffff;
  font-weight: 300;
  text-transform: uppercase;
  transition: border-color 0.3s;
  border-bottom: 1px solid #D6001C;
}
.Nav--footer .Nav-link:hover {
  border-color: #ffffff;
}

.Nav--legal .Nav-list {
  margin-left: -0.6666666667rem;
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Nav--legal .Nav-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
.Nav--legal .Nav-item {
  margin-left: 0.6666666667rem;
}
@media screen and (min-width: 64em) {
  .Nav--legal .Nav-item:not(.is-prefix) + .Nav-item:not(.is-prefix) {
    position: relative;
  }
  .Nav--legal .Nav-item:not(.is-prefix) + .Nav-item:not(.is-prefix)::before {
    content: "–";
    position: absolute;
    left: -15px;
    color: #ffffff;
  }
}
.Nav--legal .Nav-link {
  font-size: 0.7333333333rem;
  color: #ffffff;
  font-weight: 300;
  text-transform: lowercase;
  transition: border-color 0.3s;
  border-bottom: 1px solid #D6001C;
}
.Nav--legal .Nav-link:hover {
  border-color: #ffffff;
}

.Nav--socialMedia {
  margin-top: 1.3333333333rem;
}
@media screen and (min-width: 48em) {
  .Nav--socialMedia {
    margin-top: 0;
  }
}
.Nav--socialMedia .Nav-list {
  margin-left: -0.3333333333rem;
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Nav--socialMedia .Nav-list {
    justify-content: center;
  }
}
.Nav--socialMedia .Nav-item {
  margin-left: 0.3333333333rem;
}
.Nav--socialMedia .Nav-link {
  border: none;
}

@media screen and (max-width: 63.9375em) {
  .Nav--children.Nav--children-1 {
    margin-top: 0.5666666667rem;
    margin-left: 1.3333333333rem;
  }
  .Nav--children.Nav--children-1 .Nav-item-dropdown + .Nav-item-dropdown {
    margin-top: 0;
  }
  .Nav--children.Nav--children-2 {
    margin-left: 1.8333333333rem !important;
  }
  .Nav--children.Nav--children-2 .Nav-item-dropdown + .Nav-item-dropdown {
    margin-top: 0;
  }
}
@media screen and (min-width: 64em) {
  .Nav--children {
    min-width: 7.1666666667rem;
  }
  .Nav--children.Nav--children-1 {
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 100%;
    left: 0.3333333333rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 64em) {
  .Nav--children.Nav--children-1 {
    position: absolute;
    top: 100%;
    left: 0.6666666667rem;
  }
}
@media screen and (min-width: 64em) and (min-width: 80em) {
  .Nav--children.Nav--children-1 {
    position: absolute;
    top: 100%;
    left: 1rem;
  }
}
@media screen and (min-width: 64em) {
  .Nav--children.Nav--children-1 .Nav-item:last-child .Nav--children {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 64em) {
  .Nav--children.Nav--children-1 .Nav-link.is-open ~ .Nav--children-2 {
    margin: 0.3333333333rem 0rem;
  }
}
@media screen and (min-width: 64em) {
  .Nav-item:not(:hover) > .Nav--children.Nav--children-1 {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  .Nav-item:not(:hover) > .Nav--children.Nav--children-1 * {
    pointer-events: none;
  }
}
@media screen and (min-width: 64em) {
  .Nav--children .Nav-list {
    margin: 0;
    padding: 0;
    text-align: left;
  }
}
@media screen and (min-width: 64em) {
  .Nav--children .Nav-item {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .Nav--children .Nav-link {
    padding: 0;
  }
  .Nav--children .Nav-link.is-active {
    font-weight: 700;
  }
  .Nav--children .Nav-link:hover {
    color: #000000;
  }
}
@media screen and (min-width: 64em) {
  .Nav--childrenBackground {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
    padding: 0.6666666667rem;
  }
}
@media screen and (min-width: 64em) {
  .Nav--children.Nav--children-2 .Nav-link {
    padding-left: 40px;
  }
}
.Nav--children .Nav-item {
  display: flex;
  flex-direction: column;
}
.Nav--children.Nav--children-1 {
  z-index: 2;
}
.Nav--children.Nav--children-1 .Nav-link.is-active {
  color: #000000 !important;
  background-color: transparent !important;
}
.Nav--children.Nav--children-1 .Nav-link.is-closed ~ .Nav--children-2 {
  height: 0;
  margin: 0;
}
.Nav--children.Nav--children-1 .Nav-link.is-open ~ .Nav--children-2 {
  opacity: 1;
  height: auto;
  visibility: visible;
}
.Nav--children.Nav--children-2 {
  opacity: 0;
  visibility: hidden;
  transition: height 0.3s, margin 0.3s;
}

.Nav--pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1.3333333333rem;
}
.Nav--pagination .Nav-list {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  list-style-type: none;
  border: 1px solid #c9c9c9;
}
.Nav--pagination .Nav-item {
  font-size: 0.6666666667rem;
  color: #000000;
}
.Nav--pagination .Nav-item::before {
  display: none;
}
.Nav--pagination .Nav-item + .Nav-item .Nav-link {
  border-left: 1px solid #c9c9c9;
}
.Nav--pagination .Nav-link {
  font-size: 0.6666666667rem;
  color: #000000;
  padding: 0.25rem 0.5rem;
}
.Nav--pagination .Nav-link.is-active {
  font-weight: bold;
}
.Nav--pagination .Nav-link:hover {
  background-color: #e6e6e6;
}
.Nav--pagination .Nav-ellipsis {
  height: 100%;
  display: block;
  border-left: 1px solid #c9c9c9;
  padding: 0.25rem 0.5rem;
}

.Nav--breadcrumbs {
  margin-left: -0.6666666667rem;
  padding-bottom: 0.6666666667rem;
}
.Nav--breadcrumbs .Nav-item {
  margin-left: 0.6666666667rem;
}
.Nav--breadcrumbs .Nav-item + .Nav-item {
  position: relative;
}
.Nav--breadcrumbs .Nav-item + .Nav-item::before {
  font-size: 0.7333333333rem;
  content: "–";
  position: absolute;
  top: -3px;
  left: -15px;
  color: #c4c4c4;
}
.Nav--breadcrumbs .Nav-link {
  font-size: 0.6666666667rem;
  color: #c4c4c4;
  text-transform: uppercase;
}
.Nav--breadcrumbs .Nav-link:hover {
  color: #000000;
}

/* Fields */ /* Fields: field */
.Form-field--dropdown[multiple], .Form-field--dropdown, .Form-field--input,
.Form-field--textarea {
  font-size: 0.7333333333rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  background-color: #eeeeee;
  border-radius: 0;
  border: 0px solid #bdbdbd;
  color: #000000;
  vertical-align: middle;
  width: 100%;
  font-weight: 300;
}
.Form-field--dropdown[multiple]::placeholder, .Form-field--dropdown::placeholder, .Form-field--input::placeholder,
.Form-field--textarea::placeholder {
  color: #898989;
}
@media screen and (min-width: 37.5em) {
  .Form-field--dropdown[multiple], .Form-field--dropdown, .Form-field--input,
  .Form-field--textarea {
    max-width: 20rem;
  }
}
.Form-item.is-error .Form-field--dropdown, .Form-item.is-error .Form-field--input,
.Form-item.is-error .Form-field--textarea {
  border-color: #f44336;
}
.Form-item.is-success .Form-field--dropdown, .Form-item.is-success .Form-field--input,
.Form-item.is-success .Form-field--textarea {
  border-color: #4caf50;
}

.Form-field--dropdown, .Form-field--input,
.Form-field--textarea {
  box-shadow: none;
}
.Form-field--dropdown:focus, .Form-field--input:focus,
.Form-field--textarea:focus {
  border-color: #757575;
  box-shadow: none , none;
  outline: 0;
  z-index: 1;
}
.Form-item.is-error .Form-field--dropdown:focus, .Form-item.is-error .Form-field--input:focus,
.Form-item.is-error .Form-field--textarea:focus {
  box-shadow: none , 0 0 2px 1px rgba(244, 67, 54, 0.6);
}
.Form-item.is-success .Form-field--dropdown:focus, .Form-item.is-success .Form-field--input:focus,
.Form-item.is-success .Form-field--textarea:focus {
  box-shadow: none , 0 0 2px 1px rgba(76, 175, 80, 0.6);
}

.Form-field {
  font-family: inherit;
  position: relative;
}

.Form-indicator {
  user-select: none;
}

.Form-field--xs {
  max-width: 4rem !important;
}

.Form-field--sm {
  max-width: 6.6666666667rem !important;
}

.Form-field--md {
  max-width: 13.3333333333rem !important;
}

.Form-field--lg {
  max-width: 20rem !important;
}

.Form-field--xl {
  max-width: 26.6666666667rem !important;
}

.Form-field--full {
  max-width: none !important;
}

/* Fields: check */
.Form-field--radio,
.Form-field--checkbox {
  cursor: pointer;
  display: flex;
  user-select: none;
}
.Form-field--radio:not(:first-of-type),
.Form-field--checkbox:not(:first-of-type) {
  margin-top: 0.1666666667rem;
}
.Form-field--radio input[type=radio],
.Form-field--radio input[type=checkbox],
.Form-field--checkbox input[type=radio],
.Form-field--checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.Form-field--radio input[type=radio]:checked ~ .Form-indicator,
.Form-field--radio input[type=checkbox]:checked ~ .Form-indicator,
.Form-field--checkbox input[type=radio]:checked ~ .Form-indicator,
.Form-field--checkbox input[type=checkbox]:checked ~ .Form-indicator {
  background-color: #757575;
  border-color: #757575;
}
.Form-field--radio input[type=radio]:focus ~ .Form-indicator,
.Form-field--radio input[type=checkbox]:focus ~ .Form-indicator,
.Form-field--checkbox input[type=radio]:focus ~ .Form-indicator,
.Form-field--checkbox input[type=checkbox]:focus ~ .Form-indicator {
  border-color: #757575;
  box-shadow: none;
}
.Form-field--radio .Form-indicator,
.Form-field--checkbox .Form-indicator {
  background-color: #ffffff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 0.3em 0.3em;
  border: 1px solid #bdbdbd;
  flex-shrink: 0;
  height: 1em;
  margin-right: 0.6666666667rem;
  width: 1em;
  position: relative;
  top: 0.1666666667em;
}

.Form-field--radio > .Form-indicator {
  border-radius: 100%;
}
.Form-field--radio input[type=radio]:checked ~ .Form-indicator::after {
  background-color: #ffffff;
  border-radius: 100%;
  content: "";
  height: 0.3em;
  width: 0.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Form-field--checkbox > .Form-indicator {
  border-radius: 0;
}
.Form-field--checkbox input[type=checkbox]:checked ~ .Form-indicator {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E");
}

/* Fields: file */
.Form-field--file {
  cursor: pointer;
  display: block;
}
.Form-field--file input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.Form-field--file input[type=file]:focus ~ .Form-indicator {
  border-color: #757575;
  box-shadow: none , none;
}
.Form-item.is-error .Form-field--file input[type=file]:focus ~ .Form-indicator {
  box-shadow: none , 0 0 2px 1px rgba(244, 67, 54, 0.6);
}
.Form-item.is-success .Form-field--file input[type=file]:focus ~ .Form-indicator {
  box-shadow: none , 0 0 2px 1px rgba(76, 175, 80, 0.6);
}
.Form-field--file .Form-indicator {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
  border-radius: 0;
  border: 0px solid #bdbdbd;
  box-shadow: none;
  color: #000000;
  display: block;
  line-height: normal;
  overflow: hidden;
  padding: 0.4333333333em 0.7333333333em 0.4333333333em 0.7333333333em;
  position: relative;
  white-space: nowrap;
  width: 100%;
}
.Form-field--file .Form-indicator::before {
  content: attr(data-file-text);
  display: block;
  padding-right: 3.3333333333rem;
}
.Form-field--file .Form-indicator::after {
  background-color: #eeeeee;
  border-left: 0px solid #bdbdbd;
  color: #757575;
  content: attr(data-file-button);
  padding: 0.4333333333em 0.7333333333em 0.4333333333em 0.7333333333em;
  text-align: center;
  width: 3.3333333333rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
@media screen and (min-width: 37.5em) {
  .Form-field--file {
    max-width: 20rem;
  }
}

/* Fields: input */
.Form-field--input {
  padding: 0.4333333333em 0.7333333333em 0.4333333333em 0.7333333333em;
}

.Form-field--textarea {
  padding: 0.3666666667em 0.7333333333em 0.3666666667em 0.7333333333em;
}

/* Fields: select */
select {
  cursor: pointer;
  width: 100% !important;
}
@media screen and (min-width: 37.5em) {
  select {
    max-width: 20rem;
  }
}

.Form-field--dropdown {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20292.36%20402%22%3E%3Cpath%20d%3D%22M18.27%2C164.45H274.09a18.17%2C18.17%2C0%2C0%2C0%2C12.85-31.12L159%2C5.42a17.93%2C17.93%2C0%2C0%2C0-25.7%2C0L5.42%2C133.33a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7A17.57%2C17.57%2C0%2C0%2C0%2C18.27%2C164.45Z%22%2F%3E%3Cpath%20d%3D%22M274.09%2C237.55H18.27A17.57%2C17.57%2C0%2C0%2C0%2C5.42%2C243a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7l127.91%2C127.9a17.92%2C17.92%2C0%2C0%2C0%2C25.7%2C0L286.93%2C268.66a18.27%2C18.27%2C0%2C0%2C0-12.85-31.12Z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.6666666667rem center;
  background-repeat: no-repeat;
  background-size: 0.6666666667rem;
  box-shadow: none;
  line-height: normal;
  overflow: hidden;
  padding: 0.4333333333em 1.3333333333em 0.4333333333em 0.7333333333em;
}

.Form-field--dropdown[multiple] {
  height: auto;
}
.Form-field--dropdown[multiple] option {
  padding: 0.2166666667em 0.7333333333em 0.2166666667em 0.7333333333em;
}
.Form-field--dropdown[multiple] option + option {
  border-top: 1px solid #bdbdbd;
}

/* Fields: recaptcha */
.Form-item--recaptcha {
  margin: 0.3333333333rem 0rem;
}
.Form-item--recaptcha .Form-controls {
  display: flex;
  justify-content: center;
}
.Footer .Form-item--recaptcha .Form-controls {
  justify-content: start;
}
.Form-item--recaptcha .Form-controls > div {
  margin: 0 auto;
}

/* Slider */ /* Slider: core */
.Slider {
  position: relative;
  z-index: 1;
  /* Fix of Webkit flickering */
  overflow: hidden;
}
.Slider--no-flexbox .Slider-item {
  float: left;
}
.Slider--vertical > .Slider-items {
  flex-direction: column;
}
.Slider-items {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  transition-property: transform;
}
.Slider--android .Slider-item, .Slider-items {
  transform: translate3d(0px, 0, 0);
}
.Slider--multirow > .Slider-items {
  flex-wrap: wrap;
}
.Slider--free-mode > .Slider-items {
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.Slider-item {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  transition-property: transform;
}
.Slider-item.is-blank {
  visibility: hidden;
}
.Slider--autoheight,
.Slider--autoheight .Slider-item {
  height: auto;
}
.Slider--autoheight .Slider-items {
  align-items: flex-start;
  transition-property: transform, height;
}
.Slider--3d {
  perspective: 1200px;
}
.Slider--3d .Slider-items,
.Slider--3d .Slider-item,
.Slider--3d .Slider-item-shadow-left,
.Slider--3d .Slider-item-shadow-right,
.Slider--3d .Slider-item-shadow-top,
.Slider--3d .Slider-item-shadow-bottom,
.Slider--3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}
.Slider--3d .Slider-item-shadow-left,
.Slider--3d .Slider-item-shadow-right,
.Slider--3d .Slider-item-shadow-top,
.Slider--3d .Slider-item-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.Slider--3d .Slider-item-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.Slider--3d .Slider-item-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.Slider--3d .Slider-item-shadow-top {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.Slider--3d .Slider-item-shadow-bottom {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.Slider--wp8-horizontal, .Slider--wp8-horizontal > .Slider-items {
  touch-action: pan-y;
}
.Slider--wp8-vertical, .Slider--wp8-vertical > .Slider-items {
  touch-action: pan-x;
}

/* Slider: a11y */
.Slider .Slider-notification {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  pointer-events: none;
  opacity: 0;
}

/* Slider: fade */
.Slider--fade.Slider--free-mode .Slider-item {
  transition-timing-function: ease-out;
}
.Slider--fade .Slider-item {
  pointer-events: none;
  transition-property: opacity;
}
.Slider--fade .Slider-item .Slider-item {
  pointer-events: none;
}
.Slider--fade .is-active, .Slider--fade .is-active .is-active {
  pointer-events: auto;
}

/* Slider: coverflow */
.Slider--coverflow .Slider-items {
  -ms-perspective: 1200px; /* Windows 8 IE 10 fix */
}

/* Slider: cube */
.Slider--cube {
  overflow: visible;
}
.Slider--cube .Slider-item {
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  pointer-events: none;
  transform-origin: 0 0;
  visibility: hidden;
  z-index: 1;
}
.Slider--cube .Slider-item .Slider-item {
  pointer-events: none;
}
.Slider--cube.Slider--rtl .Slider-item {
  transform-origin: 100% 0;
}
.Slider--cube .is-active, .Slider--cube .is-active .is-active {
  pointer-events: auto;
}
.Slider--cube .is-active,
.Slider--cube .is-next,
.Slider--cube .is-prev,
.Slider--cube .is-next + .Slider-item {
  pointer-events: auto;
  visibility: visible;
}
.Slider--cube .swiper-slide-shadow-top,
.Slider--cube .swiper-slide-shadow-bottom,
.Slider--cube .swiper-slide-shadow-left,
.Slider--cube .swiper-slide-shadow-right {
  backface-visibility: hidden;
  z-index: 0;
}
.Slider--cube .swiper-cube-shadow {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: #000000;
  filter: blur(50px);
  opacity: 0.6;
}

/* Slider: flip */
.Slider--flip {
  overflow: visible;
}
.Slider--flip .Slider-item {
  backface-visibility: hidden;
  pointer-events: none;
  z-index: 1;
}
.Slider--flip .Slider-item .Slider-item {
  pointer-events: none;
}
.Slider--flip .is-active, .Slider--flip .is-active .is-active {
  pointer-events: auto;
}
.Slider--flip .Slider-item--shadow-top,
.Slider--flip .Slider-item--shadow-bottom,
.Slider--flip .Slider-item--shadow-left,
.Slider--flip .Slider-item--shadow-right {
  backface-visibility: hidden;
  z-index: 0;
}

/* Slider: lazy */
.Slider-preloader {
  height: 1.4rem;
  width: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
  margin-top: -0.7rem;
  margin-left: -0.7rem;
}
.Slider-preloader:after {
  height: 100%;
  width: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
}

.Slider-preloader--white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
/* Slider: navigation */
.Slider-button {
  height: 1.3333333333rem;
  width: 0.6666666667rem;
  position: absolute;
  top: 50%;
  z-index: 10;
  margin-top: -0.6666666667rem;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 40px;
  transition: background-image 0.3s;
}
.Slider-button.is-disabled {
  cursor: auto;
  opacity: 0.35;
}
.Slider-button.is-locked {
  display: none;
}

.Slider-button--prev,
.Slider-buttonSlider--prev,
.Slider--rtl .Slider-button--next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23c4c4c4'%2F%3E%3C%2Fsvg%3E");
  right: auto;
  left: 20px;
}
.Slider-button--prev:hover,
.Slider-buttonSlider--prev:hover,
.Slider--rtl .Slider-button--next:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.Slider-button--next,
.Slider-buttonSlider--next,
.Slider--rtl .Slider-button--prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23c4c4c4'%2F%3E%3C%2Fsvg%3E");
  right: 20px;
  left: auto;
}
.Slider-button--next:hover,
.Slider-buttonSlider--next:hover,
.Slider--rtl .Slider-button--prev:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

/* Slider: pagination */
.Slider-pagination {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 10;
  text-align: center;
  transform: translate3d(0, 0, 0);
}
.Slider-pagination.is-hidden {
  opacity: 0;
}
.Slider-pagination.is-locked {
  display: none;
}

.Slider-pagination--fraction,
.Slider-pagination--custom,
.Slider--horizontal > .Slider-pagination--bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.Slider-pagination--bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transform: scale(0.33);
  position: relative;
}
.Slider-pagination--bullets-dynamic .is-active {
  transform: scale(1);
}
.Slider-pagination--bullets-dynamic .is-active-main {
  transform: scale(1);
}
.Slider-pagination--bullets-dynamic .is-active-prev {
  transform: scale(0.66);
}
.Slider-pagination--bullets-dynamic .is-active-prev-prev {
  transform: scale(0.33);
}
.Slider-pagination--bullets-dynamic .is-active-next {
  transform: scale(0.66);
}
.Slider-pagination--bullets-dynamic .is-active-next-next {
  transform: scale(0.33);
}

.Slider-pagination-item {
  height: 0.2666666667rem;
  width: 0.2666666667rem;
  background: #000000;
  border-radius: 100%;
  display: inline-block;
  opacity: 0.2;
}
button.Slider-pagination-item {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  appearance: none;
}

.is-clickable .Slider-pagination-item {
  cursor: pointer;
}
.Slider-pagination-item.is-active {
  background-color: currentColor;
  opacity: 1;
}

.Slider--vertical > .Slider-pagination--bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.Slider--vertical > .Slider-pagination--bullets .Slider-pagination-item {
  display: block;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}
.Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
  display: inline-block;
  transition-property: transform, top;
  transition-duration: 200ms;
}

.Slider--horizontal > .Slider-pagination--bullets .Slider-pagination-item {
  margin: 0 4px;
}
.Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transition-property: transform, left;
  transition-duration: 200ms;
}
.Slider--horizontal.Slider--rtl > .Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transition-property: transform, right;
  transition-duration: 200ms;
}

.Slider-pagination--progressbar {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.Slider-pagination--progressbar .Slider-pagination-fill {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: currentColor;
  transform: scale(0);
  transform-origin: left top;
}
.Slider--rtl .Slider-pagination--progressbar .Slider-pagination-fill {
  transform-origin: right top;
}
.Slider--horizontal > .Slider-pagination--progressbar, .Slider--vertical > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
  height: 0.1333333333rem;
  width: 100%;
  left: 0;
  top: 0;
}
.Slider--vertical > .Slider-pagination--progressbar, .Slider--horizontal > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
  height: 100%;
  width: 0.1333333333rem;
  left: 0;
  top: 0;
}

/* Slider: scrollbar */
.Slider-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.3333333333rem;
  position: relative;
  -ms-touch-action: none;
}
.Slider--horizontal > .Slider-scrollbar {
  height: 0.1666666667rem;
  width: 98%;
  position: absolute;
  bottom: 3px;
  left: 1%;
  z-index: 50;
}
.Slider--vertical > .Slider-scrollbar {
  height: 98%;
  width: 0.1666666667rem;
  position: absolute;
  top: 1%;
  right: 3px;
  z-index: 50;
}
.Slider-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.3333333333rem;
}
.Slider-scrollbar-cursor-drag {
  cursor: move;
}
.Slider-scrollbar .is-locked {
  display: none;
}

/* Slider: thumbs */ /* Slider: zoom */
.Slider--zoom {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Slider--zoom > img,
.Slider--zoom > svg,
.Slider--zoom > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.Slider--zoom .is-zoomed {
  cursor: move;
}

/* Gallery */
@font-face {
  font-family: "lg";
  src: url("/assets/fonts/lg.ttf?22t19m") format("truetype"), url("/assets/fonts/lg.woff?22t19m") format("woff"), url("/assets/fonts/lg.svg?22t19m#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: none;
  border: none;
  background-color: transparent;
}
.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #FFF;
}
.lg-actions .lg-next {
  right: 20px;
}
.lg-actions .lg-next:before {
  content: "\e095";
}
.lg-actions .lg-prev {
  left: 20px;
}
.lg-actions .lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  background: none;
  border: none;
  box-shadow: none;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.lg-toolbar .lg-icon:hover {
  color: #FFF;
}
.lg-toolbar .lg-close:after {
  content: "\e070";
}
.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}
.lg-sub-html h4, .lg-sub-html .h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}
.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}
.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}
.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px;
}
@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: rgb(169, 7, 7);
}
.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}
.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}
.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}
.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}
.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
  outline: medium none;
  border: none;
}
.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}
.lg-outer .lg-toggle-thumb:hover {
  color: #FFF;
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}
.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}
.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}
.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("/assets/img/gallery/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("/assets/img/gallery/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("/assets/img/gallery/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}
.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("/assets/img/gallery/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("/assets/img/gallery/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}
.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}
.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}
.lg-progress-bar .lg-progress {
  background-color: rgb(169, 7, 7);
  height: 5px;
  width: 0;
}
.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}
.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}
.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}
.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
}
#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}
#lg-zoom-out:after {
  content: "\e312";
}
.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}
.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}
.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}
.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}
.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}
.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}
.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}
.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}
.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}
.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}
.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}
.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF;
}
.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}
.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px;
}
.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}
.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}
.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}
.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}
.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}
.lg-outer #lg-share {
  position: relative;
}
.lg-outer #lg-share:after {
  content: "\e80d";
}
.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}
.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e904";
}
.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}
.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e907";
}
.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}
.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e905";
}
.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}
.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e906";
}

.lg-outer .lg-img-rotate {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
}

.lg-rotate-left:after {
  content: "\e900";
}

.lg-rotate-right:after {
  content: "\e901";
}

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px;
}

.lg-flip-hor:after {
  content: "\e902";
}

.lg-flip-ver:after {
  content: "\e903";
}

.lg-group {
  *zoom: 1;
}

.lg-group:before, .lg-group:after {
  display: table;
  content: "";
  line-height: 0;
}

.lg-group:after {
  clear: both;
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  outline: none;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}
.lg-outer .lg-item {
  background: url("/assets/img/gallery/loading.gif") no-repeat scroll center center transparent;
  display: none !important;
}
.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}
.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-empty-html {
  display: none;
}
.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

/* DigitalPulse */
.DigitalPulse {
  color: currentColor;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-left: auto;
  height: 1.5em;
}
.DigitalPulse-icon, .DigitalPulse-body {
  display: block;
  fill: currentColor;
}
.DigitalPulse-label {
  margin-right: 0.3em;
  flex-shrink: 0;
}
.DigitalPulse-object {
  background-color: #D6001C;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.DigitalPulse-icon {
  flex-shrink: 0;
  height: 1.8574270557em;
  transform: translateY(5%);
  width: 1.5em;
}
@media screen and (min-width: 64em) {
  .DigitalPulse-object {
    -webkit-transition: transform, right, 300ms, ease-out;
    -o-transition: transform, right, 300ms, ease-out;
    transition: transform, right, 300ms, ease-out;
    min-width: 5.5616710875em;
    position: relative;
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 0;
    transform: scale(1.01) translateY(-50%);
  }
  .DigitalPulse:hover .DigitalPulse-object {
    color: currentColor;
    right: 100%;
  }
  .DigitalPulse-body {
    -webkit-transition: width, 300ms, ease-out;
    -o-transition: width, 300ms, ease-out;
    transition: width, 300ms, ease-out;
    margin-left: 0.3em;
    transform: translateY(12.5%);
    display: flex;
    justify-content: flex-end;
    height: 0.9482758621em;
    width: 5.5616710875em;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: -1;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 8px solid #d6001c;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.htmx-request .htmx-indicator {
  z-index: 10;
}

.htmx-request.htmx-indicator {
  z-index: 10;
}

/* Header */
.Header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
.Header > .Container {
  display: flex;
  height: 100%;
  justify-content: center;
}
.Header--sticky {
  will-change: transform;
}
.Header--sticky.is-pinned {
  transform: translateY(0%);
}
.Header--sticky.is-unpinned {
  transform: translateY(-100%);
}
.Header--sticky.is-notTop {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

/* Logo */
.Logo {
  margin-right: auto;
}
@media screen and (min-width: 48em) {
  .Logo {
    flex-shrink: 0;
  }
}
.Logo svg {
  display: block;
  width: 6rem;
}
@media screen and (min-width: 64em) {
  .Logo svg {
    width: 8.6666666667rem;
  }
}
@media screen and (min-width: 80em) {
  .Logo svg {
    width: 10.3333333333rem;
  }
}
.Logo--footer svg {
  width: 1.5rem;
}

/* General */
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  -webkit-transition: opacity, 300ms;
  -o-transition: opacity, 300ms;
  transition: opacity, 300ms;
  opacity: 1;
}

body:not(.is-tabbing) span:focus,
body:not(.is-tabbing) button:focus,
body:not(.is-tabbing) input:focus,
body:not(.is-tabbing) select:focus,
body:not(.is-tabbing) textarea:focus {
  outline: none;
}

.lg-backdrop {
  background-color: #ffffff;
}

.lg-custom-sub-html {
  width: 100%;
  color: #000000;
  font-weight: 300;
  max-width: 31.3333333333rem;
  white-space: normal;
  padding: 0rem 1.3333333333rem;
  margin-top: 1.3333333333rem;
}
@media screen and (max-width: 37.4375em) {
  .lg-custom-sub-html {
    font-size: 0.5333333333rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .lg-custom-sub-html {
    font-size: calc(0.5333333333rem + 6 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .lg-custom-sub-html {
    font-size: 0.7333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .lg-custom-sub-html {
    line-height: 0.8rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .lg-custom-sub-html {
    line-height: calc(0.8rem + 6 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .lg-custom-sub-html {
    line-height: 1rem;
  }
}

.lg-outer.lg-css3 .lg-item {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.8666666667rem 0rem;
}
.lg-outer.lg-css3 .lg-item .lg-img-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  display: none;
}

.lg-toolbar.lg-group {
  background-color: transparent;
}
.lg-toolbar.lg-group .lg-icon {
  color: #c4c4c4;
}
.lg-toolbar.lg-group .lg-icon:hover {
  color: #898989;
}

body.lg-on {
  overflow-y: hidden;
}

.lg-outer .lg-image {
  max-width: 100%;
}
@media screen and (min-width: 64em) {
  .lg-outer .lg-image {
    max-width: 32rem;
  }
}

.lg-outer.lg-img-wrap {
  margin: 0 auto;
  position: relative;
  max-width: 33.3333333333rem;
}

.Button--secondary {
  background: linear-gradient(to right, #000000 48%, #c4c4c4 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Button--secondary:hover {
  background-position: left bottom;
}

.Modal--project .Grid--withLargeGutter {
  margin-top: -2.6666666667rem;
  margin-left: -2.6666666667rem;
}
.Modal--project .Grid--withLargeGutter > .Grid-cell {
  padding-top: 2.6666666667rem;
  padding-left: 2.6666666667rem;
}
.Modal--project::before {
  background-color: #ffffff;
}
.Modal--project .Modal-close {
  height: 1.3333333333rem;
  width: 1.3333333333rem;
  background-color: #D6001C;
  color: #ffffff;
  top: 0.6666666667rem;
  right: 0.6666666667rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .Modal--project .Modal-body {
    padding-right: 0;
    padding-left: 0;
  }
}
.Modal--project .Modal-innerBody {
  border: 0;
  max-width: none;
}
@media screen and (max-width: 47.9375em) {
  .Modal--project .Modal-innerBody {
    margin: 0;
  }
}
.Modal--project .Modal-inner-body {
  width: 100%;
}

.Modal--splash .Modal-header {
  flex-direction: column;
}
.Modal--splash .Nav--lang {
  margin-bottom: 0.6666666667rem;
  margin-left: auto;
}

.hyphens-auto {
  hyphens: auto;
}

.trustpilot-header {
  font-size: 16px;
  color: #707070;
  text-align: center;
}

.g-recaptcha {
  display: flex;
  justify-content: center;
}

.freeform-form-errors {
  border: 1px solid #e0e0e0;
  border-radius: 11.25px;
  color: #dc3545;
  margin: 1rem 0;
  padding: 1rem;
  text-align: center;
}
.freeform-form-errors p,
.freeform-form-errors ul {
  margin: 0;
}

.Site-rubidor .Footer,
.Site-rubidor .DigitalPulse-object,
.Site-rubidor .CookieConsent-inner,
.Site-rubidor .Nav--main .Nav-link.is-active,
.Site-rubidor .Button--primary,
.Site-rubidor .Block--tiles .Tile-link.Button--primary,
.Site-rubidor .Block--catalogue .CatalogueProduct-label,
.Site-rubidor .Block--overview .Overview-tag a.is-active,
.Site-rubidor .Nav--main .Nav-item.Nav-button .Nav-link:hover,
.Site-rubidor .Block--form .Form-item--action .Button--primary,
.Site-rubidor .Block--textMedia a.TextMedia-link.Button--primary,
.Site-rubidor .Nav--main .Nav-item.Nav-button .Nav-link.is-active,
.Site-rubidor .Block--slider .Slider-button {
  background-color: #006341;
}
.Site-rubidor .Button--primary:hover,
.Site-rubidor .Block--tiles a.Tile-link.Button--primary:hover,
.Site-rubidor .Block--textMedia a.TextMedia-link.Button--primary:hover,
.Site-rubidor .Block--slider .Slider-button:hover {
  background-color: #00452e;
}
.Site-rubidor .Footer a,
.Site-rubidor a.Button--primary,
.Site-rubidor .Block--tiles .Tile-link.Button--primary,
.Site-rubidor .Nav--main .Nav-item.Nav-button .Nav-link,
.Site-rubidor .Block--overview .Overview-tag a.is-active,
.Site-rubidor .Block--form .Form-item--action .Button--primary,
.Site-rubidor .Block--textMedia a.TextMedia-link.Button--primary {
  border-color: #006341;
}
.Site-rubidor a.DigitalPulse,
.Site-rubidor a.Button--secondary,
.Site-rubidor .Footer a:hover,
.Site-rubidor .Block--grid a.Button,
.Site-rubidor a.Button--primary,
.Site-rubidor a.Button--primary:active,
.Site-rubidor a.Button--primary:focus,
.Site-rubidor a.Button--primary:hover,
.Site-rubidor .Block--overview a.OverviewItem-link,
.Site-rubidor .Block--circles a.Circle-imageWrapper,
.Site-rubidor .Block--overview .Overview-tag a.Button,
.Site-rubidor .Block--tiles .Tile-link.Button--primary,
.Site-rubidor .Block--textMedia a.TextMedia-link.Button--primary,
.Site-rubidor .Block--catalogue .CatalogueProduct-datasheet:hover {
  color: #ffffff;
}
.Site-rubidor a,
.Site-rubidor .Block--app .App-title,
.Site-rubidor .Block--usps .Usp-title,
.Site-rubidor .Block--usps .Usps-title,
.Site-rubidor .Block--form .Form-title,
.Site-rubidor .Block--grid .Grid-title,
.Site-rubidor .Block--image .Image-title,
.Site-rubidor .Block--quotes .Quote-text,
.Site-rubidor .Block--quotes .Quote-quote,
.Site-rubidor .Block--grid .Grid-videoTitle,
.Site-rubidor .Block--circles .Circles-title,
.Site-rubidor .Block--timeline .Timeline-title,
.Site-rubidor .Block--overview .Overview-title,
.Site-rubidor .Block--bannerHigh .Banner-title,
.Site-rubidor .Block--bannerLow .Banner-title,
.Site-rubidor .Block--textMedia .TextMedia-title,
.Site-rubidor .Block--catalogue .Catalogue-title,
.Site-rubidor .Block--titleText .TitleText-title,
.Site-rubidor .Block--circles a.Circle-text:hover,
.Site-rubidor .Block--timeline .Timeline-itemYear,
.Site-rubidor .Block--timeline .Timeline-itemTitle,
.Site-rubidor .Block--overview .OverviewItem-title,
.Site-rubidor .Block--overview .Overview-form button,
.Site-rubidor .Block--overview .OverviewItem-title a,
.Site-rubidor .Block--banner .Banner-newsTitle:hover,
.Site-rubidor .Block--banner .Banner-newsLink a:hover,
.Site-rubidor .Footer form.Newsletter .Button--primary,
.Site-rubidor .Nav--main .Nav-item.Nav-button .Nav-link,
.Site-rubidor .Block--timeline .Timeline-line.is-active,
.Site-rubidor .Footer form.Newsletter .Button--primary:hover,
.Site-rubidor .Bravo,
.Site-rubidor h2,
.Site-rubidor .h2,
.Site-rubidor h3,
.Site-rubidor .h3,
.Site-rubidor h4,
.Site-rubidor .h4,
.Site-rubidor .Charlie {
  color: #006341;
}
.Site-rubidor a:hover,
.Site-rubidor a:focus,
.Site-rubidor a:active {
  color: #000000;
}
.Site-rubidor .Nav--main .Nav-item.Nav-button .Nav-link {
  background: linear-gradient(to right, #006341 48%, transparent 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Site-rubidor .Nav--main .Nav-item.Nav-button .Nav-link:hover {
  background-position: left bottom;
}
.Site-rubidor .Block--banner .Banner-link {
  background: linear-gradient(to left, #000000 48%, #006341 50%);
  background-size: 210% 100%;
  background-position: left bottom;
}
.Site-rubidor .Block--banner .Banner-link:hover {
  background-position: right bottom;
}
.Site-rubidor .Button--primary {
  background: linear-gradient(to right, #000000 48%, #006341 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Site-rubidor .Button--primary:hover {
  background-position: left bottom;
}
.Site-rubidor .Block--overview .Overview-tag button.Button {
  background: linear-gradient(to right, #006341 48%, #c4c4c4 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Site-rubidor .Block--overview .Overview-tag button.Button:hover {
  background-position: left bottom;
}
.Site-rubidor .Block--overview .Overview-tag button.Button.is-active {
  background: linear-gradient(to left, #c4c4c4 48%, #006341 50%);
  background-size: 210% 100%;
  background-position: left bottom;
}
.Site-rubidor .Block--overview .Overview-tag button.Button.is-active:hover {
  background-position: right bottom;
}
.Site-rubidor .Block--timeline .Timeline-line.is-active::before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2290.5%22%20viewBox%3D%220%200%2020%2090.5%22%3E%3Cg%20id%3D%22Group_163%22%20data-name%3D%22Group%20163%22%20transform%3D%22translate(-380%20-4857)%22%3E%3Ccircle%20id%3D%22Ellipse_14%22%20data-name%3D%22Ellipse%2014%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%20transform%3D%22translate(380%204857)%22%20fill%3D%22%23006341%22%2F%3E%3Cline%20id%3D%22Line_193%22%20data-name%3D%22Line%20193%22%20y2%3D%2281%22%20transform%3D%22translate(390.5%204866.5)%22%20fill%3D%22none%22%20stroke%3D%22%23006341%22%20stroke-width%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.Site-crix .Footer,
.Site-crix .DigitalPulse-object,
.Site-crix .CookieConsent-inner,
.Site-crix .Nav--main .Nav-link.is-active,
.Site-crix .Button--primary,
.Site-crix .Block--tiles .Tile-link.Button--primary,
.Site-crix .Block--catalogue .CatalogueProduct-label,
.Site-crix .Block--overview .Overview-tag a.is-active,
.Site-crix .Nav--main .Nav-item.Nav-button .Nav-link:hover,
.Site-crix .Block--form .Form-item--action .Button--primary,
.Site-crix .Block--textMedia a.TextMedia-link.Button--primary,
.Site-crix .Nav--main .Nav-item.Nav-button .Nav-link.is-active,
.Site-crix .Block--slider .Slider-button {
  background-color: #CAD208;
}
.Site-crix .Block--image {
  display: flex;
  justify-content: start;
  width: 50%;
}
.Site-crix .TextMedia-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Site-crix .Button--primary {
  color: black !important;
  font-weight: 500;
}
.Site-crix .Block--textMedia .Container {
  max-width: 45.3333333333rem;
}
.Site-crix .Block--textMedia {
  grid-template-columns: 48% 4% 48%;
}
.Site-crix .TextMedia-media {
  justify-content: center;
}
.Site-crix .Usp-grid {
  justify-content: space-around;
}
.Site-crix .Button--primary:hover,
.Site-crix .Block--tiles a.Tile-link.Button--primary:hover,
.Site-crix .Block--textMedia a.TextMedia-link.Button--primary:hover,
.Site-crix .Block--slider .Slider-button:hover {
  background-color: #8d9306;
}
.Site-crix .Footer a,
.Site-crix a.Button--primary,
.Site-crix .Block--tiles .Tile-link.Button--primary,
.Site-crix .Nav--main .Nav-item.Nav-button .Nav-link,
.Site-crix .Block--overview .Overview-tag a.is-active,
.Site-crix .Block--form .Form-item--action .Button--primary,
.Site-crix .Block--textMedia a.TextMedia-link.Button--primary {
  border-color: #CAD208;
}
.Site-crix a.DigitalPulse,
.Site-crix a.Button--secondary,
.Site-crix .Footer a:hover,
.Site-crix .Block--grid a.Button,
.Site-crix a.Button--primary,
.Site-crix a.Button--primary:active,
.Site-crix a.Button--primary:focus,
.Site-crix a.Button--primary:hover,
.Site-crix .Block--overview a.OverviewItem-link,
.Site-crix .Block--circles a.Circle-imageWrapper,
.Site-crix .Block--overview .Overview-tag a.Button,
.Site-crix .Block--tiles .Tile-link.Button--primary,
.Site-crix .Block--textMedia a.TextMedia-link.Button--primary {
  color: #ffffff;
}
.Site-crix .Block--catalogue .CatalogueProduct-datasheet {
  background: linear-gradient(to right, #000000 48%, #CAD208 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  color: #000000;
}
.Site-crix .Block--catalogue .CatalogueProduct-datasheet:hover {
  background-position: left bottom;
  color: #ffffff;
}
.Site-crix a,
.Site-crix .Block--app .App-title,
.Site-crix .Block--usps .Usp-title,
.Site-crix .Block--usps .Usps-title,
.Site-crix .Block--form .Form-title,
.Site-crix .Block--grid .Grid-title,
.Site-crix .Block--image .Image-title,
.Site-crix .Block--quotes .Quote-text,
.Site-crix .Block--quotes .Quote-quote,
.Site-crix .Block--grid .Grid-videoTitle,
.Site-crix .Block--circles .Circles-title,
.Site-crix .Block--timeline .Timeline-title,
.Site-crix .Block--overview .Overview-title,
.Site-crix .Block--bannerHigh .Banner-title,
.Site-crix .Block--bannerLow .Banner-title,
.Site-crix .Block--textMedia .TextMedia-title,
.Site-crix .Block--catalogue .Catalogue-title,
.Site-crix .Block--titleText .TitleText-title,
.Site-crix .Block--circles a.Circle-text:hover,
.Site-crix .Block--timeline .Timeline-itemYear,
.Site-crix .Block--timeline .Timeline-itemTitle,
.Site-crix .Block--overview .OverviewItem-title,
.Site-crix .Block--overview .Overview-form button,
.Site-crix .Block--overview .OverviewItem-title a,
.Site-crix .Block--banner .Banner-newsTitle:hover,
.Site-crix .Block--banner .Banner-newsLink a:hover,
.Site-crix .Footer form.Newsletter .Button--primary,
.Site-crix .Nav--main .Nav-item.Nav-button .Nav-link,
.Site-crix .Block--timeline .Timeline-line.is-active,
.Site-crix .Footer form.Newsletter .Button--primary:hover,
.Site-crix .Bravo,
.Site-crix h2,
.Site-crix .h2,
.Site-crix h3,
.Site-crix .h3,
.Site-crix h4,
.Site-crix .h4,
.Site-crix .Charlie {
  color: #CAD208;
}
.Site-crix a:hover,
.Site-crix a:focus,
.Site-crix a:active {
  color: #000000;
}
.Site-crix .Nav--main .Nav-item.Nav-button .Nav-link {
  background: linear-gradient(to right, #CAD208 48%, transparent 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Site-crix .Nav--main .Nav-item.Nav-button .Nav-link:hover {
  background-position: left bottom;
}
.Site-crix .Block--banner .Banner-link {
  background: linear-gradient(to left, #000000 48%, #CAD208 50%);
  background-size: 210% 100%;
  background-position: left bottom;
}
.Site-crix .Block--banner .Banner-link:hover {
  background-position: right bottom;
}
.Site-crix .Button--primary {
  background: linear-gradient(to right, #000000 48%, #CAD208 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Site-crix .Button--primary:hover {
  background-position: left bottom;
  color: #ffffff !important;
}
.Site-crix .Block--overview .Overview-tag button.Button {
  background: linear-gradient(to right, #CAD208 48%, #c4c4c4 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Site-crix .Block--overview .Overview-tag button.Button:hover {
  background-position: left bottom;
}
.Site-crix .Block--overview .Overview-tag button.Button.is-active {
  background: linear-gradient(to left, #c4c4c4 48%, #CAD208 50%);
  background-size: 210% 100%;
  background-position: left bottom;
}
.Site-crix .Block--overview .Overview-tag button.Button.is-active:hover {
  background-position: right bottom;
}
.Site-crix .Block--timeline .Timeline-line.is-active::before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2290.5%22%20viewBox%3D%220%200%2020%2090.5%22%3E%3Cg%20id%3D%22Group_163%22%20data-name%3D%22Group%20163%22%20transform%3D%22translate(-380%20-4857)%22%3E%3Ccircle%20id%3D%22Ellipse_14%22%20data-name%3D%22Ellipse%2014%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%20transform%3D%22translate(380%204857)%22%20fill%3D%22%23CAD208%22%2F%3E%3Cline%20id%3D%22Line_193%22%20data-name%3D%22Line%20193%22%20y2%3D%2281%22%20transform%3D%22translate(390.5%204866.5)%22%20fill%3D%22none%22%20stroke%3D%22%23CAD208%22%20stroke-width%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
.Site-crix footer .Logo-object path, .Site-crix footer .Logo-object rect {
  fill: #d6001c !important;
}

/* Blocks */
.Block:first-child {
  margin-top: 0;
}
.Block ol {
  padding-left: 0.6666666667rem;
}
.Block ul {
  padding-left: 0;
}
.Block ul li {
  color: #dc3545;
  font-size: 0.75rem;
  list-style-type: none;
  position: relative;
}
.Block ul li::before {
  content: "" !important;
  position: absolute;
  top: 0;
  left: -20px;
  color: #000000;
}

.Block--app {
  margin-top: 4rem;
  background-color: #eeeeee;
}
@media screen and (max-width: 19.9375em) {
  .Block--app {
    padding-bottom: 6.6666666667rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--app {
    margin-top: 8rem;
  }
}
@media screen and (min-width: 64em) {
  .Block--app {
    padding-bottom: 5.3333333333rem;
  }
}
@media screen and (max-width: 19.9375em) {
  .Block--app .App-grid .Grid-cell {
    width: 100% !important;
  }
}
.Block--app .App-title {
  margin: 0 auto;
  max-width: 22.6666666667rem;
  position: relative;
  text-align: center;
}
@media screen and (max-width: 37.4375em) {
  .Block--app .App-title {
    max-width: 12.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--app .App-title {
    max-width: calc(12.6666666667rem + 300 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--app .App-title {
    max-width: 22.6666666667rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--app .App-title {
    top: -0.5333333333rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--app .App-title {
    top: calc(-0.5333333333rem + -18 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--app .App-title {
    top: -1.1333333333rem;
  }
}
.Block--app .App-title + .App-grid {
  margin-top: 1.6666666667rem;
}
@media screen and (min-width: 64em) {
  .Block--app .App-title + .App-grid {
    margin-top: 3.3333333333rem;
  }
}
.Block--app .App-image {
  position: absolute;
  bottom: 0;
}
.Block--app .App-image--desktop {
  left: 50%;
  width: 550px;
  transform: translateX(-50%);
}
.Block--app .App-image--mobile {
  left: 0;
  max-width: 50%;
}
@media screen and (max-width: 19.9375em) {
  .Block--app .App-image--mobile {
    max-width: 150px;
  }
}
@media screen and (max-width: 47.9375em) {
  .Block--app .App-text {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Block--app .App-text {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 25.6rem) / 256));
  }
}
@media screen and (min-width: 64em) {
  .Block--app .App-text {
    font-size: 1rem;
  }
}
.Block--app .App-ctaTextWrapper {
  transition: height 0.3s;
}
@media screen and (max-width: 63.9375em) {
  .Block--app .App-ctaTextWrapper {
    display: flex;
    align-items: center;
    height: 400px;
  }
}
@media screen and (max-width: 26.1875em) {
  .Block--app .App-ctaTextWrapper {
    height: 310px;
  }
}
@media screen and (max-width: 19.9375em) {
  .Block--app .App-ctaTextWrapper {
    height: auto;
    margin: 1.3333333333rem 0rem;
  }
}
.Block--app .App-ctaText {
  font-weight: bold;
  text-decoration: none;
}
@media screen and (max-width: 47.9375em) {
  .Block--app .App-ctaText {
    font-size: 1rem;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Block--app .App-ctaText {
    font-size: calc(1rem + 30 * ((100vw - 25.6rem) / 256));
  }
}
@media screen and (min-width: 64em) {
  .Block--app .App-ctaText {
    font-size: 2rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .Block--app .App-ctaText {
    line-height: 0.8333333333rem;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Block--app .App-ctaText {
    line-height: calc(0.8333333333rem + 35 * ((100vw - 25.6rem) / 256));
  }
}
@media screen and (min-width: 64em) {
  .Block--app .App-ctaText {
    line-height: 2rem;
  }
}
@media screen and (max-width: 63.9375em) {
  .Block--app .App-ctaText {
    color: #D6001C;
  }
  .Block--app .App-ctaText:hover {
    color: #D6001C;
  }
}

.Block--banner {
  position: relative;
  margin: 2rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--banner {
    margin: 4rem 0rem;
  }
}
.Block--banner .Banner-object img,
.Block--banner .Banner-object video {
  width: 100%;
  height: 100%;
  min-height: 400px;
  object-fit: cover;
  transition: height 0.3s, min-height 0.3s;
}
@media screen and (min-width: 37.5em) {
  .Block--banner .Banner-object img,
  .Block--banner .Banner-object video {
    height: 500px;
  }
}
@media screen and (min-width: 64em) {
  .Block--banner .Banner-object img,
  .Block--banner .Banner-object video {
    height: 600px;
  }
}
@media screen and (min-width: 64em) {
  .Block--banner .Banner-object img,
  .Block--banner .Banner-object video {
    height: 700px;
  }
}
.Block--banner .Banner-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.2666666667rem;
  right: 1.2666666667rem;
  margin: 0;
  z-index: 2;
  color: #ffffff;
  line-height: 100px;
  text-transform: uppercase;
}
@media screen and (max-width: 37.4375em) {
  .Block--banner .Banner-title {
    font-size: 1.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--banner .Banner-title {
    font-size: calc(1.6666666667rem + 70 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--banner .Banner-title {
    font-size: 4rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--banner .Banner-title {
    line-height: 1.5rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--banner .Banner-title {
    line-height: calc(1.5rem + 55 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--banner .Banner-title {
    line-height: 3.3333333333rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--banner .Banner-title {
    top: auto;
    right: 1.3333333333rem;
    bottom: 1.3333333333rem;
    left: 1.3333333333rem;
    transform: none;
  }
}
@media screen and (min-width: 85em) {
  .Block--banner .Banner-title {
    left: calc((100vw - 1280px) / 2);
  }
}
@media screen and (max-width: 47.9375em) {
  .Block--banner .Banner-title.has-news {
    top: 60%;
  }
}
.Block--banner .Banner-title a {
  color: #ffffff;
  text-decoration: none;
}
.Block--banner .Banner-link {
  color: #ffffff;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
}
.Block--banner .Banner-link .Icon {
  width: 8px;
}
.Block--banner .Banner-link .Icon svg path {
  transition: stroke 0.3s;
}
.Block--banner .Banner-link:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.5);
}
.Block--banner .Banner-link:hover .Icon svg path {
  stroke: #ffffff;
}
.Block--banner .Banner-news {
  display: flex;
  align-items: center;
  z-index: 4;
  max-width: 30rem;
  transition: position 0.3s;
  background-color: #c4c4c4;
  padding: 0.6666666667rem 1.2666666667rem;
}
@media screen and (min-width: 48em) {
  .Block--banner .Banner-news {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.6666666667rem 1.3333333333rem;
  }
}
.Block--banner .Banner-newsInfo {
  flex-grow: 1;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 37.4375em) {
  .Block--banner .Banner-newsInfo {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--banner .Banner-newsInfo {
    font-size: calc(0.6666666667rem + 20 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--banner .Banner-newsInfo {
    font-size: 1.3333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--banner .Banner-newsInfo {
    line-height: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--banner .Banner-newsInfo {
    line-height: calc(0.6666666667rem + 20 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--banner .Banner-newsInfo {
    line-height: 1.3333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--banner .Banner-newsInfo {
    flex-direction: column;
  }
}
.Block--banner .Banner-newsTitle {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}
.Block--banner .Banner-newsTitle:hover {
  color: #D6001C;
}
@media screen and (min-width: 37.5em) {
  .Block--banner .Banner-newsTitle {
    margin-right: 0.6666666667rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--banner .Banner-newsTitle {
    margin-right: 1.3333333333rem;
  }
}
.Block--banner .Banner-newsDate {
  font-size: 75%;
}
@media screen and (max-width: 37.4375em) {
  .Block--banner .Banner-newsDate {
    margin-top: 0.3333333333rem;
  }
}
.Block--banner .Banner-newsLink {
  flex-shrink: 0;
  margin-left: 1.3333333333rem;
}
@media screen and (min-width: 48em) {
  .Block--banner .Banner-newsLink {
    margin-left: 2rem;
  }
}
.Block--banner .Banner-newsLink a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
}
@media screen and (max-width: 37.4375em) {
  .Block--banner .Banner-newsLink a {
    font-size: 0.6rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--banner .Banner-newsLink a {
    font-size: calc(0.6rem + 12 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--banner .Banner-newsLink a {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--banner .Banner-newsLink a {
    line-height: 0.6rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--banner .Banner-newsLink a {
    line-height: calc(0.6rem + 12 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--banner .Banner-newsLink a {
    line-height: 1rem;
  }
}
.Block--banner .Banner-newsLink a:hover {
  color: #D6001C;
}

.Block--bannerHome {
  z-index: 3;
}

.Block--bannerHigh .Banner-image, .Block--bannerHigh .Banner-video {
  height: auto !important;
}
.Block--bannerHigh .Banner-title {
  color: #D6001C;
}
@media screen and (min-width: 48em) {
  .Block--bannerHigh .Banner-title {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

.Block--bannerLow .Banner-image, .Block--bannerLow .Banner-video {
  min-height: 200px !important;
}
@media screen and (min-width: 48em) {
  .Block--bannerLow .Banner-image, .Block--bannerLow .Banner-video {
    height: auto !important;
    min-height: unset !important;
  }
}
.Block--bannerLow .Banner-title {
  color: #D6001C;
}
@media screen and (min-width: 48em) {
  .Block--bannerLow .Banner-title {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

.Block--catalogue {
  padding: 1.6666666667rem 0rem;
  margin: 2rem 0rem;
  background-color: #eeeeee;
}
@media screen and (min-width: 48em) {
  .Block--catalogue {
    padding: 4rem 0rem;
    margin: 4rem 0rem;
  }
}
.Block--catalogue .Catalogue-group:not(:first-child) {
  margin-top: 3.3333333333rem;
}
.Block--catalogue .Catalogue-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.6666666667rem;
}
.Block--catalogue .Catalogue-list > .Catalogue-product {
  margin-top: 1rem;
  margin-left: 1.6666666667rem;
}
@media screen and (min-width: 37.5em) {
  .Block--catalogue .Catalogue-list > .Catalogue-product {
    margin-top: 1.6666666667rem;
  }
}
.Block--catalogue .Catalogue-product {
  flex-basis: auto;
  width: calc(100% - 50px);
}
@media screen and (min-width: 37.5em) {
  .Block--catalogue .Catalogue-product {
    width: calc(50% - 50px);
  }
}
@media screen and (min-width: 64em) {
  .Block--catalogue .Catalogue-product {
    width: calc(33.3333333333% - 50px);
  }
}
.Block--catalogue .Catalogue-subgroup {
  flex-basis: auto;
  margin-top: 1rem;
  margin-left: 1.6666666667rem;
  width: calc(100% - 50px);
}
@media screen and (min-width: 37.5em) {
  .Block--catalogue .Catalogue-subgroup {
    margin-top: 1.6666666667rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--catalogue .Catalogue-subgroup {
    margin-top: 3.3333333333rem;
  }
  .Block--catalogue .Catalogue-subgroup:first-child {
    margin-top: 2.3333333333rem;
  }
}
.Block--catalogue .Catalogue-subtitle {
  color: #c4c4c4;
  text-transform: uppercase;
}
@media screen and (max-width: 37.4375em) {
  .Block--catalogue .Catalogue-subtitle {
    font-size: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--catalogue .Catalogue-subtitle {
    font-size: calc(1rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--catalogue .Catalogue-subtitle {
    font-size: 1.3333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--catalogue .Catalogue-subtitle {
    line-height: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--catalogue .Catalogue-subtitle {
    line-height: calc(0.6666666667rem + 20 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--catalogue .Catalogue-subtitle {
    line-height: 1.3333333333rem;
  }
}
.Block--catalogue .CatalogueProduct-imageWrapper {
  position: relative;
}
.Block--catalogue .CatalogueProduct-object img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.Block--catalogue .CatalogueProduct-object img::before {
  background-color: white;
}
.Block--catalogue .CatalogueProduct-meta {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
}
.Block--catalogue .CatalogueProduct-video, .Block--catalogue .CatalogueProduct-datasheet {
  background: linear-gradient(to right, #898989 48%, #c4c4c4 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  color: #ffffff;
  padding: 0.1666666667rem 0.5rem;
  transition: all 0.3s;
  margin-left: 0.1666666667rem;
}
.Block--catalogue .CatalogueProduct-video:hover, .Block--catalogue .CatalogueProduct-datasheet:hover {
  background-position: left bottom;
}
.Block--catalogue .CatalogueProduct-video {
  display: flex;
  align-items: center;
}
.Block--catalogue .CatalogueProduct-icon {
  display: block;
  fill: currentColor;
  width: 24px;
  height: 24px;
}
.Block--catalogue .CatalogueProduct-datasheet {
  font-size: 0.7333333333rem;
  text-decoration: none;
  text-transform: uppercase;
}
.Block--catalogue .CatalogueProduct-label {
  font-size: 0.7333333333rem;
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
  padding: 0.1666666667rem 0.5rem;
  position: absolute;
  text-transform: uppercase;
  background-color: #D6001C;
}
.Block--catalogue .CatalogueProduct-title {
  color: #000000;
  font-weight: 600;
  margin: 0.6666666667rem 0rem 0.3333333333rem 0rem;
}
@media screen and (max-width: 37.4375em) {
  .Block--catalogue .CatalogueProduct-title {
    font-size: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--catalogue .CatalogueProduct-title {
    font-size: calc(1rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--catalogue .CatalogueProduct-title {
    font-size: 1.3333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--catalogue .CatalogueProduct-title {
    line-height: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--catalogue .CatalogueProduct-title {
    line-height: calc(1rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--catalogue .CatalogueProduct-title {
    line-height: 1.3333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--catalogue .CatalogueProduct-text {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--catalogue .CatalogueProduct-text {
    font-size: calc(0.6666666667rem + 2 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--catalogue .CatalogueProduct-text {
    font-size: 0.7333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--catalogue .CatalogueProduct-text {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--catalogue .CatalogueProduct-text {
    line-height: calc(0.8666666667rem + 2 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--catalogue .CatalogueProduct-text {
    line-height: 0.9333333333rem;
  }
}

.Block--circles {
  margin: 2rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--circles {
    margin: 4rem 0rem;
  }
}
.Block--circles .Circles-title {
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 37.4375em) {
  .Block--circles .Circles-title {
    max-width: 14.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--circles .Circles-title {
    max-width: calc(14.6666666667rem + 260 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--circles .Circles-title {
    max-width: 23.3333333333rem;
  }
}
.Block--circles .Circles-title + .Circles-grid {
  margin-top: 1.3333333333rem;
}
@media screen and (min-width: 48em) {
  .Block--circles .Circles-title + .Circles-grid {
    margin-top: 2.6666666667rem;
  }
}
.Block--circles .Circles-grid {
  margin-left: -1.3333333333rem;
}
@media screen and (min-width: 48em) {
  .Block--circles .Circles-grid {
    margin-left: -2.6666666667rem;
  }
}
.Block--circles .Circles-grid .Grid-cell {
  padding-left: 1.3333333333rem;
}
@media screen and (max-width: 24.9375em) {
  .Block--circles .Circles-grid .Grid-cell {
    width: 100% !important;
  }
  .Block--circles .Circles-grid .Grid-cell + .Grid-cell {
    margin-top: 1.3333333333rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--circles .Circles-grid .Grid-cell {
    padding-left: 2.6666666667rem;
  }
}
.Block--circles .Circle-imageWrapper {
  color: #ffffff;
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  text-decoration: none;
}
.Block--circles .Circle-imageWrapper:hover .Circle-object {
  transform: scale(1.05);
}
@media screen and (max-width: 63.9375em) {
  .Block--circles .Circle-imageWrapper {
    margin: 0 auto;
    max-width: 450px;
    max-height: 450px;
  }
}
.Block--circles .Circle-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  padding: 0rem 1rem;
}
@media screen and (max-width: 37.4375em) {
  .Block--circles .Circle-title {
    font-size: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--circles .Circle-title {
    font-size: calc(1rem + 45 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--circles .Circle-title {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--circles .Circle-title {
    line-height: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--circles .Circle-title {
    line-height: calc(1rem + 40 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--circles .Circle-title {
    line-height: 2.3333333333rem;
  }
}
.Block--circles .Circle-object {
  transition: transform 0.3s;
}
.Block--circles .Circle-object img {
  width: 100%;
  height: 100%;
}
.Block--circles .Circle-text {
  color: #000000;
  display: block;
  text-align: center;
  text-decoration: none;
  margin-top: 1.3333333333rem;
}
@media screen and (max-width: 47.9375em) {
  .Block--circles .Circle-text {
    display: none;
  }
}
.Block--circles a.Circle-text:hover {
  color: #D6001C;
}

.Block--cta {
  background-color: #eeeeee;
  margin: 4rem 0rem 2rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--cta {
    margin: 5rem 0rem;
  }
}
.Block--cta .Cta {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 48em) {
  .Block--cta .Cta {
    flex-direction: row;
  }
}
.Block--cta .Cta-icon, .Block--cta .Cta-text {
  width: 100%;
  flex-basis: auto;
}
@media screen and (min-width: 48em) {
  .Block--cta .Cta-icon, .Block--cta .Cta-text {
    width: 50%;
  }
}
.Block--cta .Cta-icon {
  position: relative;
}
.Block--cta .Cta-icon img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 37.4375em) {
  .Block--cta .Cta-icon img {
    top: 0rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--cta .Cta-icon img {
    top: calc(0rem + -50 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--cta .Cta-icon img {
    top: -1.6666666667rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .Block--cta .Cta-icon img {
    max-width: 300px;
  }
}
@media screen and (min-width: 48em) {
  .Block--cta .Cta-icon img {
    left: 0;
    transform: translateY(-50%);
  }
}
.Block--cta .Cta-text {
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
@media screen and (max-width: 37.4375em) {
  .Block--cta .Cta-text {
    font-size: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--cta .Cta-text {
    font-size: calc(1rem + 30 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--cta .Cta-text {
    font-size: 2rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--cta .Cta-text {
    line-height: 1.1666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--cta .Cta-text {
    line-height: calc(1.1666666667rem + 25 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--cta .Cta-text {
    line-height: 2rem;
  }
}

.CtaNew-text {
  color: #D6001C;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transform: translateY(-1rem);
}
@media screen and (max-width: 37.4375em) {
  .CtaNew-text {
    font-size: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .CtaNew-text {
    font-size: calc(1rem + 30 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .CtaNew-text {
    font-size: 2rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .CtaNew-text {
    line-height: 1.1666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .CtaNew-text {
    line-height: calc(1.1666666667rem + 25 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .CtaNew-text {
    line-height: 2rem;
  }
}
@media screen and (min-width: 48em) {
  .CtaNew-text {
    transform: translateY(-2rem);
  }
}
@media screen and (min-width: 48em) {
  .CtaNew-right {
    border-left: 1px solid #000000;
  }
}
.CtaNew-link {
  align-items: center;
}
.CtaNew-linkText {
  text-transform: uppercase;
  color: #000000;
}

.Block--exploded {
  margin: 2.6666666667rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--exploded {
    margin: 4rem 0rem;
  }
}
.Block--exploded .Exploded {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Block--exploded .Exploded-row {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.Block--exploded .Exploded-row + .Exploded-row {
  margin-top: 0.3333333333rem;
}
.Block--exploded .Exploded-row:hover .Exploded-label {
  opacity: 1;
  visibility: visible;
}
.Block--exploded .Exploded-row:hover .Exploded-imageWrapper::before {
  background-color: rgba(255, 255, 255, 0);
}
.Block--exploded .Exploded-imageWrapper {
  position: relative;
}
.Block--exploded .Exploded-imageWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  transition: background-color 0.3s;
}
@media screen and (min-width: 48em) {
  .Block--exploded .Exploded-imageWrapper::before {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
.Block--exploded .Exploded-label {
  position: absolute;
  top: 50%;
  left: 100%;
  z-index: 3;
  font-size: 0.7333333333rem;
  display: flex;
  align-items: center;
  width: calc((100vw - 600px) / 2 - 40px);
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%) translateX(-1.3333333333rem) translateY(-50%);
  transition: opacity 0.3s, visibility 0.3s;
}
@media screen and (min-width: 64em) {
  .Block--exploded .Exploded-label {
    transform: translateX(-2rem) translateY(-50%);
  }
}
.Block--exploded .Exploded-label.is-wide {
  min-width: 100px;
  padding-left: 1.3333333333rem;
}
.Block--exploded .Exploded-label span {
  z-index: 3;
  position: relative;
  background-color: #ffffff;
  padding: 0.1666666667rem 0.1666666667rem 0.1666666667rem 0.6666666667rem;
}
.Block--exploded .Exploded-label::before {
  height: 0.0333333333rem;
  width: 1.3333333333rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2010%201%22%3E%3Cline%20x2%3D%2210%22%20transform%3D%22translate(0%200.5)%22%20fill%3D%22none%22%20stroke%3D%22%23707070%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E");
  content: "";
  flex-shrink: 0;
  background-repeat: repeat-x;
}

.Block--form {
  margin: 2.6666666667rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--form {
    margin: 4rem 0rem;
  }
}
.Block--form .Form-title {
  text-align: center;
}
.Block--form .Form-title + .Form, .Block--form .Form-title + .Form-intro {
  margin-top: 1.6666666667rem;
}
@media screen and (min-width: 37.5em) {
  .Block--form .Form-title + .Form, .Block--form .Form-title + .Form-intro {
    margin-top: 2.3333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--form .Form-intro {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--form .Form-intro {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--form .Form-intro {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--form .Form-intro {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--form .Form-intro {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--form .Form-intro {
    line-height: 1.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) {
  .Block--form .Form-intro {
    text-align: center;
  }
}
.Block--form .Form-intro + .Form {
  margin-top: 1.6666666667rem;
}
@media screen and (min-width: 37.5em) {
  .Block--form .Form-intro + .Form {
    margin-top: 2.3333333333rem;
  }
}

.Block--grid {
  margin: 2rem 0rem !important;
}
@media screen and (min-width: 48em) {
  .Block--grid {
    margin: 2.6666666667rem 0rem !important;
  }
}
.Block--grid + .Block--app {
  margin-top: 5.3333333333rem;
}
@media screen and (min-width: 48em) {
  .Block--grid .Grid-grid {
    display: grid;
    grid-template-columns: calc(50% - 20px) 40px calc(50% - 20px);
    grid-template-rows: repeat(7, auto);
  }
}
@media screen and (min-width: 64em) {
  .Block--grid .Grid-grid {
    display: grid;
    grid-template-columns: calc(50% - 50px) 100px calc(50% - 50px);
    grid-template-rows: repeat(7, auto);
  }
}
.Block--grid .Grid-title {
  grid-column: 1/2;
  grid-row: 1/2;
}
.Block--grid .Grid-intro {
  grid-column: 1/2;
  grid-row: 2/3;
  margin-top: 1.6666666667rem;
}
@media screen and (max-width: 37.4375em) {
  .Block--grid .Grid-intro {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--grid .Grid-intro {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--grid .Grid-intro {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--grid .Grid-intro {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--grid .Grid-intro {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--grid .Grid-intro {
    line-height: 1.3333333333rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--grid .Grid-intro {
    margin: 2.3333333333rem 0rem;
  }
}
.Block--grid .Grid-video {
  grid-column: 1/2;
  grid-row: 4/7;
  position: relative;
  align-self: flex-end;
}
@media screen and (max-width: 47.9375em) {
  .Block--grid .Grid-video {
    margin: 1.6666666667rem 0rem;
  }
}
.Block--grid .Grid-videoOverlay::before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2269%22%20height%3D%2269%22%20viewBox%3D%220%200%2069%2069%22%3E%3Cg%20id%3D%22Group_280%22%20data-name%3D%22Group%20280%22%20transform%3D%22translate(-535%20-1218)%22%3E%3Ccircle%20id%3D%22Ellipse_38%22%20data-name%3D%22Ellipse%2038%22%20cx%3D%2234.5%22%20cy%3D%2234.5%22%20r%3D%2234.5%22%20transform%3D%22translate(535%201218)%22%20fill%3D%22%23fff%22%20opacity%3D%220.25%22%2F%3E%3Cpath%20id%3D%22Polygon_3%22%20data-name%3D%22Polygon%203%22%20d%3D%22M13.889%2C0%2C27.778%2C25.8H0Z%22%20transform%3D%22translate(585.8%201239)%20rotate(90)%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.2);
}
.Block--grid .Grid-videoOverlayImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Block--grid .Grid-imageWrapper {
  grid-column: 3/4;
  grid-row: 2/5;
  position: relative;
  margin-top: 40px;
}
@media screen and (min-width: 48em) {
  .Block--grid .Grid-imageWrapper {
    margin-top: 0;
  }
}
@media screen and (min-width: 48em) {
  .Block--grid .Grid-imageWrapper .Grid-object {
    top: 0;
    right: -1.3333333333rem;
    bottom: 0;
    left: 0;
    margin-bottom: 40px;
    z-index: 3;
  }
}
@media screen and (min-width: 85em) {
  .Block--grid .Grid-imageWrapper .Grid-object {
    right: calc((100vw - 1280px) / -2);
  }
}
.Block--grid .Grid-imageWrapper .Grid-object img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Block--grid .Grid-videoInfo {
  grid-column: 3/4;
  grid-row: 6/7;
}
.Block--grid .Grid-list {
  grid-column: 1/2;
  grid-row: 7/8;
  margin-top: 1.6666666667rem;
}
@media screen and (max-width: 37.4375em) {
  .Block--grid .Grid-list {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--grid .Grid-list {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--grid .Grid-list {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--grid .Grid-list {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--grid .Grid-list {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--grid .Grid-list {
    line-height: 1.3333333333rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--grid .Grid-list {
    margin-top: 2.3333333333rem;
  }
}
.Block--grid .Video-grid {
  margin-top: 1.6666666667rem;
}
.Block--grid .Video-grid .Grid-intro {
  margin-top: 0rem;
}
@media screen and (min-width: 48em) {
  .Block--grid .Video-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
  .Block--grid .Video-grid .Video-grid__content {
    order: 2;
  }
  .Block--grid .Video-grid .Video-grid__video {
    order: 1;
  }
}

.Block--image {
  position: relative;
  margin: 2rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--image {
    margin: 4rem 0rem;
  }
}
.Block--image .Image-title {
  margin: 0rem auto;
  text-align: center;
}
@media screen and (max-width: 37.4375em) {
  .Block--image .Image-title {
    max-width: 12rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--image .Image-title {
    max-width: calc(12rem + 240 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--image .Image-title {
    max-width: 20rem;
  }
}
.Block--image .Image-title + .Image-imageWrapper {
  margin-top: 1.3333333333rem;
}
@media screen and (min-width: 48em) {
  .Block--image .Image-title + .Image-imageWrapper {
    margin-top: 2.3333333333rem;
  }
}
.Block--image .Image-image {
  width: 100%;
  height: 100%;
}

.Block--map {
  height: 550px;
  position: relative;
}
.Block--map .Map-address {
  font-size: 0.7333333333rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  width: auto;
  height: auto;
  padding: 1.3333333333rem;
  background-color: #ffffff;
}
@media screen and (min-width: 25em) {
  .Block--map .Map-address {
    right: 0;
    bottom: 50px;
    left: auto;
    min-width: 11rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--map .Map-address {
    top: 50%;
    right: auto;
    bottom: auto;
    left: calc(50% + 50px);
    transform: translateY(-50%);
  }
}
.Block--map .Map-address a {
  color: #000000;
  text-decoration: none;
}
.Block--map .Map-address a:hover {
  text-decoration: underline;
}
.Block--map .Map-object {
  height: 100%;
}

.Block--overview {
  padding: 2rem 0rem;
  background-color: #eeeeee;
  margin: 2rem 0rem !important;
}
@media screen and (min-width: 37.5em) {
  .Block--overview {
    padding: 0rem 0rem 5.3333333333rem 0rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--overview {
    margin: 4rem 0rem !important;
  }
}
.Block--overview .Overview-title {
  max-width: 28.6666666667rem;
  position: relative;
  margin: 1.3333333333rem auto;
}
@media screen and (max-width: 37.4375em) {
  .Block--overview .Overview-title {
    margin-top: 0;
  }
}
@media screen and (min-width: 37.5em) {
  .Block--overview .Overview-title {
    margin-top: 0;
    text-align: center;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 37.4375em) {
  .Block--overview .Overview-title {
    top: -0.5666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--overview .Overview-title {
    top: calc(-0.5666666667rem + -16 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 37.5em) and (min-width: 64em) {
  .Block--overview .Overview-title {
    top: -1.1rem;
  }
}
.Block--overview .Overview-intro {
  margin: 0 auto;
  max-width: 22.6666666667rem;
}
@media screen and (max-width: 37.4375em) {
  .Block--overview .Overview-intro {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--overview .Overview-intro {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--overview .Overview-intro {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--overview .Overview-intro {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--overview .Overview-intro {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--overview .Overview-intro {
    line-height: 1.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) {
  .Block--overview .Overview-intro {
    text-align: center;
  }
}
.Block--overview .Overview-tagsWrapper {
  max-width: 28.6666666667rem;
  margin: 0rem auto;
}
.Block--overview .Overview-form {
  margin: 1.8666666667rem 0rem;
}
@media screen and (min-width: 37.5em) {
  .Block--overview .Overview-form .Form-item {
    margin: 0 auto;
    max-width: 16.6666666667rem;
  }
}
.Block--overview .Overview-form .Form-controls {
  display: flex;
}
@media screen and (min-width: 37.5em) {
  .Block--overview .Overview-form .Form-controls {
    justify-content: center;
  }
}
.Block--overview .Overview-form .Form-field {
  font-size: 1.0666666667rem;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
}
.Block--overview .Overview-form .Form-clear {
  text-align: right;
}
.Block--overview .Overview-form .Form-clear a {
  font-size: 0.5333333333rem;
  font-style: italic;
  text-decoration: none;
  color: #898989;
}
.Block--overview .Overview-form .Form-clear a:hover {
  color: #707070;
}
.Block--overview .Overview-form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  color: #D6001C;
  padding: 0rem 0.6666666667rem;
  text-decoration: none;
}
.Block--overview .Overview-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0.6666666667rem auto 0rem -0.3333333333rem;
}
@media screen and (min-width: 37.5em) {
  .Block--overview .Overview-tags {
    justify-content: center;
  }
}
.Block--overview .Overview-tag {
  display: flex;
  align-items: center;
  margin-top: 0.3333333333rem;
  margin-left: 0.3333333333rem;
}
.Block--overview .Overview-tag button {
  background: linear-gradient(to right, #D6001C 48%, #c4c4c4 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Block--overview .Overview-tag button:hover {
  background-position: left bottom;
}
.Block--overview .Overview-tag button.is-active {
  background: linear-gradient(to left, #c4c4c4 48%, #D6001C 50%);
  background-size: 210% 100%;
  background-position: left bottom;
}
.Block--overview .Overview-tag button.is-active:hover {
  background-position: right bottom;
}
.Block--overview .Overview-tag svg {
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 0.1666666667rem;
}
.Block--overview .Overview-noResults {
  font-size: 0.6666666667rem;
}
.Block--overview .Overview-grid {
  margin-top: 1.3333333333rem;
  margin-left: -1.6rem;
}
.Block--overview .Overview-grid .Grid-cell {
  padding-top: 1.6rem;
  padding-left: 1.6rem;
}
.Block--overview .OverviewItem {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 13.3333333333rem;
}
.Block--overview .OverviewItem-imageWrapper {
  display: block;
  overflow: hidden;
}
.Block--overview .OverviewItem-imageWrapper:hover .OverviewItem-object {
  transform: scale(1.05);
}
.Block--overview .OverviewItem-object {
  transition: transform 0.3s;
}
.Block--overview .OverviewItem-info {
  font-size: 0.7333333333rem;
  flex-grow: 1;
  position: relative;
  background-color: #ffffff;
  padding: 1.3333333333rem 1.3333333333rem 2rem;
}
.Block--overview .OverviewItem-title {
  font-size: 1.1rem;
  line-height: 1.1;
}
.Block--overview .OverviewItem-title a {
  color: #D6001C;
  text-decoration: none;
}
.Block--overview .OverviewItem-title a:hover {
  color: #000000;
}
.Block--overview .OverviewItem-intro {
  margin-top: 1rem;
}
.Block--overview .OverviewItem-link {
  position: absolute;
  right: 0;
  bottom: 0;
}
.Block--overview .OverviewItem-footer {
  margin-top: 2rem;
}
.Block--overview .OverviewItem-footer svg {
  width: 100%;
  height: 100%;
  color: #c4c4c4;
  max-width: 35px;
  max-height: 28px;
  transition: color 0.3s;
}
.Block--overview .OverviewItem-footer a:hover svg {
  color: #898989;
}
.Block--overview .OverviewItem-footer a + a {
  margin-left: 0.3333333333rem;
}
.Block--overview.Block--overviewTipsTricks .OverviewItem-info {
  padding-bottom: 0.6666666667rem;
}

.Video-modal .Modal-inner-body {
  border: none;
  position: relative;
}
.Video-modal .Modal-body {
  padding: 0;
}

.Block--quotes {
  position: relative;
  margin: 2rem 0rem;
  padding: 2rem 0rem;
  background-color: #eeeeee;
}
@media screen and (min-width: 48em) {
  .Block--quotes {
    margin: 4rem 0rem;
    padding: 3.2rem 0rem;
  }
}
.Block--quotes .Quote {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.Block--quotes .Quote-quote {
  color: #D6001C;
  margin: 0rem 1.3333333333rem;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 37.4375em) {
  .Block--quotes .Quote-quote {
    font-size: 1.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--quotes .Quote-quote {
    font-size: calc(1.3333333333rem + 35 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--quotes .Quote-quote {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--quotes .Quote-quote {
    line-height: 1.1666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--quotes .Quote-quote {
    line-height: calc(1.1666666667rem + 35 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--quotes .Quote-quote {
    line-height: 2.3333333333rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--quotes .Quote-quote {
    margin: 0rem 3.3333333333rem;
  }
}
@media screen and (min-width: 80em) {
  .Block--quotes .Quote-quote {
    margin: 0 auto;
    max-width: 32rem;
  }
}
.Block--quotes .Quote-quote + .Quote-text {
  margin-top: 0.8333333333rem;
}
@media screen and (min-width: 48em) {
  .Block--quotes .Quote-quote + .Quote-text {
    margin-top: 1.6666666667rem;
  }
}
.Block--quotes .Quote-text {
  color: #D6001C;
  text-align: center;
}
@media screen and (max-width: 37.4375em) {
  .Block--quotes .Quote-text {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--quotes .Quote-text {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--quotes .Quote-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--quotes .Quote-text {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--quotes .Quote-text {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--quotes .Quote-text {
    line-height: 1.3333333333rem;
  }
}
.Block--quotes .Slider-buttonQuotes--prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23c4c4c4'%2F%3E%3C%2Fsvg%3E");
  left: 20px;
}
@media screen and (min-width: 37.5em) {
  .Block--quotes .Slider-buttonQuotes--prev {
    left: 35px;
  }
}
@media screen and (min-width: 48em) {
  .Block--quotes .Slider-buttonQuotes--prev {
    left: 70px;
  }
}
.Block--quotes .Slider-buttonQuotes--prev:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.Block--quotes .Slider-buttonQuotes--next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23c4c4c4'%2F%3E%3C%2Fsvg%3E");
  right: 20px;
}
@media screen and (min-width: 37.5em) {
  .Block--quotes .Slider-buttonQuotes--next {
    right: 35px;
  }
}
@media screen and (min-width: 48em) {
  .Block--quotes .Slider-buttonQuotes--next {
    right: 70px;
  }
}
.Block--quotes .Slider-buttonQuotes--next:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.Block--row {
  margin: 1rem 0rem !important;
}
.Block--row p {
  text-align: justify;
}
@media screen and (min-width: 48em) {
  .Block--row {
    margin: 2rem 0rem !important;
  }
}
.Block--row .Row-title {
  margin-bottom: 0.9333333333rem;
}
@media screen and (max-width: 63.9375em) {
  .Block--row .Row-content {
    margin-top: 1.3333333333rem;
  }
}
.Block--row .Row-text {
  font-size: 0.7333333333rem;
  font-weight: 300;
  line-height: 30px;
}
.Block--row .Row-media .Media--video {
  width: 100%;
}
.Block--row .Row-link {
  margin-top: 0.6666666667rem;
}

.Block--slider {
  position: relative;
  margin: 2.6666666667rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--slider {
    margin: 4rem 0rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--slider .Slider {
    margin-left: 1.3333333333rem;
    margin-right: 1.3333333333rem;
  }
}
@media screen and (min-width: 85em) {
  .Block--slider .Slider {
    margin-left: auto;
    margin-right: auto;
    max-width: 42.6666666667rem;
  }
}
.Block--slider .Slider-navigation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Block--slider .Slider-button {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  background-size: 20px 20px;
  background-position: center;
  border-radius: 50%;
}
@media screen and (min-width: 48em) {
  .Block--slider .Slider-button {
    width: 48px;
    height: 48px;
    background-color: #D6001C;
    transition: background-color 0.3s ease;
  }
  .Block--slider .Slider-button:hover {
    background-color: #111111;
  }
  .Block--slider .Slider-button.is-disabled {
    background-color: #111111;
  }
}
.Block--slider .Slider-buttonSlider--next {
  right: 10px;
  left: auto;
}
@media screen and (min-width: 48em) {
  .Block--slider .Slider-buttonSlider--next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  }
}
@media screen and (min-width: 91.25em) {
  .Block--slider .Slider-buttonSlider--next {
    right: auto;
    left: calc(1280px + ((100vw - 1280px) / 2) + 8px);
  }
}
.Block--slider .Slider-buttonSlider--prev {
  right: auto;
  left: 10px;
}
@media screen and (min-width: 48em) {
  .Block--slider .Slider-buttonSlider--prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  }
}
@media screen and (min-width: 91.25em) {
  .Block--slider .Slider-buttonSlider--prev {
    left: calc((100vw - 1280px) / 2 - 70px);
  }
}
.Block--slider .Slide {
  position: relative;
}
.Block--slider .Slide-image:not(:only-child) .Slide-object {
  position: relative;
}
.Block--slider .Slide-image:not(:only-child) .Slide-object::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.Block--slider .Slide-image:not(:only-child) .Slide-object img {
  width: 100%;
  height: auto;
  transition: height 0.3s, min-height 0.3s;
}
.Block--slider .Slide-video-wrapper {
  position: relative;
}
.Block--slider .Slide-video-wrapper::after {
  content: "";
  display: block;
  padding-top: 66.6666666667%;
}
.Block--slider .Slide-video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Block--slider .Slide-titleWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s, visibility 0.3s;
}
.Block--slider .Slide-titleWrapper.is-active {
  opacity: 0;
  visibility: hidden;
}
.Block--slider .Slide-title {
  z-index: 3;
  color: #ffffff;
  max-width: 100%;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  width: calc(100% - (2 * 38px));
}
@media screen and (max-width: 37.4375em) {
  .Block--slider .Slide-title {
    font-size: 1.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--slider .Slide-title {
    font-size: calc(1.3333333333rem + 35 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--slider .Slide-title {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--slider .Slide-title {
    max-width: 11rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--slider .Slide-title {
    max-width: calc(11rem + 210 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--slider .Slide-title {
    max-width: 18rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--slider .Slide-title {
    line-height: 1.1666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--slider .Slide-title {
    line-height: calc(1.1666666667rem + 35 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--slider .Slide-title {
    line-height: 2.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) {
  .Block--slider .Slide-title {
    width: calc(100% - (2 * 40px));
  }
}

.Block--textMedia {
  margin: 2rem 0rem !important;
}
@media screen and (min-width: 48em) {
  .Block--textMedia {
    margin: 4rem 0rem !important;
  }
}
.Block--textMedia .TextMedia-grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
  position: relative;
}
@media screen and (min-width: 64em) {
  .Block--textMedia .TextMedia-grid {
    display: grid;
    grid-template-columns: calc(45% - 20px) 40px calc(55% - 20px);
    grid-template-rows: auto auto;
  }
}
.Block--textMedia .TextMedia-title {
  grid-column: 1/2;
  grid-row: 1/2;
  position: relative;
  z-index: 2;
  margin-bottom: 1.3333333333rem;
}
@media screen and (min-width: 64em) {
  .Block--textMedia .TextMedia-title {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
.Block--textMedia .TextMedia-content {
  grid-column: 1/2;
  grid-row: 3/4;
}
@media screen and (min-width: 64em) {
  .Block--textMedia .TextMedia-content {
    grid-column: 1/2;
    grid-row: 1/3;
  }
}
@media screen and (max-width: 63.9375em) {
  .Block--textMedia .TextMedia-content {
    margin-top: 1.3333333333rem;
  }
}
.Block--textMedia .TextMedia-text {
  font-weight: 300;
}
@media screen and (max-width: 37.4375em) {
  .Block--textMedia .TextMedia-text {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--textMedia .TextMedia-text {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--textMedia .TextMedia-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--textMedia .TextMedia-text {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--textMedia .TextMedia-text {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--textMedia .TextMedia-text {
    line-height: 1.3333333333rem;
  }
}
.Block--textMedia .TextMedia-media {
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: relative;
}
@media screen and (min-width: 64em) {
  .Block--textMedia .TextMedia-media {
    grid-column: 3/4;
    grid-row: 1/3;
  }
}
.Block--textMedia .TextMedia-media .Media--video {
  width: 100%;
}
.Block--textMedia .TextMedia-link {
  margin-top: 0.6666666667rem;
}
.Block--textMedia .TextMedia-background {
  grid-column: 1/2;
  grid-row: 1/2;
  position: absolute;
  top: -1rem;
  right: -1.2666666667rem;
  bottom: 0;
  left: -1.2666666667rem;
  z-index: 0;
  background-color: #eeeeee;
  height: calc(100% + (2 * 80px));
  width: calc(100% + 50px + (2 * 38px));
}
@media screen and (min-width: 64em) {
  .Block--textMedia .TextMedia-background {
    display: none;
  }
}

.Block--tiles {
  margin: 2rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--tiles {
    margin: 4rem 0rem;
  }
}
.Block--tiles .Tiles-grid {
  margin-top: -2.6666666667rem;
}
.Block--tiles .Tiles-grid .Grid-cell {
  margin-top: 2.6666666667rem;
}
.Block--tiles .Tiles-grid.is-odd .Grid-cell:last-child {
  width: 100% !important;
}
.Block--tiles .Tile {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.Block--tiles .Tile-imageWrapper {
  height: 400px;
  display: block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: height 0.3s;
}
.Block--tiles .Tile-imageWrapper:hover .Tile-object {
  transform: scale(1.05);
}
.Block--tiles .Tile-object {
  transition: transform 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.Block--tiles .Tile-title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
@media screen and (max-width: 37.4375em) {
  .Block--tiles .Tile-title {
    font-size: 1.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--tiles .Tile-title {
    font-size: calc(1.3333333333rem + 35 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--tiles .Tile-title {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--tiles .Tile-title {
    line-height: 1.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--tiles .Tile-title {
    line-height: calc(1.3333333333rem + 30 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--tiles .Tile-title {
    line-height: 2.3333333333rem;
  }
}
.Block--tiles .Tile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Block--tiles .Tile-bottom {
  flex-grow: 1;
  padding: 1rem;
  position: relative;
  transition: padding 0.3s;
  padding-bottom: 5rem !important;
  background-color: #eeeeee;
}
@media screen and (max-width: 37.4375em) {
  .Block--tiles .Tile-bottom {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--tiles .Tile-bottom {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--tiles .Tile-bottom {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--tiles .Tile-bottom {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--tiles .Tile-bottom {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--tiles .Tile-bottom {
    line-height: 1.3333333333rem;
  }
}
@media screen and (min-width: 48em) {
  .Block--tiles .Tile-bottom {
    padding: 1.6666666667rem;
  }
}
@media screen and (min-width: 64em) {
  .Block--tiles .Tile-bottom {
    padding: 2.3333333333rem;
  }
}
.Block--tiles .Tile-link {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.1666666667rem 0.5rem;
}
@media screen and (max-width: 37.4375em) {
  .Block--tiles .Tile-link {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--tiles .Tile-link {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--tiles .Tile-link {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--tiles .Tile-link {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--tiles .Tile-link {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--tiles .Tile-link {
    line-height: 1.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) {
  .Block--tiles .Tile-link {
    padding: 0.3333333333rem 1rem;
  }
}
.Block--tiles .Tile.is-green .Tile-link {
  background: linear-gradient(to right, #006341 48%, #c4c4c4 50%);
}
.Block--tiles .Tile.is-red .Tile-link {
  background: linear-gradient(to right, #D6001C 48%, #c4c4c4 50%);
}
.Block--tiles .Tile.is-green .Tile-link, .Block--tiles .Tile.is-red .Tile-link {
  background-size: 210% 100%;
  background-position: right bottom;
}
.Block--tiles .Tile.is-green .Tile-link:hover, .Block--tiles .Tile.is-red .Tile-link:hover {
  color: #ffffff;
  text-decoration: none;
  background-position: left bottom;
}

.Block--timeline {
  margin: 2.6666666667rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--timeline {
    margin: 5.3333333333rem 0rem;
  }
}
.Block--timeline .Timeline-title {
  margin: 0 auto;
  max-width: 13.3333333333rem;
  text-align: center;
}
@media screen and (min-width: 37.5em) {
  .Block--timeline .Timeline-lines {
    min-height: 90px;
    position: relative;
    margin-top: 2.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (min-width: 48em) {
  .Block--timeline .Timeline-lines {
    margin-top: 5.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 84.9375em) {
  .Block--timeline .Timeline-lines {
    margin-left: 1.3333333333rem;
    margin-right: 1.3333333333rem;
  }
}
@media screen and (min-width: 37.5em) {
  .Block--timeline .Timeline-lines::before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2040%22%3E%3Cline%20x1%3D%220.5%22%20y1%3D%2240%22%20x2%3D%220.5%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%2F%3E%3Cline%20x1%3D%225.5%22%20y1%3D%2240%22%20x2%3D%225.5%22%20y2%3D%2220%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%2F%3E%3Cline%20x1%3D%2210.5%22%20y1%3D%2240%22%20x2%3D%2210.5%22%20y2%3D%2220%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%2F%3E%3Cline%20x1%3D%2215.5%22%20y1%3D%2240%22%20x2%3D%2215.5%22%20y2%3D%2220%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%2F%3E%3Cline%20x1%3D%2219.5%22%20y1%3D%2240%22%20x2%3D%2219.5%22%20y2%3D%2220%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%2F%3E%3C%2Fsvg%3E");
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 37.5em) {
  .Block--timeline .Timeline-line {
    position: absolute;
    top: 0;
    cursor: pointer;
    color: #c4c4c4;
    margin-top: 1.6666666667rem;
    transition: color 0.3s;
    transform: translateX(-50%);
  }
  .Block--timeline .Timeline-line::before {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2290.5%22%20viewBox%3D%220%200%2020%2090.5%22%3E%3Cg%20id%3D%22Group_163%22%20data-name%3D%22Group%20163%22%20transform%3D%22translate(-380%20-4857)%22%3E%3Ccircle%20id%3D%22Ellipse_14%22%20data-name%3D%22Ellipse%2014%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%20transform%3D%22translate(380%204857)%22%20fill%3D%22%23cacaca%22%2F%3E%3Cline%20id%3D%22Line_193%22%20data-name%3D%22Line%20193%22%20y2%3D%2281%22%20transform%3D%22translate(390.5%204866.5)%22%20fill%3D%22none%22%20stroke%3D%22%23cacaca%22%20stroke-width%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    position: absolute;
    bottom: 40px;
    left: calc(50% - 10px);
    width: 20px;
    height: 100px;
    transition: background 0.3s;
    background-repeat: no-repeat;
  }
  .Block--timeline .Timeline-line:hover {
    color: #898989;
  }
  .Block--timeline .Timeline-line:hover::before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2290.5%22%20viewBox%3D%220%200%2020%2090.5%22%3E%3Cg%20id%3D%22Group_163%22%20data-name%3D%22Group%20163%22%20transform%3D%22translate(-380%20-4857)%22%3E%3Ccircle%20id%3D%22Ellipse_14%22%20data-name%3D%22Ellipse%2014%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%20transform%3D%22translate(380%204857)%22%20fill%3D%22%23898989%22%2F%3E%3Cline%20id%3D%22Line_193%22%20data-name%3D%22Line%20193%22%20y2%3D%2281%22%20transform%3D%22translate(390.5%204866.5)%22%20fill%3D%22none%22%20stroke%3D%22%23898989%22%20stroke-width%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  }
  .Block--timeline .Timeline-line.is-active {
    color: #D6001C;
  }
  .Block--timeline .Timeline-line.is-active::before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2290.5%22%20viewBox%3D%220%200%2020%2090.5%22%3E%3Cg%20id%3D%22Group_163%22%20data-name%3D%22Group%20163%22%20transform%3D%22translate(-380%20-4857)%22%3E%3Ccircle%20id%3D%22Ellipse_14%22%20data-name%3D%22Ellipse%2014%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%20transform%3D%22translate(380%204857)%22%20fill%3D%22%23D6001C%22%2F%3E%3Cline%20id%3D%22Line_193%22%20data-name%3D%22Line%20193%22%20y2%3D%2281%22%20transform%3D%22translate(390.5%204866.5)%22%20fill%3D%22none%22%20stroke%3D%22%23D6001C%22%20stroke-width%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  }
}
.Block--timeline .Timeline-items {
  overflow: hidden;
  margin-top: 2.6666666667rem;
}
@media screen and (min-width: 48em) {
  .Block--timeline .Timeline-items {
    margin-top: 5.3333333333rem;
  }
}
.Block--timeline .Timeline-items .Slider-item:not(.is-active) .Grid--withLargeGutter {
  margin-left: -2.5333333333rem;
}
.Block--timeline .Timeline-itemText {
  font-size: 0.7333333333rem;
}
.Block--timeline .Timeline-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.3333333333rem 0rem;
}
.Block--timeline .Timeline-controls .Slider-button {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  margin: 0;
}
.Block--timeline .Timeline-controls .Slider-buttonTimeline--prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23c4c4c4'%2F%3E%3C%2Fsvg%3E");
}
.Block--timeline .Timeline-controls .Slider-buttonTimeline--prev:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.Block--timeline .Timeline-controls .Slider-buttonTimeline--next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23c4c4c4'%2F%3E%3C%2Fsvg%3E");
}
.Block--timeline .Timeline-controls .Slider-buttonTimeline--next:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.Block--timeline .Timeline-itemYear {
  font-size: 1rem;
  color: #D6001C;
  font-weight: 700;
}
.Block--timeline .Timeline-itemTitle {
  margin-bottom: 0.3333333333rem;
}
@media screen and (max-width: 48em) {
  .Block--timeline .Timeline-itemTitle {
    display: none;
  }
}

.Block--titleText {
  margin: 2rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--titleText {
    margin: 4rem 0rem;
  }
}
.Block--titleText .TitleText-grid {
  align-items: flex-start;
}
.Block--titleText .TitleText-title {
  margin-bottom: 1.3333333333rem;
}
.Block--titleText .TitleText-text {
  font-weight: 300;
}
@media screen and (max-width: 37.4375em) {
  .Block--titleText .TitleText-text {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--titleText .TitleText-text {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--titleText .TitleText-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--titleText .TitleText-text {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--titleText .TitleText-text {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--titleText .TitleText-text {
    line-height: 1.3333333333rem;
  }
}
.Block--titleText .TitleText-intro {
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.2rem;
}

.Block--usps {
  position: relative;
  margin: 2rem 0rem;
}
@media screen and (min-width: 48em) {
  .Block--usps {
    margin: 4rem 0rem;
  }
}
.Block--usps .Usps-top {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 4rem;
}
.Block--usps .Usps-title + .Usps-intro {
  margin-top: 1.1666666667rem;
}
@media screen and (min-width: 64em) {
  .Block--usps .Usps-title + .Usps-intro {
    margin-top: 2.5rem;
  }
}
.Block--usps .Usps-intro {
  margin: 0 auto;
  max-width: 22.6666666667rem;
}
@media screen and (max-width: 37.4375em) {
  .Block--usps .Usps-intro {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--usps .Usps-intro {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--usps .Usps-intro {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--usps .Usps-intro {
    line-height: 0.8666666667rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 79.9375em) {
  .Block--usps .Usps-intro {
    line-height: calc(0.8666666667rem + 14 * ((100vw - 20rem) / 680));
  }
}
@media screen and (min-width: 80em) {
  .Block--usps .Usps-intro {
    line-height: 1.3333333333rem;
  }
}
.Block--usps .Usp-iconWrapper {
  display: flex;
  height: 100px;
}
@media screen and (min-width: 64em) {
  .Block--usps .Usp-iconWrapper {
    align-items: flex-end;
  }
}
.Block--usps .Usp-iconWrapper.is-rectangle .Usp-image {
  max-width: 100px;
}
.Block--usps .Usp-title {
  color: #D6001C;
  text-transform: uppercase;
  margin-top: 1.0666666667rem;
}
@media screen and (max-width: 37.4375em) {
  .Block--usps .Usp-title {
    font-size: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--usps .Usp-title {
    font-size: calc(1rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--usps .Usp-title {
    font-size: 1.3333333333rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .Block--usps .Usp-title {
    line-height: 1rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Block--usps .Usp-title {
    line-height: calc(1rem + 10 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .Block--usps .Usp-title {
    line-height: 1.3333333333rem;
  }
}
@media screen and (max-width: 63.9375em) {
  .Block--usps .Usp-title {
    text-align: center;
  }
}
.Block--usps .Usp-image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100px;
}
.Block--usps .Usp-intro {
  font-size: 0.7333333333rem;
}
.Block--usps .Usp-bottom {
  margin-top: 0.6666666667rem;
}
.Block--usps .Usp-grid {
  margin-top: -2.3333333333rem;
}
.Block--usps .Usp-grid .Grid-cell {
  margin-top: 2.3333333333rem;
}
@media screen and (max-width: 63.9375em) {
  .Block--usps .Usp-top {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 48em) {
  .Block--usps .Usp-top {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 47.9375em) {
  .Block--usps.has-intro .Usp-title {
    font-size: 1rem;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Block--usps.has-intro .Usp-title {
    font-size: calc(1rem + 10 * ((100vw - 25.6rem) / 256));
  }
}
@media screen and (min-width: 64em) {
  .Block--usps.has-intro .Usp-title {
    font-size: 1.3333333333rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .Block--usps.has-intro .Usp-title {
    line-height: 1rem;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Block--usps.has-intro .Usp-title {
    line-height: calc(1rem + 10 * ((100vw - 25.6rem) / 256));
  }
}
@media screen and (min-width: 64em) {
  .Block--usps.has-intro .Usp-title {
    line-height: 1.3333333333rem;
  }
}
@media screen and (max-width: 63.9375em) {
  .Block--usps.has-intro .Usp-cell {
    display: flex;
  }
  .Block--usps.has-intro .Usp-cell + .Usp-cell {
    position: relative;
  }
  .Block--usps.has-intro .Usp-cell + .Usp-cell::before {
    content: "";
    position: absolute;
    top: -35px;
    right: 0;
    left: 2.6666666667rem;
    height: 1px;
    width: calc(100% - 80px);
    background-color: #eeeeee;
  }
  .Block--usps.has-intro .Usp-title {
    margin-top: 0;
  }
  .Block--usps.has-intro .Usp-top {
    width: 100%;
    max-width: 100px;
    flex-shrink: 0;
  }
}
@media screen and (max-width: 63.9375em) and (min-width: 20em) {
  .Block--usps.has-intro .Usp-top {
    width: 150px;
  }
}
@media screen and (max-width: 63.9375em) and (min-width: 37.5em) {
  .Block--usps.has-intro .Usp-top {
    width: 200px;
  }
}
@media screen and (max-width: 63.9375em) {
  .Block--usps.has-intro .Usp-bottom {
    margin-top: 0;
    margin-left: 1.3333333333rem;
  }
}

.productFilter {
  position: relative;
  margin: 2rem 0rem;
  background-color: #eeeeee;
}
@media screen and (min-width: 37.5em) {
  .productFilter {
    margin: 5.3333333333rem 0rem;
  }
}
.productFilter > .Container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #eeeeee;
}
@media screen and (min-width: 37.5em) {
  .productFilter > .Container {
    padding-top: 5.3333333333rem;
    padding-bottom: 5.3333333333rem;
  }
}
.productFilter .hidden {
  visibility: hidden;
  height: 0;
}
.productFilter-filterBtn {
  padding-bottom: 1rem;
}
@media screen and (min-width: 48em) {
  .productFilter-filterBtn {
    display: none;
  }
}
.productFilter-filterBtn .Button--primary:hover {
  background-position: right bottom;
}
.productFilter-title {
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: -2.5rem;
  color: #006341;
}
@media screen and (min-width: 37.5em) {
  .productFilter-title {
    top: -6.5rem;
  }
}
.productFilter-productList {
  list-style: none;
  padding-left: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.25rem 1rem;
  flex-wrap: wrap;
  font-size: 24px;
}
@media screen and (min-width: 37.5em) {
  .productFilter-productList {
    flex-direction: column;
    gap: 0;
    font-size: 30px;
  }
}
.productFilter-productListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.productFilter-products {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.333333rem;
  overflow: hidden;
}
@media screen and (min-width: 37.5em) {
  .productFilter-products {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 64em) {
  .productFilter-products {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 80em) {
  .productFilter-products {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.productFilter-arrow {
  transition: all 0.4s;
  transform: rotate(180deg);
  width: 18px;
  margin-left: 10px;
}
.productFilter-arrow--expanded {
  transform: rotate(0);
}
.productFilter-groupTitle {
  margin-bottom: 10px;
  text-transform: uppercase;
}
.productFilter-img {
  aspect-ratio: 320/300;
  object-fit: cover;
  transition: all 0.4s;
}
.productFilter-picture {
  display: flex;
  align-items: center;
  justify-content: center;
}
.productFilter-hidden {
  display: none !important;
}
.productFilter-item {
  display: block;
  flex-direction: column;
  background: #ffffff;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  min-width: 0px;
}
.productFilter-item:hover .productFilter-img {
  transform: scale(1.05);
}
.productFilter-item-info {
  font-size: 0.7333333333rem;
  flex-grow: 1;
  position: relative;
  background-color: #ffffff;
  padding: 0.6666666667rem;
}
@media screen and (min-width: 48em) {
  .productFilter-item-info {
    padding: 1.3333333333rem 1.3333333333rem 2rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .productFilter-item-title {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .productFilter-item-title {
    font-size: calc(0.8rem + 6 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .productFilter-item-title {
    font-size: 1rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .productFilter-item-title {
    line-height: 0.8rem;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .productFilter-item-title {
    line-height: calc(0.8rem + 6 * ((100vw - 20rem) / 424));
  }
}
@media screen and (min-width: 64em) {
  .productFilter-item-title {
    line-height: 1rem;
  }
}
.productFilter-item-title a {
  color: #006341;
  text-decoration: none;
}
.productFilter-item-title a:hover {
  color: #000000;
}

.Product {
  margin: 2rem 0rem;
}
@media screen and (min-width: 48em) {
  .Product {
    margin: 4rem 0rem;
  }
}
.Product-back {
  margin: 0;
  margin-bottom: 1rem;
}
.Product-link {
  margin: 0.5rem 0;
}
.Product-text {
  order: 2;
}
@media screen and (min-width: 64em) {
  .Product-text {
    order: 1;
  }
}
.Product-img {
  order: 1;
}
@media screen and (min-width: 64em) {
  .Product-img {
    order: 2;
  }
}
.Product-title {
  margin-bottom: 1.3333333333rem;
}
.Product-article {
  display: flex;
}
.Product-characteristics {
  margin-top: 1rem;
}
@media screen and (min-width: 48em) {
  .Product-characteristics {
    margin-top: 2rem;
  }
}
.Product-characteristicsTitle {
  margin-bottom: 1.3333333333rem;
}
.Product-characteristicsTable {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #006341;
}
.Product-bold {
  font-weight: 500;
}
.Product-char {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
  padding: 0 0.5rem;
}
@media screen and (max-width: 47.9375em) {
  .Product-char {
    font-size: 0.8666666667rem;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Product-char {
    font-size: calc(0.8666666667rem + 4 * ((100vw - 25.6rem) / 256));
  }
}
@media screen and (min-width: 64em) {
  .Product-char {
    font-size: 1rem;
  }
}
@media screen and (min-width: 37.5em) {
  .Product-char > :nth-child(1) {
    width: 50%;
  }
}
.Product-char:nth-child(odd) {
  background-color: #efefef;
}
.Product-info {
  margin-top: 1rem;
}
@media screen and (min-width: 48em) {
  .Product-info {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .Product-info {
    font-size: 0.6666666667rem;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .Product-info {
    font-size: calc(0.6666666667rem + 10 * ((100vw - 25.6rem) / 256));
  }
}
@media screen and (min-width: 64em) {
  .Product-info {
    font-size: 1rem;
  }
}

.rubidorProducts {
  position: relative;
  background-color: #eeeeee;
}
.rubidorProducts > .Container {
  background-color: #eeeeee;
}
.rubidorProducts-loadingIndicator {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 99999;
  height: 100%;
  background: rgba(0, 99, 65, 0.1);
}
.rubidorProducts-categoryWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.rubidorProducts-categoryWrapper h4, .rubidorProducts-categoryWrapper .h4 {
  margin-bottom: 0.5rem;
}
.rubidorProducts-title {
  text-align: center;
  position: relative;
  color: #006341;
}
.rubidorProducts-productTypeTitle {
  margin-bottom: 1rem;
}
.rubidorProducts-articleNr {
  font-size: 18px;
  margin-bottom: 0.5rem;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-filterTitle {
    margin-bottom: 1rem;
  }
}
.rubidorProducts-categoryChildsWrapper {
  display: flex;
  flex-direction: column;
}
.rubidorProducts-filterGroupWrapper {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  margin-top: 1rem;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-filterGroupWrapper {
    margin-top: 0;
  }
}
.rubidorProducts-card {
  color: #000000 !important;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  column-gap: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition: background-color 0.4s ease-in-out;
}
@media screen and (min-width: 48em) {
  .rubidorProducts-card {
    flex-direction: row;
  }
}
.rubidorProducts-card:hover {
  background-color: #ffffff;
}
.rubidorProducts-cardTitle {
  font-weight: 600;
  font-size: 25px;
}
.rubidorProducts-cardContent {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-top: 0.5rem;
}
@media screen and (min-width: 48em) {
  .rubidorProducts-cardContent {
    margin-top: 0;
  }
}
.rubidorProducts-loadMoreWrapper {
  margin-top: 16px;
}
.rubidorProducts-inputWrapper {
  display: flex;
  column-gap: 12px;
  align-items: center;
  font-size: 20px;
}
.rubidorProducts-productsList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  column-gap: 1.5rem;
}
@media screen and (min-width: 48em) {
  .rubidorProducts-productsList {
    grid-template-columns: 1fr 1fr;
  }
}
.rubidorProducts-dimensionLabel {
  display: inline-block;
  min-width: 120px;
}
.rubidorProducts-load {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.4s ease-in-out;
}
.rubidorProducts-load:hover {
  color: #006341;
}
.rubidorProducts-selectedFilters {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #dee2e6;
  display: none;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-selectedFilters {
    display: block;
  }
}
.rubidorProducts-selectedFilters--mobile {
  display: block;
  margin-top: 1rem;
}
.rubidorProducts-selectedFilters--mobile h4, .rubidorProducts-selectedFilters--mobile .h4 {
  margin-bottom: 0.5rem;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-selectedFilters--mobile {
    display: none;
  }
}
.rubidorProducts-selectedFiltersList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-selectedFiltersList {
    margin-top: 30px;
  }
}
.rubidorProducts-selectedFiltersList .Button--primary {
  display: flex;
  align-items: center;
}
.rubidorProducts-filterTag {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
.rubidorProducts-filterTagContainer {
  padding: 4px 8px;
  background-color: rgba(0, 99, 65, 0.1);
  display: flex;
  cursor: pointer;
  gap: 6px;
  align-items: center;
  transition: all 0.4s;
  font-size: 20px;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-filterTagContainer {
    font-size: 25px;
    padding: 4px 16px;
  }
}
.rubidorProducts-filterTagContainer:hover {
  background-color: rgba(0, 99, 65, 0.2);
}
.rubidorProducts-productsList {
  margin-top: 2rem;
}
.rubidorProducts-tagClose {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: rgba(0, 99, 65, 0.2);
  height: 30px;
  width: 30px;
}
.rubidorProducts-tagClose > svg {
  height: 16px;
  width: 16px;
}
.rubidorProducts-paginationParent {
  border-top: 1px solid #dee2e6;
  margin-top: 2rem;
}
.rubidorProducts-paginationGrid {
  margin-top: 1rem;
  display: grid;
  column-gap: 2rem;
  row-gap: 1rem;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-paginationGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.rubidorProducts-info {
  text-align: center;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-info {
    text-align: start;
  }
}
.rubidorProducts-pageList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 0.75rem;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 0;
}
.rubidorProducts-pageItem {
  display: flex;
  align-items: center;
  position: relative;
}
.rubidorProducts-pageItem--acctive {
  text-decoration: none;
}
.rubidorProducts-pageItem a {
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.4s;
}
.rubidorProducts-pageItem a:hover {
  text-decoration: none;
  color: #006341 !important;
}
.rubidorProducts-paginationDiv {
  margin-top: 2rem;
  font-size: 20px;
}
.rubidorProducts-picture {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
}
@media screen and (min-width: 48em) {
  .rubidorProducts-picture {
    align-self: start;
    width: 40%;
  }
}
.rubidorProducts-nextPrev {
  cursor: pointer;
  transition: all 0.4s;
}
.rubidorProducts-nextPrev:hover {
  color: #006341;
}
.rubidorProducts-pages {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 0.5rem;
}
@media screen and (min-width: 48em) {
  .rubidorProducts-pages {
    flex-direction: row;
  }
}
.rubidorProducts-filterButtonMobile {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-filterButtonMobile {
    display: none;
  }
}
.rubidorProducts-filtersTitleWrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  align-items: flex-start;
}
.rubidorProducts-mobileClose {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.rubidorProducts-mobileClose > svg {
  height: 32px;
  width: 32px;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-mobileClose {
    display: none;
  }
}
.rubidorProducts-showResults {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: block;
}
@media screen and (min-width: 64em) {
  .rubidorProducts-showResults {
    display: none;
  }
}
.rubidorProducts-filters {
  background: white;
  max-width: 500px;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 500;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
}
.rubidorProducts-filters--animate {
  transition: transform 0.4s ease-in-out;
}
.rubidorProducts-filters.open {
  transform: translateX(0);
}
@media screen and (min-width: 64em) {
  .rubidorProducts-filters {
    position: relative;
    transform: translateX(0);
    max-width: unset;
    background: transparent;
    padding: 0;
  }
}
.rubidorProducts-categoryLinksParent {
  margin-bottom: 1rem;
}
.rubidorProducts-categoryLinksWrapper {
  display: flex;
  flex-direction: column;
  column-gap: 0.25rem;
}
.rubidorProducts-categoryLink {
  font-size: 25px;
  transition: all 0.4s ease-in-out;
  text-decoration: none;
  color: #000000 !important;
}
.rubidorProducts-categoryLink--active {
  color: #006341 !important;
  font-weight: 600;
}
.rubidorProducts-categoryLink:hover {
  color: #006341 !important;
}

.Button--mobile {
  background: rgba(0, 99, 65, 0.2) !important;
  color: #000;
}
.Button--mobile:hover:hover {
  background: rgba(0, 99, 65, 0.1) !important;
  color: #000;
}
@media screen and (min-width: 64em) {
  .Button--mobile {
    background-color: initial;
  }
}

.Block--mediaCenter {
  margin: 2rem 0rem !important;
}
@media screen and (min-width: 48em) {
  .Block--mediaCenter {
    margin: 2.6666666667rem 0rem !important;
  }
}
.Block--mediaCenter + .Block--app {
  margin-top: 5.3333333333rem;
}

.lightbox {
  position: relative;
  overflow: hidden;
  display: block;
  width: fit-content;
}
.lightbox:hover .lightbox-play {
  background-color: #000;
}
.lightbox-play {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 50;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  transition: all 0.4s ease-in-out;
}
.lightbox-play > svg {
  width: 50px;
  height: 50px;
  fill: #fff;
  transition: all 0.4s ease-in-out;
}

.Site-solidor .lightbox-play {
  background-color: #D6001C;
}

.Site-rubidor .lightbox-play {
  background-color: #006341;
}

.json-lightbox-img {
  width: 100%;
  object-fit: cover;
}

.video-lightbox-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.json {
  position: relative;
  z-index: 50;
  overflow: hidden;
}
.json > iframe {
  height: 100%;
  width: 100%;
}

.video-inline {
  position: relative;
  z-index: 50;
  overflow: hidden;
}
.video-inline > video {
  width: 100%;
}

.hidden {
  display: none;
}

.Block--lottieFile .lottieContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Block--lottieFile .Container {
  height: 100%;
}

.NewsItem-image {
  width: 100%;
  height: 100%;
}
.NewsItem-imageWrapper {
  margin-bottom: 2.6666666667rem;
}
@media screen and (min-width: 48em) {
  .NewsItem-imageWrapper {
    margin-bottom: 5.0666666667rem;
  }
}
.NewsItem-date {
  font-size: 0.7333333333rem;
}
@media screen and (max-width: 47.9375em) {
  .NewsItem-date {
    margin-bottom: 1.3333333333rem;
  }
}
.NewsItem .Block--app {
  margin-top: 3.7333333333rem;
}
@media screen and (min-width: 48em) {
  .NewsItem .Block--app {
    margin-top: 7.4666666667rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .NewsItem .TitleText-title {
    margin-bottom: 0.3333333333rem;
  }
}

/* Footer */
.Footer {
  font-size: 0.7333333333rem;
  color: #ffffff;
  font-weight: 300;
  position: relative;
  background-color: #D6001C;
}
.Footer-top {
  padding-top: 1.3333333333rem;
  padding-bottom: 1.3333333333rem;
}
.Footer-top .Grid {
  margin-top: -1.3333333333rem;
}
.Footer-top .Grid .Grid-cell {
  margin-top: 1.3333333333rem;
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Footer-top .Grid-cell--footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 63.9375em) {
  .Footer-top .Grid-cell--footer {
    max-width: 13.3333333333rem;
  }
}
.Footer-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 0.6666666667rem;
  padding-bottom: 0.6666666667rem;
}
@media screen and (min-width: 37.5em) {
  .Footer-bottom {
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 64em) {
  .Footer-bottom {
    flex-direction: row;
    justify-content: space-between;
  }
}
.Footer a {
  color: #ffffff;
  text-decoration: none;
  transition: border-color 0.3s;
  border-bottom: 1px solid #D6001C;
}
.Footer a:hover {
  border-color: #ffffff;
}
.Footer .Hunt {
  position: absolute;
  right: 10px;
  bottom: 14px;
  border: none;
}
.Footer .Hunt:hover {
  border: none;
}
.Footer form.Newsletter {
  max-width: 10rem;
  overflow: hidden;
}
@media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
  .Footer form.Newsletter {
    justify-content: center;
    margin-left: 44px;
  }
}
.Footer form.Newsletter label {
  display: none;
}
.Footer form.Newsletter .Form-item:not(.Form-item--action) {
  width: 100%;
}
.Footer form.Newsletter .Form-item--action {
  width: 44px;
  line-height: 34px;
  transform: translateX(-44px);
}
.Footer form.Newsletter .Form-item--action div {
  height: 100%;
}
.Footer form.Newsletter .Form-field--input {
  max-width: none;
  border: 1px solid #ffffff;
  background-color: transparent;
  padding-right: 1.3333333333rem;
}
.Footer form.Newsletter .Form-field--input, .Footer form.Newsletter .Form-field--input::placeholder {
  color: #ffffff;
}
.Footer form.Newsletter .Button--primary {
  width: 44px;
  border: none;
  height: 100%;
  color: #D6001C;
  border: 1px solid #ffffff;
  background: linear-gradient(to right, #000000 48%, #ffffff 50%);
  background-size: 210% 100%;
  background-position: right bottom;
}
.Footer form.Newsletter .Button--primary:hover {
  color: #D6001C;
  background-position: left bottom;
}

/* Print */
@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    letter-spacing: 0;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #9e9e9e;
    page-break-inside: avoid;
  }
  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  .h2,
  h3,
  .h3 {
    orphans: 3;
    widows: 3;
  }
  h2, .h2,
  h3,
  .h3 {
    page-break-after: avoid;
  }
  /**
   * General
   */
  html {
    font-family: sans-serif;
    font-size: 12pt;
  }
  .Container {
    max-width: none;
  }
  h1, .h1,
  h2,
  .h2,
  h3,
  .h3 {
    margin-bottom: 12pt;
    text-transform: none;
  }
  h1, .h1 {
    font-size: 24pt;
  }
  h2, .h2 {
    font-size: 18pt;
  }
  h3, .h3 {
    font-size: 14pt;
  }
}