.Block--slider {
    position: relative;
    margin: u($spacing-unit--lg, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .Slider {

        @include mq($from: $viewport--md) {
            margin-left: u($structure-gutter--l);
            margin-right: u($structure-gutter--l);
        }

        @include mq($from: $container-width + (2 * $structure-gutter--l)) {
            margin-left: auto;
            margin-right: auto;
            max-width: u($container-width);
        }

        &-navigation {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }

        &-button {
            width: 40px;
            height: 40px;

            background-color: #ffffff;
            background-size: 20px 20px;
            background-position: center;

            border-radius: 50%;

            @include mq($from: $viewport--md) {
                width: 48px;
                height: 48px;

                background-color: $cc-red;

                transition: background-color .3s ease;

                &:hover {
                    background-color: #111111;
                }

                &.is-disabled {
                    background-color: #111111;
                }
            }
        }

        &-buttonSlider--next {
            right: 10px;
            left: auto;

            @include mq($from: $viewport--md) {
                background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#ffffff'/></svg>");
            }

            @include mq($container-width + 180) {
                right: auto;
                left: calc(#{$container-width}px + ((100vw - #{$container-width}px) / 2) + 8px);
            }
        }

        &-buttonSlider--prev {
            right: auto;
            left: 10px;

            @include mq($from: $viewport--md) {
                background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#ffffff'/></svg>");
            }

            @include mq($container-width + 180) {
                left: calc(((100vw - #{$container-width}px) / 2) - 70px);
            }
        }
    }

    .Slide {
        position: relative;

        &-image:not(:only-child) .Slide-object {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background-color: rgba($black, .5);
            }

            img {
                width: 100%;
                height: auto;
                transition: height .3s, min-height .3s;
            }
        }

        &-video-wrapper {
            position: relative;

            &::after {
                content: "";
                display: block;

                padding-top: (2 / 3) * 100%;
            }
        }

        &-video {
            position: absolute;
            left: 0;
            top: 0;

            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        &-titleWrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            visibility: visible;
            background-color: rgba($black,.5);
            transition: opacity .3s, visibility .3s;

            &.is-active {
                opacity: 0;
                visibility: hidden;
            }
        }

        &-title {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 40, 75);
            @include fluid-type('max-width', $viewport--sm, $viewport--lg, 330, 540);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 35, 70);

            z-index: 3;
            color: $white;
            max-width: 100%;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            width: calc(100% - (2 * #{$structure-responsive-gutter--l}px));

            @include mq($from: $viewport--sm) {
                width: calc(100% - (2 * #{$structure-gutter--l}px));
            }
        }
    }
}
