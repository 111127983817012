@import 'vars';
@import '~bootstrap/scss/bootstrap';

// 1. Settings
@import '1-settings/base';
@import '1-settings/colors';
@import '1-settings/fonts';

@import '1-settings/elements/heading';
@import '1-settings/elements/horizontal-rule';
@import '1-settings/elements/image';
@import '1-settings/elements/link';
@import '1-settings/elements/list';
@import '1-settings/elements/paragraph';
@import '1-settings/elements/selection';

@import '1-settings/objects/alert';
@import '1-settings/objects/button';
@import '1-settings/objects/close';
@import '1-settings/objects/container';
@import '1-settings/objects/field-group';
@import '1-settings/objects/flex-embed';
@import '1-settings/objects/form';
@import '1-settings/objects/grid';
@import '1-settings/objects/nav';
@import '1-settings/objects/structure';
@import '1-settings/objects/table';
@import '1-settings/objects/toggle';

@import '1-settings/components/fields';
@import '1-settings/components/cookie';
@import '1-settings/components/modal';
@import '1-settings/components/digitalpulse';

// 2. Tools
@import '2-tools/functions/throw';
@import '2-tools/functions/checks';
@import '2-tools/functions/color-invert';
@import '2-tools/functions/truncate-list';
@import '2-tools/functions/maps';
@import '2-tools/functions/math';
@import '2-tools/functions/order-index';
@import '2-tools/functions/units';
@import '2-tools/functions/string';
@import '2-tools/functions/svg';

@import '2-tools/mixins/arrow';
@import '2-tools/mixins/background-svg';
@import '2-tools/mixins/caret';
@import '2-tools/mixins/context';
@import '2-tools/mixins/dimensions';
@import '2-tools/mixins/family';
@import '2-tools/mixins/fluid-type';
@import '2-tools/mixins/font-size';
@import '2-tools/mixins/font-smoothing';
@import '2-tools/mixins/grid';
@import '2-tools/mixins/hover';
@import '2-tools/mixins/images';
@import '2-tools/mixins/last-children';
@import '2-tools/mixins/lhcrop';
@import '2-tools/mixins/maps';
@import '2-tools/mixins/parent-nth-status';
@import '2-tools/mixins/parent-state';
@import '2-tools/mixins/parent-status';
@import '2-tools/mixins/position';
@import '2-tools/mixins/queries';
@import '2-tools/mixins/transition';
@import '2-tools/mixins/truncate';

// 3. Generic
@import '3-generic/reset';

// 4. Elements
@import '4-elements/horizontal-rule';
@import '4-elements/image';
@import '4-elements/link';
@import '4-elements/list';
@import '4-elements/selection';
@import '4-elements/heading';
@import '4-elements/paragraph';
@import '4-elements/main';

// 5. Objects
@import '5-objects/alert';
@import '5-objects/button';
@import '5-objects/button-group';
@import '5-objects/container';
@import '5-objects/close';
@import '5-objects/field-group';
@import '5-objects/flex-embed';
@import '5-objects/form';
//@import '5-objects/media';
@import '5-objects/grid';
@import '5-objects/grid-legacy';
@import '5-objects/icon';
@import '5-objects/nav';
@import '5-objects/structure';
@import '5-objects/table';
@import '5-objects/toggle';

// 6. Components
@import '6-components/alert';
@import '6-components/button';
@import '6-components/modal';
@import '6-components/cookie';
@import '6-components/navigation';
@import '6-components/fields';
@import '6-components/slider';
@import '6-components/gallery';
@import '6-components/digitalpulse';
@import '6-components/loader';

// 7. Theme
@import '7-theme/header';
@import '7-theme/logo';
@import '7-theme/general';
@import '7-theme/multisite';
@import '7-theme/blocks';
@import '7-theme/news';
@import '7-theme/footer';
@import '7-theme/print';

@import '~@fancyapps/ui/dist/fancybox.css';
