.Block--tiles {
    margin: u($spacing-unit * 1.5, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .Tiles {

        &-grid {
            margin-top: u($spacing-unit--lg * -1);

            .Grid-cell {
                margin-top: u($spacing-unit--lg);
            }

            &.is-odd .Grid-cell:last-child {
                width: 100% !important;
            }
        }
    }

    .Tile {
        display: flex;
        flex-direction: column;

        height: 100%;

        &-imageWrapper {
            height: 400px;
            display: block;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            transition: height .3s;

            &:hover {

                .Tile-object {
                    transform: scale(1.05);
                }
            }
        }

        &-object {
            transition: transform .3s;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        &-title {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 40, 75);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 40, 70);

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            width: 100%;
            height: 100%;
            color: $white;
            font-weight: bold;
            padding: u(30);
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-text {}

        &-bottom {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 20, 30);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 26, 40);

            flex-grow: 1;
            padding: u(30);
            position: relative;
            transition: padding .3s;
            padding-bottom: u(150) !important;
            background-color: $cc-grey--lighter;

            @include mq($from: $viewport--md) {
                padding: u(50);
            }

            @include mq($from: $viewport--lg) {
                padding: u(70);
            }
        }

        &-link {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 20, 30);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 26, 40);

            position: absolute;
            right: 0;
            bottom: 0;
            padding: u(5, 15);

            // transition: all .3spadding .3s, background-color .3s;

            @include mq($from: $viewport--sm) {
                padding: u(10, 30);
            }
        }

        &.is-green .Tile-link {
            background: linear-gradient(to right, $cc-green 48%, $cc-grey 50%);
        }

        &.is-red .Tile-link {
            background: linear-gradient(to right, $cc-red 48%, $cc-grey 50%);
        }

        &.is-green .Tile-link,
        &.is-red .Tile-link {
            background-size: 210% 100%;
            background-position: right bottom;

            &:hover {
                color: $white;
                text-decoration: none;
                background-position: left bottom;
            }
        }
    }
}
