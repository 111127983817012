/* Main */
.Nav--main {
    @include mq($until: $viewport--lg - 1) {
        flex-grow: 1;
        overflow-y: auto;
    }

    // List
    .Nav-list {
        @include mq($until: $viewport--lg - 1) {
            display: block;
            margin-left: -10px;
        }

        @include mq($from: $viewport--lg) {
            margin-left: u($spacing-unit * -1);
        }
    }

    // Item
    .Nav-item {
        display: flex;
        padding-left: 0;
        transition: padding 0.3s;

        @include mq($from: $viewport--lg) {
            padding-left: u($spacing-unit--sm);
        }

        @include mq($from: $viewport--xl) {
            padding-left: u($spacing-unit--sm * 1.5);
        }

        &.has-children {
            display: flex;
            cursor: pointer;
            flex-wrap: wrap;
            position: relative;
            flex-direction: column;
            align-items: flex-start;

            @include mq($from: $viewport--lg) {
                align-items: center;
                flex-direction: row;
            }
        }

        &.Nav-button .Nav-link {
            color: $cc-red;
            border: 1px solid $cc-red;
            background: linear-gradient(to right, $cc-red 48%, transparent 50%);
            background-size: 210% 100%;
            background-position: right bottom;
            transition: all 0.3s;

            &:hover,
            &.is-active {
                color: $white !important;
                background-position: left bottom;
            }
        }

        .Caret {
            cursor: pointer;
            transform: rotate(-90deg);
            transition: transform 0.3s;
            margin-right: u($spacing-unit--xs * 0.75);
        }

        .Nav-link.has-children {
            display: flex;
            align-items: center;
        }

        .Nav-link.is-open .Caret {
            transform: rotate(0deg);
        }

        @include mq($until: $viewport--lg - 1) {
            + .Nav-item {
                margin-top: u($spacing-unit--xs);
            }

            .show-nav & {
                @include transition(
                    #{opacity,
                    transform},
                    #{300ms,
                    1s},
                    #{linear,
                    cubic-bezier(0.19, 1, 0.22, 1)}
                );

                @for $i from 1 through 10 {
                    $delay: (0.02s * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }
            }

            html:not(.show-nav) & {
                opacity: 0;
                transform: translateY(#{u($spacing-unit)});
            }
        }
    }

    // Link
    .Nav-link {
        @include font-size(20);
        color: #707070;
        padding: u(2, 10);
        text-transform: uppercase;

        @include mq($from: $viewport--lg) {
            white-space: nowrap;
        }

        &:active,
        &:focus,
        &:hover {
            color: $black;
        }

        &.is-active {
            color: $white;
            background-color: $cc-red;
        }
    }
}
