/* Blocks */
.Block {
    &:first-child {
        margin-top: 0;
    }

    ol {
        padding-left: u($spacing-unit--sm);
    }

    ul {
        //padding-left: u($spacing-unit--sm);
        padding-left: 0;

        li {
            color: $red-500;
            font-size: 0.75rem;
            list-style-type: none;
            position: relative;

            &::before {
                //@include font-size(30);

                content: '' !important;
                position: absolute;
                top: 0;
                left: -20px;

                color: $black;
            }
        }
    }
}

@import '../7-theme/blocks/app';
@import '../7-theme/blocks/banner';
@import '../7-theme/blocks/catalogue';
@import '../7-theme/blocks/circle';
@import '../7-theme/blocks/cta';
@import '../7-theme/blocks/exploded';
@import '../7-theme/blocks/form';
@import '../7-theme/blocks/grid';
@import '../7-theme/blocks/image';
@import '../7-theme/blocks/map';
@import '../7-theme/blocks/overview';
@import '../7-theme/blocks/quote';
@import '../7-theme/blocks/row';
@import '../7-theme/blocks/slider';
@import '../7-theme/blocks/textmedia';
@import '../7-theme/blocks/tiles';
@import '../7-theme/blocks/timeline';
@import '../7-theme/blocks/titletext';
@import '../7-theme/blocks/usp';
@import '../7-theme/blocks/productFilter';
@import '../7-theme/blocks/products';
@import '../7-theme/blocks/rubidorProducts';
@import '../7-theme/blocks/mediaCenter';
@import '../7-theme/blocks/lottieFile';
