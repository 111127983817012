/* Structure */

// Wrapper
.#{$structure-namespace}Wrapper {
    @include grid(100%, auto 1fr auto);
    grid-template-areas: 'header' 'main' 'footer';
    min-height: 100%;
}

// Header
.#{$structure-namespace}Header {
    @include position(relative, $z: 3);
    grid-area: header;
    height: u(175);
    transition: height 0.3s;

    > .#{$container-namespace}Container {
        // height: 100%;
    }

    @include mq($from: $viewport--lg) {
        height: auto;
        // height: u($structure-header-height - 45);

        &.is-home {
            // height: u($structure-header-height);
        }
    }
}

// Main
.#{$structure-namespace}Main {
    @include position(relative, $z: 2);
    grid-area: main;
    overflow-x: hidden;
    margin-top: u(0);

    @include mq($from: $viewport--md) {
        position: relative;

        &::after {
            content: '';

            @include background-svg(
                '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 21"><line id="Line_25" data-name="Line 25" x2="40" transform="translate(40 20.5) rotate(180)" fill="none" stroke="#cacaca" stroke-width="1"/><line id="Line_21" data-name="Line 21" x2="20" transform="translate(40 15.5) rotate(180)" fill="none" stroke="#cacaca" stroke-width="1"/><line id="Line_22" data-name="Line 22" x2="20" transform="translate(40 10.5) rotate(180)" fill="none" stroke="#cacaca" stroke-width="1"/><line id="Line_23" data-name="Line 23" x2="20" transform="translate(40 5.5) rotate(180)" fill="none" stroke="#cacaca" stroke-width="1"/><line id="Line_24" data-name="Line 24" x2="20" transform="translate(40 1.5) rotate(180)" fill="none" stroke="#cacaca" stroke-width="1"/></svg>'
            );
            background-position: top;
            background-repeat: repeat-y;

            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;

            width: 40px;
            height: 100%;
        }
    }

    //.show-nav &{
    //    z-index: 2;
    //}
}

// Footer
.#{$structure-namespace}Footer {
    @include position(relative, $z: 1);
    grid-area: footer;
    height: if(
        $structure-footer-height != 'auto',
        u($structure-footer-height),
        null
    );
}
