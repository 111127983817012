.Block--textMedia {
    margin: u($spacing-unit * 1.5, 0) !important;

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0) !important;
    }

    .TextMedia {
        &-grid {
            @include grid(100%, auto auto auto);
            position: relative;

            @include mq($from: $viewport--lg) {
                @include grid(
                    calc(45% - 20px) 40px calc(55% - 20px),
                    auto auto
                );
            }
        }

        &-title {
            @include grid-area(1, 2, 1, 2);

            position: relative;
            z-index: 2;

            @include mq($from: $viewport--lg) {
                @include grid-area(1, 2, 1, 2);
            }

            margin-bottom: u($spacing-unit);
        }

        &-content {
            @include grid-area(1, 2, 3, 4);

            @include mq($from: $viewport--lg) {
                @include grid-area(1, 2, 1, 3);
            }

            @include mq($until: $viewport--lg - 1) {
                margin-top: u($spacing-unit);
            }
        }

        &-text {
            @include fluid-type(
                'font-size',
                $viewport--sm,
                $viewport--xl,
                20,
                30
            );
            @include fluid-type(
                'line-height',
                $viewport--sm,
                $viewport--xl,
                26,
                40
            );

            font-weight: 300;
        }

        &-media {
            @include grid-area(1, 2, 2, 3);

            @include mq($from: $viewport--lg) {
                @include grid-area(3, 4, 1, 3);
            }

            display: flex;
            // align-items: center;

            z-index: 2;
            width: 100%;
            height: 100%;
            position: relative;

            .Media--video {
                width: 100%;
            }
        }

        // &-object {
        //     margin: 0 auto;
        //     max-width: u(450);

        //     @include mq($from: $viewport--lg) {
        //         width: 100%;
        //         height: 100%;
        //         max-width: u(610);

        //         img {
        //             position: absolute;
        //             top: 0;
        //             right: 0;
        //             bottom: 0;
        //             left: 0;
        //             max-height: 100%;
        //             object-fit: contain;
        //         }
        //     }
        // }

        &-link {
            margin-top: u($spacing-unit--sm);
        }

        &-background {
            @include grid-area(1, 2, 1, 2);

            position: absolute;
            top: -1rem;
            right: u($structure-responsive-gutter--r * -1);
            bottom: 0;
            left: u($structure-responsive-gutter--l * -1);

            z-index: 0;
            background-color: $cc-grey--lighter;
            height: calc(100% + (2 * #{$spacing-unit--lg}px));
            width: calc(
                100% + 50px + (2 * #{$structure-responsive-gutter--r}px)
            );

            @include mq($from: $viewport--lg) {
                display: none;
            }
        }
    }
}
