.Block--lottieFile {
    // height: 100vh;

    .lottieContainer{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Container{
        height: 100%;
    }

} 