/* Main */
html {
    background-color: $bg-color;
    color: $text-color;
    font-family: $font-sans;
    height: 100%;

    &.fonts-loaded {
        font-weight: 300;
        font-family: $font-config;
    }

    &.hide-scroll {
        overflow: hidden;
    }

    &.show-nav body {

        @include mq($until: $viewport--lg - 1) {
            overflow: hidden;
        }
    }

    strong {
        font-weight: 500;
    }
}

body {
    height: 100%;
}
