.Block--form {
    margin: u($spacing-unit--lg, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .Form {

        &-title {
            text-align: center;

            & + .Form,
            & + .Form-intro {
                margin-top: u(50);

                @include mq($from: $viewport--sm) {
                    margin-top: u(70);
                }
            }
        }

        &-intro {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 20, 30);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 26, 40);

            @include mq($from: $viewport--sm) {
                text-align: center;
            }

            & + .Form {
                margin-top: u(50);

                @include mq($from: $viewport--sm) {
                    margin-top: u(70);
                }
            }
        }
    }
}