.Block--cta {
    background-color: $cc-grey--lighter;
    margin: u($spacing-unit--lg * 1.5, 0, $spacing-unit * 1.5, 0);

    @include mq($from: $viewport--md) {
        margin: u(150, 0);
    }

    .Cta {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include mq($from: $viewport--md) {
            flex-direction: row;
        }

        &-icon,
        &-text {
            width: 100%;
            flex-basis: auto;

            @include mq($from: $viewport--md) {
                width: 50%;
            }
        }

        &-icon {
            position: relative;

            img {
                @include fluid-type(
                    'top',
                    $viewport--sm,
                    $viewport--xl,
                    0,
                    -50px
                );

                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);

                @include mq($until: $viewport--md - 1) {
                    max-width: 300px;
                }

                @include mq($from: $viewport--md) {
                    left: 0;
                    transform: translateY(-50%);
                }
            }
        }

        &-text {
            @include fluid-type(
                'font-size',
                $viewport--sm,
                $viewport--xl,
                30,
                60
            );
            @include fluid-type(
                'line-height',
                $viewport--sm,
                $viewport--xl,
                35,
                60
            );

            font-weight: bold;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            // margin: u($spacing-unit--lg * 1.3, 0, $spacing-unit, 0);

            // @include mq($from: $viewport--md) {
            //     text-align: left;
            //     margin: u($spacing-unit--lg * 1.5, 0);
            // }
        }
    }
}

.CtaNew {
    &-text {
        @include fluid-type('font-size', $viewport--sm, $viewport--xl, 30, 60);
        @include fluid-type(
            'line-height',
            $viewport--sm,
            $viewport--xl,
            35,
            60
        );
        color: $primary;

        font-weight: bold;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transform: translateY(-1rem);

        @include mq($from: $viewport--md) {
            transform: translateY(-2rem);
        }

        // margin: u($spacing-unit--lg * 1.3, 0, $spacing-unit, 0);

        // @include mq($from: $viewport--md) {
        //     text-align: left;
        //     margin: u($spacing-unit--lg * 1.5, 0);
        // }
    }

    &-right {
        @include mq($from: $viewport--md) {
            border-left: 1px solid $black;
        }
    }

    &-link {
        align-items: center;
    }

    &-linkText {
        text-transform: uppercase;
        color: $black;
    }
}
