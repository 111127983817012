.Block--mediaCenter {
    margin: u($spacing-unit * 1.5, 0) !important;

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1, 0) !important;
    }

    + .Block--app {
        margin-top: u($spacing-unit--xl);
    }
}

.lightbox {
    position: relative;
    overflow: hidden;
    display: block;
    width: fit-content;

    &:hover {
        .lightbox-play {
            background-color: #000;
        }
    }

    &-play {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 50;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        transition: all 0.4s ease-in-out;

        > svg {
            width: 50px;
            height: 50px;
            fill: #fff;
            transition: all 0.4s ease-in-out;
        }
    }
}
.Site-solidor {
    .lightbox {
        &-play {
            background-color: $cc-red;
        }
    }
}

.Site-rubidor {
    .lightbox {
        &-play {
            background-color: $cc-green;
        }
    }
}

.json-lightbox-img {
    width: 100%;
    object-fit: cover;
}

.video-lightbox-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.json {
    position: relative;
    z-index: 50;
    overflow: hidden;

    > iframe {
        height: 100%;
        width: 100%;
    }
}

.video-inline {
    position: relative;
    z-index: 50;
    overflow: hidden;

    > video {
        width: 100%;
    }
}
.hidden {
    display: none;
}
