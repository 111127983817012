/* Slider */
@import '../6-components/slider/core';
@import '../6-components/slider/a11y';
@import '../6-components/slider/effectFade';
@import '../6-components/slider/effectCoverflow';
@import '../6-components/slider/effectCube';
@import '../6-components/slider/effectFlip';
@import '../6-components/slider/lazy';
@import '../6-components/slider/navigation';
@import '../6-components/slider/pagination';
@import '../6-components/slider/scrollbar';
@import '../6-components/slider/thumbs';
@import '../6-components/slider/zoom';
