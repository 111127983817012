.Block--grid {
    margin: u($spacing-unit * 1.5, 0) !important;

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1, 0) !important;
    }

    + .Block--app {
        margin-top: u($spacing-unit--xl);
    }

    .Grid {

        &-grid {

            @include mq($from: $viewport--md) {
                @include grid(calc(50% - 20px) 40px calc(50% - 20px), repeat(7, auto));
            }

            @include mq($from: $viewport--lg) {
                @include grid(calc(50% - 50px) 100px calc(50% - 50px), repeat(7, auto));
            }
        }

        &-title {
            @include grid-area(1, 2, 1, 2);
        }

        &-intro {
            @include grid-area(1, 2, 2, 3);
            @include fluid-type('font-size', $viewport--sm, $viewport--xl, 20, 30);
            @include fluid-type('line-height', $viewport--sm, $viewport--xl, 26, 40);

            margin-top: u(50);

            @include mq($from: $viewport--md) {
                margin: u(70, 0);
            }
        }

        &-video {
            @include grid-area(1, 2, 4, 7);

            position: relative;
            align-self: flex-end;

            @include mq($until: $viewport--md - 1) {
                margin: u(50, 0);
            }
        }

        &-videoOverlay {

            &::before {
                @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69"><g id="Group_280" data-name="Group 280" transform="translate(-535 -1218)"><circle id="Ellipse_38" data-name="Ellipse 38" cx="34.5" cy="34.5" r="34.5" transform="translate(535 1218)" fill="#fff" opacity="0.25"/><path id="Polygon_3" data-name="Polygon 3" d="M13.889,0,27.778,25.8H0Z" transform="translate(585.8 1239) rotate(90)" fill="#fff"/></g></svg>');
                content: '';

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                z-index: 3;
                cursor: pointer;
                background-position: center;
                background-repeat: no-repeat;
                background-color: rgba($black, .2);
            }
        }

        &-videoOverlayImage {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-imageWrapper {
            @include grid-area(3, 4, 2, 5);

            position: relative;
            margin-top: 40px;

            @include mq($from: $viewport--md) {
                margin-top: 0;
            }

            .Grid-object {

                @include mq($from: $viewport--md) {
                    //position: absolute;
                    top: 0;
                    right: u($structure-gutter--r * -1);
                    bottom: 0;
                    left: 0;
                    margin-bottom: 40px;
                    z-index: 3;
                }

                @include mq($from: $container-width + (2 * $structure-gutter--r)) {
                    right: calc((100vw - #{$container-width}px) / -2);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        &-videoInfo {
            @include grid-area(3, 4, 6, 7);
        }

        &-list {
            @include grid-area(1, 2, 7, 8);
            @include fluid-type('font-size', $viewport--sm, $viewport--xl, 20, 30);
            @include fluid-type('line-height', $viewport--sm, $viewport--xl, 26, 40);

            margin-top: u(50);

            @include mq($from: $viewport--md) {
                margin-top: u(70);
            }
        }
    }

    .Video-grid {
        margin-top: u(50);

        .Grid-intro {
            margin-top: u(0);
        }

        @include mq($from: $viewport--md) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px;

            .Video-grid__content {
                order: 2;
            }

            .Video-grid__video {
                order: 1;
            }
        }
    }
}
