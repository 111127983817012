/* SuitCSS */
@charset "UTF-8";

// 1. Settings
@import '1-settings/base';
@import '1-settings/colors';
@import '1-settings/objects/grid';

// 2. Tools
@import '2-tools/functions/throw';
@import '2-tools/functions/checks';
@import '2-tools/functions/truncate-list';
@import '2-tools/functions/maps';
@import '2-tools/functions/math';
@import '2-tools/functions/units';
@import '2-tools/functions/string';
@import '2-tools/functions/svg';

@import '2-tools/mixins/queries';
@import '2-tools/mixins/last-children';

// 8. Trumps
@import '8-trumps/after';
@import '8-trumps/align';
@import '8-trumps/before';
@import '8-trumps/display';
@import '8-trumps/flex';
@import '8-trumps/layout';
@import '8-trumps/position';
@import '8-trumps/link';
@import '8-trumps/size';
@import '8-trumps/size-legacy';
@import '8-trumps/spacing';
@import '8-trumps/text';
@import '8-trumps/lastChildren';
