.NewsItem {

    &-image {
        width: 100%;
        height: 100%;
    }

    &-imageWrapper {
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-bottom: u($spacing-unit--lg * 1.9);
        }
    }

    &-date {
        @include font-size(22);

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u($spacing-unit);
        }
    }

    .Block--app {
        margin-top: u($spacing-unit--lg * 1.4);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--xl * 1.4);
        }
    }

    .TitleText-title {

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u($spacing-unit--xs);
        }
    }
}