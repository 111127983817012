.Nav--legal {

    .Nav-list {
        margin-left: u($spacing-unit--sm * -1);

        @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
    }

    .Nav-item {
        margin-left: u($spacing-unit--sm);

        @include mq($from: $viewport--lg) {

            &:not(.is-prefix) + .Nav-item:not(.is-prefix) {
                position: relative;

                &::before {
                    content: '\2013';
                    position: absolute;
                    left: -15px;
                    color: $white;
                }
            }
        }
    }

    .Nav-link {
        @include font-size(22);

        color: $white;
        font-weight: 300;
        text-transform: lowercase;
        transition: border-color .3s;
        border-bottom: 1px solid $cc-red;

        &:hover {
            border-color: $white;
        }
    }
}
