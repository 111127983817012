/* Alert */
.Alert {
    // Modifiers
    @each $modifier, $color in $theme-modifiers {
        $alert-color: color-level($color, 6);
        $alert-border: color-level($color, -9);

        &--#{$modifier} {
            color: $alert-color;
            background-color: color-level($color, -10);
            border-color: $alert-border;

            hr {
                border-bottom-color: darken($alert-border, 5%);
            }

            .Alert-link {
                color: darken($alert-color, 10%);
            }
        }
    }

    // Title
    &-title {
        color: inherit;
    }

    // Link
    &-link {
        font-weight: 700;
    }
}

.ff-form-errors,
.ff-errors {
    border: 1px solid;
    border-radius: 11.25px;
    margin-bottom: 1.3333333333rem;
    padding: 0.6666666667rem 0.8888888889rem;
    position: relative;
    border-color: $cc-red;
    color: $cc-red;
    background-color: rgba($cc-red, 0.2);
    margin-bottom: 1.3333333333rem !important;

    & > p {
        margin-bottom: 0;
    }
}

.ff-form-success {
    border: 1px solid;
    border-radius: 11.25px;
    margin-bottom: 1.3333333333rem;
    padding: 0.6666666667rem 0.8888888889rem;
    position: relative;
    background-color: #dbefdc;
    border-color: #cde9ce;
    color: #285b2a;
    margin-bottom: 1.3333333333rem !important;

    & > p {
        margin-bottom: 0;
    }
}

.ff-errors {
    margin-top: 1.3333333333rem !important;

    li:before {
        content: none !important;
    }
}
