/* General */

// LazySizes
.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}

// Tabbing
body:not(.is-tabbing) {
    span:focus,
    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
    }
}

.lg-backdrop {
    background-color: $white;
}

.lg-custom-sub-html {
    @include fluid-type('font-size', $viewport--sm, $viewport--lg, 16, 22);
    @include fluid-type('line-height', $viewport--sm, $viewport--lg, 24, 30);

    width: 100%;
    color: $black;
    font-weight: 300;
    max-width: u(940);
    white-space: normal;
    padding: u(0, $spacing-unit);
    margin-top: u($spacing-unit);
}

.lg-outer.lg-css3 .lg-item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: u($spacing-unit * 1.4, 0);

    .lg-img-wrap {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
    display: none;
}

.lg-toolbar.lg-group {
    background-color: transparent;

    .lg-icon {
        color: $cc-grey;

        &:hover {
            color: $cc-grey--hover;
        }
    }
}

body.lg-on {
    overflow-y: hidden;
}

.lg-outer .lg-image {
    max-width: 100%;

    @include mq($from: $viewport--lg) {
        max-width: u(960);
    }
}

.lg-outer.lg-img-wrap {
    margin: 0 auto;
    position: relative;
    max-width: u(1000);
}

.Button--secondary {
    background: linear-gradient(to right, $black 48%, $cc-grey 50%);
    background-size: 210% 100%;
    background-position: right bottom;

    &:hover {
        background-position: left bottom;
    }
}

// Modal projects
.Modal--project {
    .Modal-header {
        // padding-bottom: 0;
    }

    .Grid--withLargeGutter {
        margin-top: u($spacing-unit--lg * -1);
        margin-left: u($spacing-unit--lg * -1);

        > .Grid-cell {
            padding-top: u($spacing-unit--lg);
            padding-left: u($spacing-unit--lg);
        }
    }

    &::before {
        background-color: $white;
    }

    .Modal-close {
        @include dimensions(40);
        background-color: $cc-red;
        color: $white;
        top: u(20);
        right: u(20);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
    }

    .Modal-body {
        @include mq($until: $viewport--md - 1) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .Modal-innerBody {
        border: 0;
        max-width: none;

        @include mq($until: $viewport--md - 1) {
            margin: 0;
        }
    }

    .Modal-inner-body {
        width: 100%;
    }
}

.Modal--splash {
    .Modal-header {
        flex-direction: column;
    }

    .Nav--lang {
        margin-bottom: u($spacing-unit--sm);
        margin-left: auto;
    }
}

.hyphens-auto {
    hyphens: auto;
}

.trustpilot-header {
    font-size: 16px;
    color: #707070;
    text-align: center;
}

.g-recaptcha {
    display: flex;
    justify-content: center;
}

.freeform-form-errors {
    border: 1px solid $grey--300;
    border-radius: 11.25px;
    color: $red-500;
    margin: 1rem 0;
    padding: 1rem;
    text-align: center;

    p,
    ul {
        margin: 0;
    }
}
