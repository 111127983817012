.Block--image {
    position: relative;
    margin: u($spacing-unit * 1.5, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .Image {

        &-title {
            @include fluid-type('max-width', $viewport--sm, $viewport--xl, 360, 600);
            margin: u(0, auto);
            text-align: center;

            & + .Image-imageWrapper {
                margin-top: u(40);

                @include mq($from: $viewport--md) {
                    margin-top: u(70);
                }
            }
        }

        &-image {
            width: 100%;
            height: 100%;
        }
    }
}