/* Fields: recaptcha */
.#{$form-namespace}Form-item--recaptcha {
    margin: u($spacing-unit--xs, 0);

    .Form-controls {
        display: flex;
        justify-content: center;

        .Footer & {
            justify-content: start;
        }

        > div {
            margin: 0 auto;
        }
    }
}
