.Nav--socialMedia {
    margin-top: u($spacing-unit);

    @include mq($from: $viewport--md) {
        margin-top: 0;
    }

    .Nav-list {
        margin-left: u($spacing-unit--xs * -1);

        @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            justify-content: center;
        }
    }

    .Nav-item {
        margin-left: u($spacing-unit--xs);
    }

    .Nav-link {
        border: none;
    }
}
