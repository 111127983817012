.Block--app {
    margin-top: u($spacing-unit--lg * 1.5);
    background-color: $cc-grey--lighter;

    @include mq($until: $viewport--xs - 1) {
        padding-bottom: u(200);
    }

    @include mq($from: $viewport--md) {
        margin-top: u($spacing-unit--xl * 1.5);
    }

    @include mq($from: $viewport--lg) {
        padding-bottom: u($spacing-unit--xl);
    }

    .App {

        &-grid {

            @include mq($until: $viewport--xs - 1) {

                .Grid-cell {
                    width: 100% !important;
                }
            }
        }

        &-title {
            @include fluid-type('max-width', $viewport--sm, $viewport--xl, 380, 680);
            @include fluid-type('top', $viewport--sm, $viewport--xl, -16px, -34px);

            margin: 0 auto;
            max-width: u(680);
            position: relative;
            text-align: center;

            & + .App-grid {
                margin-top: u(50);

                @include mq($from: $viewport--lg) {
                    margin-top: u(100);
                }
            }
        }

        &-image {
            position: absolute;
            bottom: 0;

            &--desktop {
                left: 50%;
                width: 550px;
                transform: translateX(-50%);
            }

            &--mobile {
                left: 0;
                max-width: 50%;

                @include mq($until: $viewport--xs - 1) {
                    max-width: 150px;
                }
            }
        }

        &-text {
            @include fluid-type('font-size', $viewport--md, $viewport--lg, 20, 30);
        }

        &-ctaTextWrapper {
            transition: height .3s;

            @include mq($until: $viewport--lg - 1) {
                display: flex;
                align-items: center;

                height: 400px;
            }

            @include mq($until: 419) {
                height: 310px;
            }

            @include mq($until: $viewport--xs - 1) {
                height: auto;
                margin: u($spacing-unit, 0);
            }
        }

        &-ctaText {
            @include fluid-type('font-size', $viewport--md, $viewport--lg, 30, 60);
            @include fluid-type('line-height', $viewport--md, $viewport--lg, 25, 60);
            font-weight: bold;
            text-decoration: none;

            @include mq($until: $viewport--lg - 1) {
                color: $cc-red;

                &:hover {
                    color: $cc-red;
                }
            }
        }
    }
}