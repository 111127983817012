/* Navigation */
.Navigation {
    @include mq($from: $viewport--lg) {
        margin-left: u($spacing-unit);
    }

    display: flex;
    align-items: center;

    &-toggle {
        display: block;
        color: $cc-grey;
    }

    &-top {
        display: flex;
        justify-content: space-between;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        align-items: center;
        flex-direction: column;

        @include mq($from: $viewport--lg) {
            flex-direction: row;
        }

        &-flex {
            display: flex;
            column-gap: 1rem;
            align-items: center;
        }

        &-legal {
            font-size: 16px;
            color: #707070;
            text-align: center;

            a {
                color: #707070 !important;
            }
        }

        &-border {
            border-bottom: 1px solid #c2c2c2;
        }
    }

    &-bottom {
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        @include mq($from: $viewport--lg) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &-parent {
        flex-direction: column;
    }

    &-body {
        display: flex;
        margin-left: u($spacing-unit * -1);

        > .Nav {
            @include mq($from: $viewport--lg) {
                padding-left: u($spacing-unit);
            }
        }

        @include mq($until: $viewport--lg - 1) {
            @include position(fixed, u(175), 0, 0, 0, 1);
            @include transition(#{opacity, visibility});
            background-color: $header-bg-color;
            flex-direction: row;
            padding: u($spacing-unit);
            pointer-events: auto;

            html:not(.show-nav) & {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }

            html.show-nav & {
                margin: 0;
                background: $cc-grey--lighter;
            }
        }
    }

    &-bottom {
        // transform: translateY(-100%);
    }
}

/* Types */
@import '../6-components/navigation/main';
@import '../6-components/navigation/lang';
@import '../6-components/navigation/footer';
@import '../6-components/navigation/legal';
@import '../6-components/navigation/social';
@import '../6-components/navigation/children';
@import '../6-components/navigation/pagination';
@import '../6-components/navigation/breadcrumbs';
