/* Logo */
.Logo {
    margin-right: auto;

    @include mq($from: $viewport--md) {
        flex-shrink: 0;
    }

    svg {
        display: block;
        width: u(180);

        @include mq($from: $viewport--lg) {
            width: u(260);
        }

        @include mq($from: $viewport--xl) {
            width: u(310);
        }
    }

    &--footer {

        svg {
            width: u(45);
        }
    }
}
