.Product {
    margin: u($spacing-unit * 1.5, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    &-back {
        margin: 0;
        margin-bottom: 1rem;
    }

    &-link {
        margin: 0.5rem 0;
    }

    &-text {
        order: 2;

        @include mq($from: $viewport--lg) {
            order: 1;
        }
    }

    &-img {
        order: 1;

        @include mq($from: $viewport--lg) {
            order: 2;
        }
    }

    &-title {
        margin-bottom: 1.3333333333rem;
    }

    &-article {
        display: flex;
    }

    &-characteristics {
        margin-top: 1rem;
        @include mq($from: $viewport--md) {
            margin-top: 2rem;
        }
    }

    &-characteristicsTitle {
        margin-bottom: 1.3333333333rem;
    }

    &-characteristicsTable {
        padding: 0.5rem 0;
        display: flex;
        flex-direction: column;
        border: 1px solid $cc-green;
    }

    &-bold {
        font-weight: 500;
    }

    &-char {
        display: flex;
        flex-wrap: wrap;
        gap: 0 1rem;
        padding: 0 0.5rem;
        @include fluid-type('font-size', $viewport--md, $viewport--lg, 26, 30);

        > :nth-child(1) {
            @include mq($from: $viewport--sm) {
                width: 50%;
            }
        }

        &:nth-child(odd) {
            background-color: #efefef;
        }
    }

    &-info {
        margin-top: 1rem;

        @include mq($from: $viewport--md) {
            margin-top: 2rem;
        }

        @include fluid-type('font-size', $viewport--md, $viewport--lg, 20, 30);
    }
}
