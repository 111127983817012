.Nav--footer {

    .Nav-list {
        display: block;
        margin-top: u(-1);

        @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            column-count: 2;
            column-fill: auto;
        }

        @include mq($from: $viewport--xl) {
            column-count: 2;
            column-fill: auto;
        }
    }

    .Nav-item {
        //display: block;
        //break-inside: avoid;
        //margin-top: u(1);
    }

    .Nav-link {
        @include font-size(22);

        display: inline-block;
        color: $white;
        font-weight: 300;
        text-transform: uppercase;
        transition: border-color .3s;
        border-bottom: 1px solid $cc-red;

        &:hover {
            border-color: $white;
        }
    }
}
