/* Object: Close */

// Namespace
$close-namespace:                   $namespace !default;

// Size
$close-font-size:                   $font-size * 1.5 !default;

// Decoration
$close-color:                       $black !default;
$close-text-shadow:                 0 1px 0 $white !default;
