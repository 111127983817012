.Block--timeline {
    margin: u($spacing-unit--lg, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--xl, 0);
    }

    .Timeline {

        &-title {
            margin: 0 auto;
            max-width: u(400);
            text-align: center;
        }

        &-lines {

            @include mq($viewport--sm) {
                min-height: 90px;
                position: relative;
                margin-top: u($spacing-unit--lg);

                @include mq($from: $viewport--md) {
                    margin-top: u($spacing-unit--xl);
                }

                @include mq($until: $container-width + (2 * $structure-gutter--l) - 1) {
                    margin-left: u($structure-gutter--l);
                    margin-right: u($structure-gutter--l);
                }

                &::before {
                    @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 40"><line x1="0.5" y1="40" x2="0.5" fill="none" stroke="#cacaca"/><line x1="5.5" y1="40" x2="5.5" y2="20" fill="none" stroke="#cacaca"/><line x1="10.5" y1="40" x2="10.5" y2="20" fill="none" stroke="#cacaca"/><line x1="15.5" y1="40" x2="15.5" y2="20" fill="none" stroke="#cacaca"/><line x1="19.5" y1="40" x2="19.5" y2="20" fill="none" stroke="#cacaca"/></svg>');
                    content: '';

                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;

                    width: 100%;
                    height: 40px;
                    background-repeat: repeat-x;
                }
            }
        }

        &-line {

            @include mq($from: $viewport--sm) {
                position: absolute;
                top: 0;

                cursor: pointer;
                color: $cc-grey;
                margin-top: u(50);
                transition: color .3s;
                transform: translateX(-50%);

                &::before {
                    content: '';
                    @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="20" height="90.5" viewBox="0 0 20 90.5"><g id="Group_163" data-name="Group 163" transform="translate(-380 -4857)"><circle id="Ellipse_14" data-name="Ellipse 14" cx="10" cy="10" r="10" transform="translate(380 4857)" fill="#cacaca"/><line id="Line_193" data-name="Line 193" y2="81" transform="translate(390.5 4866.5)" fill="none" stroke="#cacaca" stroke-width="1"/></g></svg>');

                    position: absolute;
                    bottom: 40px;
                    left: calc(50% - 10px);

                    width: 20px;
                    height: 100px;
                    transition: background .3s;
                    background-repeat: no-repeat;
                }

                &:hover {
                    color: $cc-grey--hover;

                    &::before {
                        @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="20" height="90.5" viewBox="0 0 20 90.5"><g id="Group_163" data-name="Group 163" transform="translate(-380 -4857)"><circle id="Ellipse_14" data-name="Ellipse 14" cx="10" cy="10" r="10" transform="translate(380 4857)" fill="#{$cc-grey--hover}"/><line id="Line_193" data-name="Line 193" y2="81" transform="translate(390.5 4866.5)" fill="none" stroke="#{$cc-grey--hover}" stroke-width="1"/></g></svg>');
                    }
                }

                &.is-active {
                    color: $cc-red;

                    &::before {
                        @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="20" height="90.5" viewBox="0 0 20 90.5"><g id="Group_163" data-name="Group 163" transform="translate(-380 -4857)"><circle id="Ellipse_14" data-name="Ellipse 14" cx="10" cy="10" r="10" transform="translate(380 4857)" fill="#{$cc-red}"/><line id="Line_193" data-name="Line 193" y2="81" transform="translate(390.5 4866.5)" fill="none" stroke="#{$cc-red}" stroke-width="1"/></g></svg>');
                    }
                }
            }
        }

        &-items {
            overflow: hidden;
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u($spacing-unit--xl);
            }

            .Slider-item:not(.is-active) .Grid--withLargeGutter {
                margin-left: u(($spacing-unit--lg * -1) + 4);
            }
        }

        &-itemText {
            @include font-size(22);
        }

        &-controls {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin: u($spacing-unit, 0);

            .Slider-button {
                position: relative;
                left: auto;
                right: auto;
                top: auto;
                margin: 0;
            }

            .Slider-buttonTimeline--prev {
                background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#{$cc-grey}'/></svg>");

                &:hover {
                    background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#{$black}'/></svg>");
                }
            }

            .Slider-buttonTimeline--next {
                background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#{$cc-grey}'/></svg>");

                &:hover {
                    background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#{$black}'/></svg>");
                }
            }
        }

        &-itemYear {
            @include font-size(30);

            color: $cc-red;
            font-weight: 700;
        }

        &-itemTitle {
            margin-bottom: u($spacing-unit--xs);

            @include mq($until: $viewport--md) {
                display: none;
            }
        }
    }
}