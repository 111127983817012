/* Form */

// Item
.#{$form-namespace}Form-item:not(.#{$form-namespace}Form-item--action) {
    margin-bottom: u($form-item-spacing);
}

// Label
.#{$form-namespace}Form-label {
    @include font-size(22);

    color: $cc-grey;
    display: inline-block;

    .Form-item--input & {
        position: absolute;
        transform: translateX(16px) translateY(8px) scale(1);
        transform-origin: top left;
        transition: transform 0.2s, color 0.2s;
        z-index: 2;
    }

    .Form-item--input.is-active & {
        color: $cc-grey--hover;
        transform: translateX(17px) translateY(-10px) scale(0.75);
    }

    // Style label if it's a optional item
    .#{$form-namespace}Form-item:not(.is-required) &::after {
        @include font-size(18);

        content: ' - Optional';
        margin-left: u($spacing-unit--xs);

        html[lang^='nl'] & {
            content: ' - Optioneel';
        }

        html[lang^='fr'] & {
            content: ' - Facultatif';
        }
    }

    // Error
    .#{$form-namespace}Form-item.is-error & {
        color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        color: $form-success-color;
    }
}

// Help & Validation
.#{$form-namespace}Form-help {
    margin-top: u($spacing-unit--xs);
    color: $form-help-color;
    font-size: u($form-help-font-size);

    &:first-child {
        margin-top: 0;
        margin-bottom: u($spacing-unit--sm);
    }

    & :last-child {
        margin-bottom: 0;
    }

    & + & {
        margin-top: u($spacing-unit--sm);
        padding-top: u($spacing-unit--sm);
        border-top: 1px solid $form-divider-color;
    }
}

.#{$form-namespace}Form-help--validation {
    // Error
    .#{$form-namespace}Form-item.is-error & {
        color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        color: $form-success-color;
    }
}

// Controls
.#{$form-namespace}Form-controls {
    position: relative;
}

// Controls
.#{$form-namespace}Form-item--action {
    display: flex;
    // justify-content: flex-end;
    justify-content: center;

    @include mq($from: $viewport--sm) {
        justify-content: center;
    }
}

// Horizontal
@include mq($from: $viewport--sm) {
    .#{$form-namespace}Form--horizontal {
        .#{$form-namespace}Form-item {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }

        // Label
        .#{$form-namespace}Form-label {
            margin-top: u($form-label-spacing--t);
            margin-bottom: 0;
            padding-right: u($spacing-unit);
            width: percentage($form-label-width);
        }

        // Controls
        .#{$form-namespace}Form-controls {
            width: percentage($form-controls-width);
        }

        // Actions
        .#{$form-namespace}Form-item--action {
            padding-left: percentage($form-label-width);
        }
    }
}

.FormField-label-check {
    font-size: 18px;
}

.Form-item.Form-item--dropdown {
    .Form-label {
        display: none;
    }
    select {
        line-height: 32.9999px;
    }
}

.Form-item--recaptcha {
    .Form-controls {
        display: flex;
        flex-direction: column;
    }
}
