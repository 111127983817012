.Block--banner {
    position: relative;
    margin: u($spacing-unit * 1.5, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .Banner {
        &-object {
            img,
            video {
                width: 100%;
                height: 100%;
                min-height: 400px;
                object-fit: cover;
                transition: height 0.3s, min-height 0.3s;

                @include mq($from: $viewport--sm) {
                    height: 500px;
                }

                @include mq($from: $viewport--lg) {
                    height: 600px;
                }

                @include mq($from: $viewport--lg) {
                    height: 700px;
                }
            }
        }

        &-title {
            @include fluid-type(
                'font-size',
                $viewport--sm,
                $viewport--xl,
                50,
                120
            );
            @include fluid-type(
                'line-height',
                $viewport--sm,
                $viewport--xl,
                45,
                100
            );

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: u($structure-responsive-gutter--l);
            right: u($structure-responsive-gutter--r);

            margin: 0;
            z-index: 2;
            color: $white;
            line-height: 100px;
            text-transform: uppercase;

            @include mq($from: $viewport--md) {
                top: auto;
                right: u($structure-gutter--r);
                bottom: u($spacing-unit);
                left: u($structure-gutter--l);
                transform: none;
            }

            @include mq(
                $from: $container-width +
                    (
                        2 * $structure-gutter--l
                    )
            ) {
                left: calc((100vw - #{$container-width}px) / 2);
            }

            &.has-news {
                @include mq($until: $viewport--md - 1) {
                    top: 60%;
                }
            }

            a {
                color: $white;
                text-decoration: none;
            }
        }

        &-link {
            color: $white;
            text-decoration: none;
            background-color: rgba($white, 0.1);

            .Icon {
                width: 8px;

                svg path {
                    transition: stroke 0.3s;
                }
            }

            &:hover {
                color: $white;
                background-color: rgba($white, 0.5);

                .Icon svg path {
                    stroke: $white;
                }
            }
        }

        &-news {
            display: flex;
            align-items: center;

            z-index: 4;
            max-width: u(900);
            transition: position 0.3s;
            background-color: $cc-grey;
            padding: u(20, $structure-responsive-gutter--l);

            @include mq($from: $viewport--md) {
                position: absolute;
                top: 0;
                right: 0;
                padding: u(20, $structure-gutter--l);
            }

            &Info {
                @include fluid-type(
                    'font-size',
                    $viewport--sm,
                    $viewport--lg,
                    20,
                    40
                );
                @include fluid-type(
                    'line-height',
                    $viewport--sm,
                    $viewport--lg,
                    20,
                    40
                );
                flex-grow: 1;
                color: $white;
                text-transform: uppercase;
                display: flex;
                flex-wrap: wrap;

                @include mq($until: $viewport--sm - 1) {
                    flex-direction: column;
                }
            }

            &Title {
                color: $white;
                font-weight: bold;
                text-decoration: none;

                &:hover {
                    color: $cc-red;
                }

                @include mq($from: $viewport--sm) {
                    margin-right: u($spacing-unit--sm);
                }

                @include mq($from: $viewport--md) {
                    margin-right: u($spacing-unit);
                }
            }

            &Date {
                font-size: 75%;

                @include mq($until: $viewport--sm - 1) {
                    margin-top: u($spacing-unit--xs);
                }
            }

            &Link {
                flex-shrink: 0;
                margin-left: u($spacing-unit);

                @include mq($from: $viewport--md) {
                    margin-left: u($spacing-unit * 1.5);
                }

                a {
                    @include fluid-type(
                        'font-size',
                        $viewport--sm,
                        $viewport--lg,
                        18,
                        30
                    );
                    @include fluid-type(
                        'line-height',
                        $viewport--sm,
                        $viewport--lg,
                        18,
                        30
                    );
                    color: $white;
                    text-decoration: none;
                    text-transform: uppercase;

                    &:hover {
                        color: $cc-red;
                    }
                }
            }
        }
    }
}

.Block--bannerHome {
    z-index: 3;
}

.Block--bannerHigh {
    .Banner {
        &-image,
        &-video {
            height: auto !important;
        }

        &-title {
            color: $cc-red;

            @include mq($from: $viewport--md) {
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
            }
        }
    }
}

.Block--bannerLow {
    .Banner {
        &-image,
        &-video {
            min-height: 200px !important;

            @include mq($from: $viewport--md) {
                height: auto !important;
                min-height: unset !important;
            }
        }

        &-title {
            color: $cc-red;

            @include mq($from: $viewport--md) {
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
            }
        }
    }
}
