.Block--titleText {
    margin: u($spacing-unit * 1.5, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .TitleText {

        &-grid {
            align-items: flex-start;
        }

        &-title {
            margin-bottom: u($spacing-unit);
        }

        &-text {
            @include fluid-type('font-size', $viewport--sm, $viewport--xl, 20, 30);
            @include fluid-type('line-height', $viewport--sm, $viewport--xl, 26, 40);

            font-weight: 300;
        }

        &-intro{
            font-weight: 400;
            line-height: 1.4;
            font-size: 1.2rem;
        }
    }
}
