/* Lang */
.Nav--lang {
    @include font-size(20);
    color: #707070;

    @include mq($from: $viewport--lg) {
        // margin-top: u($spacing-unit--sm);
        // margin-right: u($spacing-unit--sm);
    }

    @include position(relative, $z: 1);

    &:not(:hover) {
        .Nav-list {
            opacity: 0;
            visibility: hidden;
        }
    }

    // Toggle
    .Nav-toggle {
        cursor: default;
        align-items: center;
        display: flex;
        font-size: 16px;
        text-transform: uppercase;
        column-gap: 4px;

        > svg {
            transform: translateY(2px);
        }

        .Caret {
            @include caret(8, 1, bottom, currentColor, $header-bg-color);
            margin-left: u($spacing-unit--xs);
        }
    }

    // List
    .Nav-list {
        @include transition(#{opacity, visibility});
        @include position(absolute, $top: 100%, $right: 0);
        color: $black;
        flex-direction: column;
        background-color: $grey--200;
    }

    // Link
    .Nav-link {
        color: currentColor;
        text-transform: uppercase;
        padding: u($spacing-unit--xs, $spacing-unit--xs * 2);

        &.is-active {
            background-color: $grey--400;
        }

        &:not(.is-active):hover {
            background-color: $grey--300;
        }
    }
}
