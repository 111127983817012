.Block--circles {
    margin: u($spacing-unit * 1.5, 0);

    @include mq($from: $viewport--md) {
        margin: u($spacing-unit--lg * 1.5, 0);
    }

    .Circles {

        &-title {
            @include fluid-type('max-width', $viewport--sm, $viewport--lg, 440, 700);
            margin: 0 auto;
            text-align: center;

            & + .Circles-grid {
                margin-top: u($spacing-unit);

                @include mq($from: $viewport--md) {
                    margin-top: u($spacing-unit--lg);
                }
            }
        }

        &-grid {
            margin-left: u($spacing-unit * -1);

            @include mq($from: $viewport--md) {
                margin-left: u($spacing-unit--lg * -1);
            }

            .Grid-cell {
                padding-left: u($spacing-unit);

                @include mq($until: 399) {
                    width: 100% !important;

                    & + .Grid-cell {
                        margin-top: u($spacing-unit);
                    }
                }

                @include mq($from: $viewport--md) {
                    padding-left: u($spacing-unit--lg);
                }
            }
        }
    }

    .Circle {

        &-imageWrapper {
            color: $white;
            display: block;
            overflow: hidden;
            position: relative;
            border-radius: 50%;
            text-decoration: none;

            &:hover .Circle-object {
                transform: scale(1.05);
            }

            @include mq($until: $viewport--lg - 1) {
                margin: 0 auto;
                max-width: 450px;
                max-height: 450px;
            }
        }

        &-title {
            @include fluid-type('font-size', $viewport--sm, $viewport--lg, 30, 75);
            @include fluid-type('line-height', $viewport--sm, $viewport--lg, 30, 70);

            position: absolute;
            top: 50%;
            left: 50%;

            width: 100%;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            transform: translate(-50%, -50%);
            padding: u(0, $spacing-unit--sm * 1.5);
        }

        &-object {
            transition: transform .3s;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &-text {
            color: $black;
            display: block;
            text-align: center;
            text-decoration: none;
            margin-top: u($spacing-unit);

            @include mq($until: $viewport--md - 1) {
                display: none;
            }
        }
    }

    a.Circle-text {

        &:hover {
            color: $cc-red;
        }
    }
}