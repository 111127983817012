.rubidorProducts {
    position: relative;
    background-color: $cc-grey--lighter;

    @include mq($from: $viewport--md) {
        // margin: u($spacing-unit--xl, 0);
    }
    > .Container {
        background-color: $cc-grey--lighter;
    }

    &-loadingIndicator {
        position: fixed;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 99999;
        height: 100%;
        background: rgba($cc-green, 0.1);
    }

    &-categoryWrapper {
        display: flex;
        flex-direction: column;

        margin-bottom: 1rem;

        h4 {
            margin-bottom: 0.5rem;
        }
    }

    &-title {
        // margin: 0 auto;
        text-align: center;
        position: relative;
        color: $cc-green;
    }

    &-productTypeTitle {
        margin-bottom: 1rem;
    }

    &-articleNr {
        font-size: 18px;
        margin-bottom: 0.5rem;
    }

    &-filterTitle {
        @include mq($from: $viewport--lg) {
            margin-bottom: 1rem;
        }
    }

    &-categoryChildsWrapper {
        display: flex;
        flex-direction: column;
    }

    &-filterGroupWrapper {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
        margin-top: 1rem;

        @include mq($from: $viewport--lg) {
            margin-top: 0;
        }
    }

    &-card {
        color: $black !important;
        text-decoration: none;
        display: flex;
        cursor: pointer;
        flex-direction: column;
        column-gap: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        transition: background-color 0.4s ease-in-out;

        @include mq($from: $viewport--md) {
            flex-direction: row;
        }

        &:hover {
            background-color: $white;
        }
    }

    &-img {
        // max-width: 200px;
        // width: 200px;
        // aspect-ratio: 320/300;
        // object-fit: contain;
        // transition: all 0.4s;
    }

    &-cardTitle {
        font-weight: 600;
        font-size: 25px;
    }

    &-cardContent {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        margin-top: 0.5rem;

        @include mq($from: $viewport--md) {
            margin-top: 0;
        }
    }

    &-loadMoreWrapper {
        margin-top: 16px;
    }

    &-inputWrapper {
        display: flex;
        column-gap: 12px;
        align-items: center;
        font-size: 20px;
    }

    &-noProductsFound {
    }

    &-productsList {
        display: grid;
        grid-template-columns: 1fr;
        @include mq($from: $viewport--md) {
            grid-template-columns: 1fr 1fr;
        }

        gap: 1rem;
        column-gap: 1.5rem;
    }

    &-dimensionWrapper {
    }

    &-dimensionLabel {
        display: inline-block;
        min-width: 120px;
    }

    &-load {
        font-size: 18px;
        cursor: pointer;
        transition: color 0.4s ease-in-out;

        &:hover {
            color: $cc-green;
        }
    }

    &-selectedFilters {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid $border-color;
        display: none;

        @include mq($from: $viewport--lg) {
            display: block;
        }

        &--mobile {
            display: block;
            margin-top: 1rem;

            h4 {
                margin-bottom: 0.5rem;
            }

            @include mq($from: $viewport--lg) {
                display: none;
            }
        }
    }

    &-selectedFiltersList {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include mq($from: $viewport--lg) {
            margin-top: 30px;
        }

        .Button--primary {
            display: flex;
            align-items: center;
        }
    }

    &-filterTag {
        display: flex;
        column-gap: 12px;
        align-items: center;
    }

    &-filterTagContainer {
        padding: 4px 8px;
        background-color: rgba($cc-green, 0.1);
        display: flex;
        cursor: pointer;
        gap: 6px;
        align-items: center;
        transition: all 0.4s;
        font-size: 20px;

        @include mq($from: $viewport--lg) {
            font-size: 25px;
            padding: 4px 16px;
        }

        &:hover {
            background-color: rgba($cc-green, 0.2);
        }
    }

    &-productsList {
        margin-top: 2rem;
    }

    &-tagClose {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: rgba($cc-green, 0.2);
        height: 30px;
        width: 30px;

        > svg {
            height: 16px;
            width: 16px;
        }
    }

    &-paginationParent {
        border-top: 1px solid $border-color;
        margin-top: 2rem;
    }

    &-paginationGrid {
        margin-top: 1rem;
        display: grid;
        column-gap: 2rem;
        row-gap: 1rem;

        @include mq($from: $viewport--lg) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &-info {
        text-align: center;
        // display: flex;

        @include mq($from: $viewport--lg) {
            text-align: start;
        }
    }

    &-pageList {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 1rem;
        column-gap: 0.75rem;
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        margin-left: 0;
    }

    &-pageItem {
        display: flex;
        align-items: center;
        position: relative;

        &--acctive {
            text-decoration: none;
        }

        a {
            cursor: pointer;
            text-decoration: underline;
            transition: all 0.4s;

            &:hover {
                text-decoration: none;
                color: $cc-green !important;
            }
        }
    }

    &-paginationDiv {
        margin-top: 2rem;
        font-size: 20px;
    }

    &-picture {
        display: flex;
        flex-direction: column;
        width: 80%;
        align-self: center;

        @include mq($from: $viewport--md) {
            align-self: start;
            width: 40%;
        }
    }

    &-nextPrev {
        cursor: pointer;
        transition: all 0.4s;
        &:hover {
            color: $cc-green;
        }
    }

    &-pages {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        column-gap: 1rem;
        row-gap: 0.5rem;

        @include mq($from: $viewport--md) {
            flex-direction: row;
        }
    }

    &-filterButtonMobile {
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;

        @include mq($from: $viewport--lg) {
            display: none;
        }
    }

    &-filtersTitleWrapper {
        display: flex;
        justify-content: space-between;
        column-gap: 1rem;
        align-items: flex-start;
    }

    &-mobileClose {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        position: absolute;
        top: 1rem;
        right: 1rem;

        > svg {
            height: 32px;
            width: 32px;
        }

        @include mq($from: $viewport--lg) {
            display: none;
        }
    }

    &-showResults {
        margin-top: 1rem;
        margin-bottom: 1rem;

        display: block;

        @include mq($from: $viewport--lg) {
            display: none;
        }
    }

    &-filters {
        background: white;
        max-width: 500px;
        position: fixed;
        top: 0;
        right: 0;
        transform: translateX(100%);
        z-index: 500;
        padding: 1rem;
        height: 100%;
        overflow-y: auto;

        &--animate {
            transition: transform 0.4s ease-in-out;
        }

        &.open {
            transform: translateX(0);
        }

        @include mq($from: $viewport--lg) {
            position: relative;
            transform: translateX(0);
            max-width: unset;
            background: transparent;
            padding: 0;
        }
    }

    &-categoryLinksParent {
        margin-bottom: 1rem;
    }

    &-categoryLinksWrapper {
        display: flex;
        flex-direction: column;
        column-gap: 0.25rem;
    }

    &-categoryLink {
        font-size: 25px;
        transition: all 0.4s ease-in-out;
        text-decoration: none;
        color: $black !important;

        &--active {
            color: $cc-green !important;
            font-weight: 600;
        }

        &:hover {
            color: $cc-green !important;
        }
    }
}

.Button--mobile {
    background: rgba($cc-green, 0.2) !important;
    color: #000;

    &:hover {
        &:hover {
            background: rgba($cc-green, 0.1) !important;
            color: #000;
        }
    }

    @include mq($from: $viewport--lg) {
        background-color: initial;
    }
}
